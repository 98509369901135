import React from "react";
import { useSetRecoilState } from "recoil";
import styled, { CSSProperties } from "styled-components";
import { leftMenuModeActiveAtom } from "../../state/filter";
import { StandardBox } from "../../styles/boxes/Boxes";
import { ReactComponent as Close } from "../../icons/24/Close.svg";
import { colors } from "../../styles/colors";

type Props = {
  title: string;
  onCloseCallBack?: () => void;
  children: JSX.Element[] | JSX.Element;
  icon?: JSX.Element;
  verticallyCentered?: boolean;
  style?: CSSProperties;
};

const Wrapper = styled(StandardBox)`
  font-size: 1.6rem;
  position: absolute;
  background-color: ${colors.grayLight};
  padding: 0rem 1rem;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: var(--left-menu-margin);
  transition: all 0.3s ease-in-out;
  min-width: 25rem;
`;

const verticallyCenteredWrapperStyle = {
  top: "0px",
  bottom: "0px",
  height: "fit-content",
  maxHeight: "80vh",
  marginTop: "auto",
  marginRight: "auto",
  marginBottom: "auto",
};

const HeaderWrapper = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid #9fbac5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 2.4rem;
    width: 2.4rem;
  }
`;

const Header = styled.h4`
  padding: 0;
  margin: 0;
  white-space: nowrap;
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
`;

const TitleIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const CloseableMenuPopup = ({
  title,
  icon,
  onCloseCallBack,
  children,
  verticallyCentered,
  style,
}: Props) => {
  const setFilterMenuOpen = useSetRecoilState(leftMenuModeActiveAtom);

  const centerStyle = verticallyCentered ? verticallyCenteredWrapperStyle : {};
  const fullStyle = { ...style, ...centerStyle };

  return (
    <Wrapper style={fullStyle}>
      <HeaderWrapper>
        <TitleIconWrapper>
          <Header>{title}</Header>
          {icon}
        </TitleIconWrapper>
        <Close
          onClick={() => {
            setFilterMenuOpen(undefined);
            onCloseCallBack && onCloseCallBack();
          }}
          style={{ cursor: "pointer", height: "1.8rem" }}
        />
      </HeaderWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
};

export default CloseableMenuPopup;
