import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { backgroundLayerActiveAtom } from "../state/layer";
import { mapRefAtom } from "../state/map";

export const MAP_LAYER_SATTELITE = "background-layer";

const Satellite = () => {
  const map = useRecoilValue(mapRefAtom);
  const activeLayer = useRecoilValue(backgroundLayerActiveAtom);

  useEffect(() => {
    if (!map || activeLayer !== "satellite") return;

    map.addLayer(
      {
        id: MAP_LAYER_SATTELITE,
        source: {
          type: "raster",
          url: "mapbox://mapbox.satellite",
          tileSize: 256,
        },
        type: "raster",
      },
      "aeroway-line"
    );

    return () => {
      map.removeLayer(MAP_LAYER_SATTELITE);
      map.removeSource(MAP_LAYER_SATTELITE);
    };
  }, [map, activeLayer]);

  return null;
};

export default Satellite;
