import { colorsBlueGradient } from "./colors";
import styled, { keyframes } from "styled-components/macro";

const skeletonAnimation = keyframes`
    100% {transform: translateX(100%);}
`;

const Skeleton = styled.div`
  --base-color: ${colorsBlueGradient.blue6};
  --highlight-color: #f5f5f5;

  background-color: var(--base-color);

  width: 100%;
  height: 1.6rem;
  border-radius: 0.6rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  overflow: hidden;
  z-index: 1;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      var(--base-color),
      var(--highlight-color),
      var(--base-color)
    );
    transform: translateX(-100%);

    animation-name: ${skeletonAnimation};
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`;

const defaultSize = 32;
export const SkeletonRound = styled(Skeleton)<{ size?: number }>`
  width: ${(p) => (p.size ?? defaultSize) / 10}rem;
  height: ${(p) => (p.size ?? defaultSize) / 10}rem;
  min-width: ${(p) => (p.size ?? defaultSize) / 10}rem;
  min-height: ${(p) => (p.size ?? defaultSize) / 10}rem;
  border-radius: 50%;
`;

export const SkeletonText = styled(Skeleton)``;
export const SkeletonBlock = styled(Skeleton)`
  height: 100%;
  width: 100%;
`;
