import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";
import { colors } from "../../styles/colors";

export const InputWrapper = styled(StandardBox)<{
  close?: boolean;
  maxHeight?: string;
}>`
  position: absolute;
  right: 21.5rem;
  bottom: 5.5rem;
  z-index: 0;
  background-color: ${colors.grayLight};
  padding: 1.2rem;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 1rem;
  font-size: 1.2rem;
  width: ${(p) => (p.close ? "10rem" : "30rem")};
  max-height: ${(p) =>
    p.close ? "4.6rem" : p.maxHeight ? p.maxHeight : "85vh"};
  overflow: ${(p) => (p.close ? "hidden" : "scroll")};
  transition: all 0.3s ease-in-out;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const ToggleWrapper = styled(StandardBox)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  gap: 0.5rem;
`;
