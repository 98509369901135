import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { mapInteractionSelector, mapRefAtom } from "../state/map";
import * as turf from "@turf/turf";
import { projectFeatureMap } from "../state/projectLayers";
import { ProjectFeature } from "../services/types";
import { MapLayerMouseEvent } from "mapbox-gl";
import * as Sentry from "@sentry/browser";
import { zoomPropertyName, ZOOM_SHOW_THRESHOLD } from "../constants/canvas";

export type MouseCallbacks = {
  onClick?: (e: any) => void;
  onMouseMove?: (e: any) => void;
  onMouseLeave?: (e: any) => void;
  onDblClick?: (e: any) => void;
};

export type LayerMouseHandlingCallbacks = Record<string, MouseCallbacks>;
export type ClickOutsideCallback = () => void;

const featuresFilterZoom = (
  map: mapboxgl.Map,
  features: mapboxgl.MapboxGeoJSONFeature[]
) => {
  const zoom = map.getZoom();
  if (zoom > ZOOM_SHOW_THRESHOLD) return features;

  return features.filter(
    (f) =>
      !f.properties ||
      !(zoomPropertyName in f.properties) ||
      !f.properties[zoomPropertyName]
  );
};

const getSmallestFeature = (
  e: mapboxgl.MapMouseEvent,
  map: mapboxgl.Map,
  layerIdsToHandle: string[],
  allFeatures: Map<string, ProjectFeature>
) =>
  map
    .queryRenderedFeatures(e.point)
    .map((f) => ({
      ...f,
      geometry: allFeatures.get(String(f.id))?.geometry ?? f.geometry,
    }))
    .filter((f) => layerIdsToHandle.includes(f.layer.id))
    .sort((a, b) => {
      if (a.geometry.type === "Point" && b.geometry.type !== "Point") return -1;
      if (b.geometry.type === "Point" && a.geometry.type !== "Point") return 1;
      try {
        return turf.area(a) - turf.area(b);
      } catch (e) {
        Sentry.addBreadcrumb({
          category: "mouse handling",
          message: "Unable to calculate area of features",
          level: "error",
          data: {
            a,
            b,
            allFeatures,
          },
        });
        throw e;
      }
    });

export const useMouseHandlingWithSmallestFeaturePrioritized = (
  layerMouseHandlingCallbacks: LayerMouseHandlingCallbacks,
  clickOutsideCallback: ClickOutsideCallback
) => {
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const map = useRecoilValue(mapRefAtom);
  const allFeatures = useRecoilValue(projectFeatureMap);

  useEffect(() => {
    if (!map || !mapInteraction.select) return;
    const layerIdsToHandle = Object.entries(layerMouseHandlingCallbacks)
      .filter(([_, v]) => v?.onClick !== undefined)
      .map(([k, _]) => k);
    if (!map || layerIdsToHandle.length === 0) return;

    const onClick = (e: MapLayerMouseEvent) => {
      const features = getSmallestFeature(
        e,
        map,
        layerIdsToHandle,
        allFeatures
      );

      const featuresZoomAware = featuresFilterZoom(map, features);

      if (featuresZoomAware.length === 0) {
        if (clickOutsideCallback) clickOutsideCallback();
        return;
      }
      const idToCall = featuresZoomAware[0].layer.id;
      e.features = featuresZoomAware;
      layerMouseHandlingCallbacks[idToCall]?.onClick?.(e);
    };

    map.on("click", onClick);
    return () => {
      map.off("click", onClick);
    };
  }, [
    map,
    layerMouseHandlingCallbacks,
    clickOutsideCallback,
    allFeatures,
    mapInteraction.select,
  ]);

  useEffect(() => {
    const layerIdsToHandle = Object.keys(layerMouseHandlingCallbacks).filter(
      (l) => layerMouseHandlingCallbacks[l]["onMouseMove"]
    );
    if (!map || layerIdsToHandle.length === 0 || !mapInteraction.hover) return;

    const onMouseMove = (e: MapLayerMouseEvent) => {
      const features = getSmallestFeature(
        e,
        map,
        layerIdsToHandle,
        allFeatures
      );

      const featuresZoomAware = featuresFilterZoom(map, features);

      if (featuresZoomAware.length === 0) {
        layerIdsToHandle.forEach((l) => {
          layerMouseHandlingCallbacks[l]?.onMouseLeave?.(e);
        });
        map.getCanvas().style.cursor = "unset";
        return;
      }

      map.getCanvas().style.cursor = "pointer";
      const idToCall = featuresZoomAware[0].layer.id;
      e.features = featuresZoomAware;
      layerMouseHandlingCallbacks[idToCall]?.onMouseMove?.(e);

      const layersToCallMouseLeaveOn = layerIdsToHandle.filter(
        (l) => l !== idToCall
      );
      layersToCallMouseLeaveOn.forEach((l) => {
        layerMouseHandlingCallbacks[l].onMouseLeave?.(e);
      });
    };

    map.on("mousemove", onMouseMove);
    return () => {
      map.off("mousemove", onMouseMove);
    };
  }, [map, layerMouseHandlingCallbacks, mapInteraction, allFeatures]);

  useEffect(() => {
    if (!mapInteraction.select) return;
    const layerIdsToHandle = Object.keys(layerMouseHandlingCallbacks).filter(
      (l) => layerMouseHandlingCallbacks[l]["onDblClick"]
    );
    if (!map || layerIdsToHandle.length === 0) return;

    const onDblClick = (e: MapLayerMouseEvent) => {
      const features = getSmallestFeature(
        e,
        map,
        layerIdsToHandle,
        allFeatures
      );

      if (features.length === 0) {
        return;
      }
      const idToCall = features[0].layer.id;
      e.features = features;
      layerMouseHandlingCallbacks[idToCall].onDblClick?.(e);
    };

    map.on("dblclick", onDblClick);
    return () => {
      map.off("dblclick", onDblClick);
    };
  }, [map, layerMouseHandlingCallbacks, allFeatures, mapInteraction.select]);
};
