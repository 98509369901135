import React, { useEffect } from "react";
import styled from "styled-components";
import AnimatedLogo from "../../icons/animatedlogo/AnimatedLogo";
import { colors } from "../../styles/colors";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grayLight}b0;
`;

const FullScreenLoader = () => {
  useEffect(() => {
    const wheelHandler = (event) => {
      event.preventDefault();
    };
    document.addEventListener("wheel", wheelHandler, { passive: false });
    return () => {
      document.removeEventListener("wheel", wheelHandler);
    };
  }, []);
  return (
    <Wrapper id={"fullscreenloader"}>
      <AnimatedLogo />
    </Wrapper>
  );
};

export default FullScreenLoader;
