import { useEffect, useState } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { mapRefAtom } from "../../state/map";
import * as turf from "@turf/turf";
import { Feature, Geometry, Point } from "geojson";
import { GeoJSONSource, MapMouseEvent } from "mapbox-gl";
import { leftMenuModeActiveAtom } from "../../state/filter";
import { TURBINE_PROPERTY_TYPE } from "../../state/layout";
import { StandardBox } from "../../styles/boxes/Boxes";
import { indexOfMin } from "../../utils/utils";

const sourceId = "measure-points";

const DistanceMeterWrapper = styled(StandardBox)`
  position: fixed;
  padding: 1rem;
  top: 60px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Row = ({ points }: { points: number[][] }) => {
  const distM = coordsToDistance(points).toFixed(0);
  const distNM = (coordsToDistanceNauticalMile(points) / 1000).toFixed(2);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        justifyContent: "space-evenly",
      }}
    >
      <div>{distM}m</div>/<div>{distNM}NM</div>
    </div>
  );
};

export const MeasureDistanceMode = "measure-distance-mode-type";

const snapToFeatureAtom = atom<boolean>({
  key: "snapToFeature",
  default: false,
});

const coordsToDistance = (distanceCoords: number[][]): number => {
  if (distanceCoords.length === 0) return 0.0;
  const linestring: Feature = {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: distanceCoords,
    },
    properties: {},
  };
  return turf.length(linestring, { units: "meters" });
};

const coordsToDistanceNauticalMile = (distanceCoords: number[][]): number => {
  return coordsToDistance(distanceCoords) / 1.852;
};

const Distance = () => {
  const leftMenuModeActive = useRecoilValue(leftMenuModeActiveAtom);

  if (leftMenuModeActive !== MeasureDistanceMode) return <></>;

  return <DistanceInner />;
};

const DistanceInner = () => {
  const [hoverPoint, setHoverPoint] = useState([0, 0]);
  const map = useRecoilValue(mapRefAtom);
  const setLeftMenuModeActive = useSetRecoilState(leftMenuModeActiveAtom);
  const [distanceCoords, setDistanceCoords] = useState<number[][]>([]);

  const snap = useRecoilValue(snapToFeatureAtom);

  useEffect(() => {
    if (!map) return;

    map.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer({
      id: "measure-points",
      type: "circle",
      source: sourceId,
      paint: {
        "circle-radius": 5,
        "circle-color": "#000",
      },
      filter: ["in", "$type", "Point"],
    });
    map.addLayer({
      id: "measure-lines",
      type: "line",
      source: sourceId,
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": "#000",
        "line-width": 2.5,
      },
      filter: ["in", "$type", "LineString"],
    });

    return () => {
      map.removeLayer("measure-points");
      map.removeLayer("measure-lines");
      map.removeSource(sourceId);
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;

    map.moveLayer("measure-points");
    map.moveLayer("measure-lines");
    const linestring = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [...distanceCoords, hoverPoint],
      },
    };

    const geojson = {
      type: "FeatureCollection" as const,
      features: [
        ...distanceCoords.map((c, i) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [c[0], c[1]],
          },
          properties: {
            id: i,
          },
        })),
        linestring,
      ] as Feature<Geometry>[],
    };

    const source = map.getSource(sourceId) as GeoJSONSource;
    source.setData(geojson);
  }, [distanceCoords, hoverPoint, map]);

  useEffect(() => {
    if (!map) return;

    const click = (e: MapMouseEvent) => {
      e.preventDefault();
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });

      if (features.length) {
        const id = features[0]?.properties?.["id"];
        if (!id) return;
        const filteredDistanceCoords = distanceCoords.filter(
          (_, i) => i !== id
        );
        setDistanceCoords(filteredDistanceCoords);
      } else {
        const { lng, lat } = e.lngLat;
        let clickedPoint = [lng, lat];

        if (snap) {
          const clickedTurbines = map
            .queryRenderedFeatures(e.point)
            .filter((f) => f?.properties?.type === TURBINE_PROPERTY_TYPE);
          if (0 < clickedTurbines.length) {
            const allCoords = clickedTurbines.map(
              // Safety: these are all turbines, so the geometry is a point.
              (f) => (f.geometry as Point).coordinates
            );
            const i = indexOfMin(allCoords, (coord) =>
              turf.distance(clickedPoint, coord)
            )!; // Safety: allCoords is not empty.
            clickedPoint = allCoords[i];
          }
        }

        setDistanceCoords((coords) => [...coords, clickedPoint]);
      }
    };

    const mousemove = (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });

      setHoverPoint(Object.values(e.lngLat));

      map.getCanvas().style.cursor =
        features.length !== 0 && features[0].layer.type === "circle"
          ? "no-drop"
          : "crosshair";
    };

    map.on("click", click);
    map.on("mousemove", mousemove);

    const abort = (e) => {
      if (e.code === "Escape") {
        setLeftMenuModeActive(undefined);
      }
    };

    document.addEventListener("keydown", abort);

    return () => {
      map.off("click", click);
      map.off("mousemove", mousemove);
      document.removeEventListener("keydown", abort);
    };
  }, [distanceCoords, map, setLeftMenuModeActive, snap]);

  return (
    <>
      <DistanceMeterWrapper>
        <Row points={distanceCoords} />
        <Row points={[...distanceCoords, hoverPoint]} />
      </DistanceMeterWrapper>
    </>
  );
};

export default Distance;
