import { useRecoilState, useRecoilValue } from "recoil";
import { useCallback, useEffect, useMemo } from "react";
import { mapRefAtom } from "../state/map";
import { currentSelectionArrayAtom } from "../state/selection";
import { selectMethod } from "../layers/canvasLayer";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import mapboxgl, {
  AnyLayer,
  CustomLayerInterface,
  Map,
  MapboxGeoJSONFeature,
} from "mapbox-gl";
import { Camera, Scene, WebGLRenderer } from "three";
import {
  getAllTurbinesSelector,
  getSurroundingTurbineFeaturesSelector,
  TurbineFeature,
} from "../state/layout";
import { getFilteredParkFeaturesSelector, ParkFeature } from "../state/park";
import ParkPolygon from "./MapFeatures/ParkPolygon";
import TurbinePoint from "./MapFeatures/TurbinePoint";
import { useParams } from "react-router-dom";

const turbinesSourceId = "turbines";
const surroundingTurbinesSourceId = "surrounding-turbines";
const turbinesLayerId = "turbines-layer";
const surroundingTurbinesLayerId = "surrounding-turbines-layer";
const turbines3dLayerId = "turbines3d-layer";

const surroundingTurbinesLayer: AnyLayer = {
  id: surroundingTurbinesLayerId,
  type: "circle",
  source: surroundingTurbinesSourceId, // reference the data source
  paint: { "circle-opacity": 0.4 },
};

const Layout = () => {
  return (
    <div>
      <TurbinePolygonWrapper />
      <TurbinesWrapper />
    </div>
  );
};

export const turbinePolygonsSourceId = "turbine-polygons";
export const turbinePolygonsLayerId = "turbines-polygons-layer";

const TurbinePolygonWrapper = () => {
  const parks = useRecoilValue(getFilteredParkFeaturesSelector);

  return <TurbinePolygons parks={parks} />;
};

export const TurbinePolygons = ({ parks }: { parks: ParkFeature[] }) => {
  const map = useRecoilValue(mapRefAtom);
  const [currentSelectionArray, setCurrentSelectionArray] = useRecoilState(
    currentSelectionArrayAtom
  );
  const onClickCallback = useCallback(
    (features: MapboxGeoJSONFeature[], shiftClicked: boolean) => {
      selectMethod(shiftClicked, features, setCurrentSelectionArray);
    },
    [setCurrentSelectionArray]
  );

  const selectedIds = useMemo(
    () =>
      currentSelectionArray
        .filter((cs) => cs.source === turbinePolygonsSourceId)
        .map((s) => s.properties.id),
    [currentSelectionArray]
  );

  if (!map) return null;

  return (
    <ParkPolygon
      features={parks}
      sourceId={turbinePolygonsSourceId}
      layerId={turbinePolygonsLayerId}
      map={map}
      onClickCallback={onClickCallback}
      selectedIds={selectedIds}
    />
  );
};

const TurbinesWrapper = () => {
  const turbines = useRecoilValue(getAllTurbinesSelector);
  return <Turbines features={turbines} />;
};

export const Turbines = ({ features }: { features: TurbineFeature[] }) => {
  const { parkId } = useParams();
  const map = useRecoilValue(mapRefAtom);
  const surroundingFeatures = useRecoilValue(
    getSurroundingTurbineFeaturesSelector({ parkId })
  );

  const threeLayer = useMemo(() => {
    const turbineLonlats = features.map(
      (f) => f.geometry.coordinates as [number, number]
    );
    return new ThreeDLayer(turbineLonlats);
  }, [features]);

  useEffect(() => {
    if (!map) return;
    map.addSource(surroundingTurbinesSourceId, {
      type: "geojson",
      data: { type: "FeatureCollection", features: surroundingFeatures },
    });
    map.addLayer(surroundingTurbinesLayer);
    map.addLayer(threeLayer);

    return () => {
      map.removeLayer(surroundingTurbinesLayerId);
      map.removeLayer(turbines3dLayerId);
      map.removeSource(surroundingTurbinesSourceId);
    };
  }, [map, features, threeLayer, surroundingFeatures]);

  if (!map) return null;
  return (
    <TurbinePoint
      layerId={turbinesLayerId}
      sourceId={turbinesSourceId}
      features={features}
      map={map}
    />
  );
};

class ThreeDLayer implements CustomLayerInterface {
  id: string;
  type: "custom";
  renderingMode: "2d" | "3d" | undefined;
  turbineLonlats: [number, number][];
  direction: number = 0;
  modelTransforms: {
    translateX: number;
    translateY: number;
    translateZ: number;
    rotateX: number;
    rotateY: number;
    rotateZ: number;
    scale: number;
  }[];
  camera: Camera;
  scene: Scene;
  renderer: WebGLRenderer;
  map: Map;
  constructor(turbineLonlats: [number, number][]) {
    this.id = turbines3dLayerId;
    this.type = "custom";
    this.renderingMode = "3d";
    this.turbineLonlats = turbineLonlats;
    this.direction = 0;
  }
  onAdd(map: Map, gl: WebGLRenderingContext) {
    // parameters to ensure the model is georeferenced correctly on the map
    if (this.turbineLonlats.length === 0) return;
    const modelOrigins = this.turbineLonlats;
    const modelAltitude = 0;
    map.setLayerZoomRange(turbines3dLayerId, 14, 24);

    const modelAsMercatorCoordinates = modelOrigins.map((modelOrigin) =>
      mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude)
    );

    // transformation parameters to position, rotate and scale the 3D model onto the map
    this.modelTransforms = modelAsMercatorCoordinates.map(
      (modelAsMercatorCoordinate) => {
        return {
          translateX: modelAsMercatorCoordinate.x,
          translateY: modelAsMercatorCoordinate.y,
          translateZ: modelAsMercatorCoordinate.z ?? 0,
          rotateX: 0,
          rotateY: 0,
          rotateZ: 0,
          /* Since the 3D model is in real world meters, a scale transform needs to be
           * applied since the CustomLayerInterface expects units in MercatorCoordinates.
           */
          scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
        };
      }
    );
    this.camera = new THREE.Camera();
    this.scene = new THREE.Scene();

    // create two three.js lights to illuminate the model
    const directionalLight = new THREE.DirectionalLight(0xffffff);
    directionalLight.position.set(0, -70, 100).normalize();
    this.scene.add(directionalLight);

    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
    directionalLight2.position.set(0, 70, 100).normalize();
    this.scene.add(directionalLight2);

    // use the three.js GLTF loader to add the 3D model to the three.js scene
    const loader = new GLTFLoader();
    loader.load(
      "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/test.gltf",
      (gltf) => {
        this.scene.add(gltf.scene);
      }
    );
    this.map = map;

    // use the Mapbox GL JS map canvas for three.js
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
  }

  renderSingle(gl, matrix, index) {
    const transform = this.modelTransforms[index];
    const rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      transform.rotateX
    );
    const rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      transform.rotateY
    );
    const rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      ((-90 - this.direction) * Math.PI) / 180
    );

    const m = new THREE.Matrix4().fromArray(matrix);
    const l = new THREE.Matrix4()
      .makeTranslation(
        transform.translateX,
        transform.translateY,
        transform.translateZ
      )
      .scale(
        new THREE.Vector3(transform.scale, -transform.scale, transform.scale)
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);

    this.camera.projectionMatrix = m.multiply(l);
    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }

  render(gl, matrix) {
    if (this.turbineLonlats.length > 0) {
      for (const index in this.modelTransforms) {
        this.renderSingle(gl, matrix, index);
      }
    }
  }
}

export default Layout;
