import { atom, atomFamily, selectorFamily } from "recoil";
import { ARCGIS_REST_API_TYPE, getArcgisPath } from "./arcgisRestAPI";
import { getWfsPath, WFS_TYPE } from "./wfs";
import { WMS_TYPE } from "./wms";
import { getPublicProjectLayers } from "../services/gisDataAPIService";
import { TILE_JSON_TYPE } from "./tileJSON";
import {
  LayerType,
  PublicGisLayerCommon,
  PublicGisLayerTilejson,
  PublicGisLayerWMS,
  PublicGisLayers,
  PublicVectorGisLayer,
  isArcgisLayer,
  isHostedLayer,
  isTileJSONLayer,
  isWFSLayer,
  isWMSLayer,
} from "../types/gisData";
import { Feature, Polygon } from "geojson";
import { FeatureCollection, isNumber } from "@turf/turf";
import { SourceTypes } from "../components/LayerList/AddNewSourceModal";
import { isCustomLayer } from "../utils/externalLayers";
import { customLayerSourceType } from "../constants/customLayers";
import { z } from "zod";
import { _Feature } from "../utils/geojson/geojson";
import { jsonrepair } from "jsonrepair";
import { publicNodeInfoSelectorFamily } from "./projectAPI";

const WFS_FEATURE_COUNT = 20;

export const AllLayersMenu = "AllLayersMenu";
export const HOSTED_TYPE = "hosted";

const _VectorDataSchema = z.object({
  features: _Feature.array().optional(),
  type: z.string(),
  exceededTransferLimit: z.boolean().optional(),
  isWFSLayerWithoutPaginationSupport: z.boolean().optional(),
  isArcgisLayerWithoutPaginationSupport: z.boolean().optional(),
  numberMatched: z.number().optional(),
  numberReturned: z.number().optional(),
  totalFeatures: z.number().optional(),
});
type VectorDataSchema = z.infer<typeof _VectorDataSchema>;

export type LayerSourceType =
  | typeof HOSTED_TYPE
  | typeof WFS_TYPE
  | typeof WMS_TYPE
  | typeof ARCGIS_REST_API_TYPE
  | typeof TILE_JSON_TYPE;

type LibraryLaMenuToggledOpenType = undefined | typeof AllLayersMenu;

export type CustomDataEntry = {
  type: SourceTypes;
  name: string;
  url: string;
};

export const newCustomDataSourceAtom = atom<undefined | CustomDataEntry>({
  key: "newCustomDataSourceAtom",
  default: undefined,
});

export const libraryLayersOpenAtom = atom({
  key: "libraryLayersOpenAtom",
  default: false,
});

export const libraryLayersTabOpenAtom = atom<LibraryLaMenuToggledOpenType>({
  key: "libraryLayersTabOpenAtom",
  default: AllLayersMenu,
});

export const visibleDynamicLayersAtom = atom<string[]>({
  key: "visibleDynamicLayersAtom",
  default: [],
});

export const selectedDynamicLayersAtom = atomFamily<
  PublicGisLayerCommon[],
  string
>({
  key: "selectedDynamicLayersAtom",
  default: [],
});

export const windLayerVariablesAtom = atom({
  key: "windLayerVariablesAtom",
  default: {
    layerType: "meanSpeed",
    colorScale: "default",
  },
});

export const windLayerHeightAtom = atom({
  key: "windLayerHeightAtom",
  default: 150,
});

export const combinationLayerVariablesAtom = atom({
  key: "combinationLayerVariablesAtom",
  default: {
    depth: 0.5,
    shoreDistance: 0.5,
    fishFactor: 0.5,
    trafficFactor: 0.5,
    energyFactor: 0.1,
  },
});

const isOnlyNumbers = (value: any) => {
  if (Array.isArray(value)) {
    return value.every((subval) => isOnlyNumbers(subval));
  }
  return isNumber(value);
};

export type BackgroundLayer = "bathymetry" | "satellite";

export type InfoLayer =
  | undefined
  | "cost"
  | "combination"
  | "hillshade"
  | "windSpeed";

export enum SourceName {
  Original = "Original",
  English = "English",
}

export const selectedSourceNameAtom = atom<SourceName>({
  key: "sourceNameAtom",
  default: SourceName.Original,
});

export const backgroundLayerActiveAtom = atom<BackgroundLayer>({
  key: "backgroundLayerActiveAtom",
  default: "bathymetry",
});

export const lowerRightMenuActiveModeAtom = atom<InfoLayer | undefined>({
  key: "infoLayerActiveAtom",
  default: undefined,
});

export const selectedDynamicTileJSONPolygonLayersSelectorFamily =
  selectorFamily<PublicGisLayerTilejson[], string>({
    key: "selectedDynamicTileJSONPolygonLayersSelectorFamily",
    get:
      (projectId: string) =>
      async ({ get }) => {
        return get(selectedDynamicLayersAtom(projectId)).filter(
          (dl) => isTileJSONLayer(dl) && dl.type === "polygon"
        ) as PublicGisLayerTilejson[];
      },
  });

export const selectedDynamicTileJSONCircleLayersSelectorFamily = selectorFamily(
  {
    key: "selectedDynamicTileJSONCircleLayersSelectorFamily",
    get:
      (projectId: string) =>
      async ({ get }) => {
        return get(selectedDynamicLayersAtom(projectId)).filter(
          (dl) => isTileJSONLayer(dl) && dl.type === "point"
        ) as PublicGisLayerTilejson[];
      },
  }
);

export const selectedDynamicTileJSONLineLayersSelectorFamily = selectorFamily({
  key: "selectedDynamicTileJSONLineLayersSelectorFamily",
  get:
    (projectId: string) =>
    async ({ get }) => {
      return get(selectedDynamicLayersAtom(projectId)).filter(
        (dl) => isTileJSONLayer(dl) && dl.type === "line"
      ) as PublicGisLayerTilejson[];
    },
});

export const selectedDynamicPolygonLayersSelectorFamily = selectorFamily<
  PublicVectorGisLayer[],
  string
>({
  key: "selectedDynamicPolygonLayersSelectorFamily",
  get:
    (projectId: string) =>
    async ({ get }) => {
      return get(selectedDynamicLayersAtom(projectId)).filter(
        (dl) =>
          dl.sourceType !== TILE_JSON_TYPE &&
          (dl.type === "polygon" ||
            dl.type === LayerType.FeatureCollection ||
            dl.sourceType === customLayerSourceType)
      ) as PublicVectorGisLayer[];
    },
});

export const selectedDynamicLineLayersSelectorFamily = selectorFamily<
  PublicVectorGisLayer[],
  string
>({
  key: "selectedDynamicLineLayersSelectorFamily",
  get:
    (projectId: string) =>
    async ({ get }) => {
      return get(selectedDynamicLayersAtom(projectId)).filter(
        (dl) =>
          dl.sourceType !== TILE_JSON_TYPE &&
          (dl.type === "line" ||
            dl.type === LayerType.FeatureCollection ||
            dl.sourceType === customLayerSourceType)
      ) as PublicVectorGisLayer[];
    },
});

export const selectedDynamicWMSLayersSelectorFamily = selectorFamily<
  PublicGisLayerWMS[],
  string
>({
  key: "selectedDynamicWMSLayersSelectorFamily",
  get:
    (projectId) =>
    async ({ get }) =>
      get(selectedDynamicLayersAtom(projectId)).filter((l) =>
        isWMSLayer(l)
      ) as PublicGisLayerWMS[],
});

export const selectedDynamicPointsLayersSelectorFamily = selectorFamily<
  PublicVectorGisLayer[],
  string
>({
  key: "selectedDynamicPointsLayersSelectorFamily",
  get:
    (projectId: string) =>
    async ({ get }) => {
      return get(selectedDynamicLayersAtom(projectId)).filter(
        (dl) => dl.sourceType !== TILE_JSON_TYPE && dl.type === "point"
      ) as PublicVectorGisLayer[];
    },
});

export const selectedDynamicCircleLayersSelectorFamily = selectorFamily<
  PublicVectorGisLayer[],
  string
>({
  key: "selectedDynamicCircleLayersSelectorFamily",
  get:
    (projectId: string) =>
    async ({ get }) => {
      return get(selectedDynamicLayersAtom(projectId)).filter(
        (dl) =>
          dl.sourceType !== TILE_JSON_TYPE &&
          (dl.type === "circle" ||
            dl.type === LayerType.FeatureCollection ||
            dl.sourceType === customLayerSourceType)
      ) as PublicVectorGisLayer[];
    },
});

export const publicProjectLayersSelectorFamily = selectorFamily<
  PublicGisLayers,
  { customerId: string; projectId: string; branchId: string }
>({
  key: "publicProjectLayersSelectorFamily",
  get:
    ({ customerId, projectId, branchId }) =>
    async ({ get }) => {
      const node = get(publicNodeInfoSelectorFamily({ customerId, projectId }));
      return getPublicProjectLayers(node.id, branchId);
    },
});

export const publicProjectLayersPerSourceSelectorFamily = selectorFamily<
  Record<string, PublicGisLayerCommon[]>,
  { customerId: string; projectId: string; branchId: string }
>({
  key: "publicProjectLayersNewSelectorFamily",
  get:
    ({ customerId, projectId, branchId }) =>
    async ({ get }) =>
      get(
        publicProjectLayersSelectorFamily({
          customerId,
          projectId,
          branchId,
        })
      ).layers.reduce((acc, layer) => {
        if (!acc[layer.sourceId]) acc[layer.sourceId] = [];
        acc[layer.sourceId].push(layer);
        return acc;
      }, {} as Record<string, PublicGisLayerCommon[]>),
});

const pathWithOffset = (
  layer: PublicGisLayerCommon,
  offset: number,
  bbox?: number[]
) => {
  if (isHostedLayer(layer)) return `${layer.endpoint.url}`;
  if (isCustomLayer(layer)) return `${layer.endpoint.url}`;

  if (isArcgisLayer(layer)) {
    const url = `${getArcgisPath(layer)}&resultOffset=${offset}`;
    if (!bbox) return url;
    return url
      .replaceAll("&geometry=&", "&geometry=" + bbox.join(",") + "&")
      .replaceAll("&inSR=&", "&inSR=4326&");
  }

  if (isWFSLayer(layer)) {
    const url = `${getWfsPath(
      layer
    )}&count=${WFS_FEATURE_COUNT}&startIndex=${offset}`;
    if (!bbox) return url;
    return url + "&bbox=" + bbox.join(",") + ",EPSG:4326";
  }

  throw new Error(
    "Can not add offset to this layer sourceType: " + layer.sourceType
  );
};

const specialHandlingForArcgisLayersWithoutPagination = async (
  path: string
): Promise<Response> => {
  const pathWithoutStartIndex = path
    .substring(0, path.indexOf("&resultOffset"))
    .replace(/&resultRecordCount=\d+/, "");
  const res = await fetch(pathWithoutStartIndex, {
    method: "get",
  });

  return res;
};

const specialHandlingForWFSLayersWithoutOffsetHandling = async (
  path: string
): Promise<Response> => {
  const pathWithoutStartIndex = path.substring(0, path.indexOf("&startIndex"));
  const res = await fetch(pathWithoutStartIndex, {
    method: "get",
  });

  return res;
};

const getLayerWithOffsetUrl = async (
  layer: PublicGisLayerCommon,
  offset: number,
  bbox?: number[]
): Promise<VectorDataSchema> => {
  const path = pathWithOffset(layer, offset, bbox);
  let isWFSLayerWithoutPaginationSupport = false;
  let isArcgisLayerWithoutPaginationSupport = false;
  let res = await fetch(path, {
    method: "get",
  });

  if (!res.ok) {
    let text = await res.text();
    if (
      text.includes("IOExceptionCannot do natural order without a primary key")
    ) {
      res = await specialHandlingForWFSLayersWithoutOffsetHandling(path);
      isWFSLayerWithoutPaginationSupport = true;
    }
  } else {
    const clone = res.clone();
    const text = await clone.text();
    if (text.includes("Pagination is not supported")) {
      res = await specialHandlingForArcgisLayersWithoutPagination(path);
      isArcgisLayerWithoutPaginationSupport = true;
    }
  }

  if (!res.ok) {
    throw new Error("Error while trying to download external data layer");
  }

  const resJsonText = await res.text();
  const repaired = jsonrepair(resJsonText);
  const resJson = JSON.parse(repaired);
  const parsed = z.record(z.any()).parse(resJson); // NOTE: any here, because we're parsing it later anyways.

  if ("error" in parsed) {
    throw new Error("Error while trying to download external data layer");
  }

  const cleanResult = {
    ...parsed,
    features: (parsed.features ?? []).filter(
      (f) =>
        Boolean(f.geometry?.coordinates) &&
        isOnlyNumbers(f.geometry.coordinates)
    ),
    isWFSLayerWithoutPaginationSupport,
    isArcgisLayerWithoutPaginationSupport,
    exceededTransferLimit:
      parsed.exceededTransferLimit ??
      parsed?.properties?.exceededTransferLimit ??
      (isWFSLayer(layer) && parsed?.features?.length === WFS_FEATURE_COUNT),
    numberReturned: parsed?.features?.length,
  }; // Some results return null as geometry and crash when parsing

  return _VectorDataSchema.parse(cleanResult);
};

export const dynamicLayersSelector = selectorFamily<
  | {
      [key: string]: any;
      features: Feature[];
    }
  | FeatureCollection<Polygon>,
  { layer: PublicGisLayerCommon }
>({
  key: "dynamicLayersSelector",
  get:
    ({ layer }) =>
    async () => {
      let getNextPage = true;
      let finished = false;

      window.setTimeout(() => {
        if (finished) return;
        getNextPage = false;
        console.error(
          `Took too long to request all features from layer "${layer.name}", stop fetching instead...`
        );
      }, 10_000);

      let result = { features: [] as Feature[] };
      while (getNextPage) {
        const newResult = await getLayerWithOffsetUrl(
          layer,
          result.features.length
        );
        const newFeatures = newResult.features ?? [];
        result = {
          ...newResult,
          features: [...newFeatures, ...result.features],
        };
        if (!newResult.exceededTransferLimit) getNextPage = false;
      }

      finished = true;
      return result;
    },
});
