import styled from "styled-components";
import { StyledModalBase } from "../InfoModal/InfoModal.style";
import { StandardBox } from "../../styles/boxes/Boxes";
import { colors } from "../../styles/colors";
import { Ui12Regular } from "../../styles/typography";
import {
  IconWrapperFill,
  IconWrapperStroke,
} from "../ControlPanels/ControlPanel.layout";
import Tooltip from "../General/Tooltip";
import { Container } from "../../styles/misc/Misc";
import React from "react";

export const ParentWrapper = styled(Container)`
  display: inline-block;
`;

export const AnalysisWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  z-index: 1;
  gap: 0.25rem;
`;

export const StyledModal = styled(StyledModalBase)`
  overflow-y: auto;
  overflow-wrap: break-word;
`;

export const AnalysisMenu = styled(StandardBox)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  gap: 8px;
  height: fit-content;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem 0 0 0;
`;

export const Key = styled(Ui12Regular)`
  margin: 0;
`;

export const Value = styled(Ui12Regular)`
  margin: 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${colors.blueDark};
  cursor: pointer;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.blueMedium};
  padding-bottom: 1rem;
  margin: 1rem 0;
  font-style: normal;
`;

export const Content = styled.div`
  flex-grow: 2;
  padding: 0 0.5rem 0 0;
`;

export const Menu = styled.div`
  border-radius: 0.6rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

export const MenuOption = ({
  Icon,
  iconType = "fill",
  chosenMenu,
  setChosenMenu,
  option,
  tooltipText,
}) => {
  const selected = chosenMenu === option;
  return iconType === "fill" ? (
    <Tooltip position="left" text={tooltipText}>
      <IconWrapperFill
        active={selected}
        onClick={() => setChosenMenu(selected ? undefined : option)}
      >
        <Icon
          style={{
            opacity: selected ? "1.0" : "0.6",
            cursor: "pointer",
          }}
        />
      </IconWrapperFill>
    </Tooltip>
  ) : (
    <Tooltip position="left" text={tooltipText}>
      <IconWrapperStroke
        active={selected}
        onClick={() => setChosenMenu(selected ? undefined : option)}
      >
        <Icon
          style={{
            opacity: selected ? "1.0" : "0.6",
            cursor: "pointer",
          }}
        />
      </IconWrapperStroke>
    </Tooltip>
  );
};
