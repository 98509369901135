import { useCallback, useState } from "react";
import { dedup, downloadBlob, isDefined } from "../utils/utils";
import { getGeotiffImage } from "../services/projectDataAPIService";
import { useTypedPath } from "../state/pathParams";
import { ProjectFeature } from "../services/types";
import { geojsonFileToZippedShapeFiles } from "../utils/downloadUtils";
import { GeotiffFeature } from "../utils/geojson/utils";

const GeoTiffUserUploadedImageType = "GeoTiffUserUploadedImageType";
const BathymetryUserUploadedType = "BathymetryUserUploadedType";

export const geotiffTypes = [
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
];

// /**
//  * Soem features, like {@link MooringLineFeature} and {@link CableFeature} implicitly
//  * use the coordinates of the endpoints of the line strings of the features they reference.
//  * This is a problem when downloading features, because the coordinates of the endpoints
//  * might be wrong, if the endpoints have been updated but the cable feature's has not.
//  */
// const updateCoordinates = (
//   featureMap: Map<string, ProjectFeature>,
//   features: ProjectFeature[]
// ): ProjectFeature[] => {
//   return features.map((feature) => {
//     if (isMooringLine(feature)) {
//       const anchor = featureMap.get(feature.properties.anchor);
//       const target = featureMap.get(feature.properties.target);
//       if (isAnchor(anchor) && isPointFeature(target)) {
//         return replaceEndpointsLineString(
//           feature,
//           anchor.geometry.coordinates,
//           target.geometry.coordinates
//         );
//       }
//       return feature;
//     }
//     if (isCable(feature)) {
//       const from = featureMap.get(feature.properties.fromId);
//       const to = featureMap.get(feature.properties.toId);
//       if (isPointFeature(from) && isPointFeature(to)) {
//         return replaceEndpointsLineString(
//           feature,
//           from.geometry.coordinates,
//           to.geometry.coordinates
//         );
//       }
//       return feature;
//     }
//     return feature;
//   });
// };

const geoTiffType = (feature: ProjectFeature) =>
  geotiffTypes.includes(feature.properties.type ?? "");
const notGeoTiffType = (feature: ProjectFeature) => !geoTiffType(feature);
const getUniqueFeatureTypes = (features: ProjectFeature[]) =>
  dedup(features.map((f) => f.properties.type).filter(isDefined));

enum FeatureTypes {
  Point = "Point",
  MultiPoint = "MultiPoint",
  Polygon = "Polygon",
  MultiPolygon = "MultiPolygon",
  LineString = "LineString",
  MultiLineString = "MultiLineString",
}

const getDownloadTypes = (
  features: ProjectFeature[],
  uniqueFeatureTypes: string[],
  name: string,
  getBlobFunc: (
    features: ProjectFeature[],
    fileName: string
  ) => Promise<Blob | void>
): Promise<Blob | void>[] =>
  [
    FeatureTypes.Point,
    FeatureTypes.MultiPoint,
    FeatureTypes.LineString,
    FeatureTypes.MultiLineString,
    FeatureTypes.Polygon,
    FeatureTypes.MultiPolygon,
  ]
    .map((type) => {
      const featuresOfType = features.filter(
        (feature) => feature.geometry.type === type
      );

      if (featuresOfType.length === 0) return [];

      const uniqueFeaturesPerType = Object.fromEntries(
        uniqueFeatureTypes
          .map((featureType) => {
            const filteredFeatures = featuresOfType.filter(
              (f) => f.properties.type === featureType
            );
            if (filteredFeatures.length === 0) return undefined;
            return [featureType, filteredFeatures];
          })
          .filter(isDefined)
      );

      const generalFeatures = featuresOfType.filter((f) => !f.properties.type);
      if (generalFeatures.length !== 0) {
        uniqueFeaturesPerType["feature"] = generalFeatures;
      }

      return Object.keys(uniqueFeaturesPerType).map((featureType) =>
        getBlobFunc(
          uniqueFeaturesPerType[featureType],
          `${name}-${type}-${featureType}`
        )
      );
    })
    .flat();

const useDownloadFeatures = () => {
  const { projectId } = useTypedPath("projectId");
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});

  const downloadGeotiff = useCallback(
    async (geotiffFeature: GeotiffFeature) => {
      if (!projectId) return;
      const e = geotiffFeature as GeotiffFeature;
      const blob = await getGeotiffImage(
        projectId,
        e.properties.filename,
        e.properties.type
      );
      if (!blob) return;
      downloadBlob(blob, `${e.properties.name}.tif`);
    },
    [projectId]
  );

  const downloadMultipleFeaturesShape = useCallback(
    async (
      features: ProjectFeature[],
      name: string,
      loadingId?: string,
      types?: FeatureTypes[]
    ) => {
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: true }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = true;
          return acc;
        }, {}),
      }));

      const geotiffFeatures = features.filter(geoTiffType) as GeotiffFeature[];
      const nonGeotiffFeatures = features.filter(notGeoTiffType);

      let typeFeatures = nonGeotiffFeatures.filter((f) => {
        if (!types) return true;
        if (!f.properties.type) return false;
        const type = f.properties.type as FeatureTypes | undefined;
        if (!type) {
          return false;
        }

        return types.includes(type);
      });
      // typeFeatures = updateCoordinates(featureMap, typeFeatures);

      const uniqueFeatures = getUniqueFeatureTypes(nonGeotiffFeatures);

      const downloadGeotiffs = geotiffFeatures.map(downloadGeotiff);

      const downloadTypes = getDownloadTypes(
        typeFeatures,
        uniqueFeatures,
        name,
        (features: ProjectFeature[], fileName: string) =>
          geojsonFileToZippedShapeFiles(features).then((b) =>
            downloadBlob(b, fileName + ".zip")
          )
      );

      await Promise.all([...downloadTypes, ...downloadGeotiffs]);
      setIsLoading((curr) => ({
        ...curr,
        ...(loadingId && { [loadingId]: false }),
        ...features.reduce((acc, feature) => {
          (acc as any)[feature.properties.id ?? ""] = false;
          return acc;
        }, {}),
      }));
      return;
    },
    [downloadGeotiff]
  );

  return {
    isLoading,
    downloadMultipleFeaturesShape,
  };
};

export default useDownloadFeatures;
