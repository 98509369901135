import * as turf from "@turf/turf";
import { Position } from "@turf/turf";
import { movingWindow, movingWindowLoop, zip } from "./utils";

const EPS = 1e-7; // floating point accuracy
export const isApprox = (a: number, b: number, eps: number = EPS) =>
  Math.abs(a - b) < eps;
export const isApproxBetween = (
  a: number,
  x: number,
  b: number,
  eps: number = EPS
) => a - eps < x && x < b + eps;
export const isClearlyBetween = (
  a: number,
  x: number,
  b: number,
  eps: number = EPS
) => a + eps < x && x < b - eps;

type Vec = number[];
export const vecAdd = <V extends Vec>(a: V, b: V): V =>
  zip(a, b).map(([aa, bb]) => aa + bb) as V;
export const vecSub = <V extends Vec>(a: V, b: V): V =>
  zip(a, b).map(([aa, bb]) => aa - bb) as V;
export const vecScale = <V extends Vec>(a: V, s: number): V =>
  a.map((aa) => aa * s) as V;
export const dot = (a: Vec, b: Vec): number => a[0] * b[0] + a[1] * b[1];
export const length = (v: Vec): number => Math.sqrt(dot(v, v));
export const sampleLine = <V extends Vec>(s: [V, V], f: number): V => {
  const ab = vecSub(s[1], s[0]);
  return vecAdd(s[0], vecScale(ab, f));
};
export const vectorAngle = (vec: Vec): number => Math.atan2(vec[1], vec[0]);
export const segmentAngle = ([a, b]: Vec[]): number =>
  vectorAngle(vecSub(b, a));

export const pointsAreEqual = (a: Vec, b: Vec, eps: number = EPS): boolean =>
  isApprox(length(vecSub(a, b)), 0.0, eps);

export const pointsAreInLine = (a: Vec, b: Vec, c: Vec): boolean => {
  // If a == b then the three points always form a line. Otherwise,
  // project and see if we moved the point.
  if (pointsAreEqual(a, b)) return true;
  return pointsAreEqual(c, projectPointToLine(c, [a, b]).point);
};

function projectPointToLine(
  pt: Vec,
  [lineFrom, lineTo]: Vec[]
): { point: Vec; factor: number } {
  const line = vecSub(lineTo, lineFrom);
  const normPt = vecSub(pt, lineFrom);
  const factor = dot(line, normPt) / dot(line, line);
  const point = sampleLine([lineFrom, lineTo], factor);
  return {
    point,
    factor,
  };
}

export function projectPointToSegment(pt: number[], segment: number[][]): Vec {
  const { point, factor } = projectPointToLine(pt, segment);
  if (factor < 0) return vecSub(pt, segment[0]);
  if (1 < factor) return vecSub(pt, segment[1]);
  return vecSub(pt, point);
}

export function distancePointToSegment(
  pt: number[],
  segment: number[][]
): number {
  return length(projectPointToSegment(pt, segment));
}

export function distancePointToPolygonBoundary(
  pt: Vec,
  boundaryPoints: Vec[]
): number {
  return movingWindow(boundaryPoints).reduce((min, segment) => {
    const dist = distancePointToSegment(pt, segment);
    return dist < min ? dist : min;
  }, Infinity);
}

export function lineIntersectionIsClearlyOutsideEitherSegment(
  li: LineIntersection,
  eps = EPS
): boolean {
  return (
    !isApproxBetween(0.0, li.s, 1.0, eps) ||
    !isApproxBetween(0.0, li.t, 1.0, eps) ||
    isNaN(li.pt[0])
  );
}

export function lineIntersectionIsClearlyOnSegments(
  li: LineIntersection,
  eps = EPS
): boolean {
  return (
    isClearlyBetween(0.0, li.s, 1.0, eps) &&
    isClearlyBetween(0.0, li.t, 1.0, eps)
  );
}

export type LineIntersection = { s: number; t: number; pt: number[] };

/**
 * Compute the intersection of two lines and returns the two scalars for the two lines,
 * as well as the intersection point. If the lines are parallel and not overlapping, return
 * NaN for the points and `[0, 0]` for the intersection point. If the lines are parallel and
 * overlapping, return any point that is on both lines.
 *
 * Any point on the line crossing two points `a` and `b` can be written as `a + s * (b - a)` for some number `s`.
 * For instance, if `s == 0` then the point is `a`, if `s == 0.5` the point is in the middle of `a` and b`, and
 * if `s == 2` the point is past `b` so that `b` is right in the middle of `a` and the point.
 *
 * @param line1 The first line
 * @param line2 The second line
 * @returns a `LineIntersection` object `{s, t, pt}` containing the two scalar factors for the two lines and the intersection point.
 */
export function getLineIntersection(
  line1: number[][],
  line2: number[][],
  debug: boolean = false
): LineIntersection {
  const [a, b] = line1;
  const [c, d] = line2;

  const [abx, aby] = [b[0] - a[0], b[1] - a[1]];
  const [cdx, cdy] = [d[0] - c[0], d[1] - c[1]];

  // Want to sovle the system
  // |abx -cdx| |s| = |cx - ax|
  // |aby -cdy| |t| = |cy - ay|

  const det = abx * -cdy + aby * cdx;
  if (isApprox(det, 0)) {
    // The lines are parallel.  If the lines are distinct they don't intersect at all.
    if (!pointsAreInLine(a, b, c)) {
      if (debug) console.log("parallel lines are offset. No intersection");
      return { s: NaN, t: NaN, pt: [NaN, NaN] };
    }
    // The lines are equal.  In order to be useful, we try to find a point that is on both line *segments*,
    // and report this as the intersection point, even though all points on the line intersects the other line.
    // Write the points as `a + t * ab` for some `t`, to reduce the problem to 1D.
    const ct = projectPointToLine(c, [a, b]).factor;
    const dt = projectPointToLine(d, [a, b]).factor;

    const ab0 = 0;
    const ab1 = 1;
    const cd0 = Math.min(ct, dt);
    const cd1 = Math.max(ct, dt);
    // We have three cases that should result in an overlap:
    //               ab0 ----------- ab1
    // Case 1: cd0 ------- cd1
    // Case 2:                 cd0 ------- cd1
    // Case 3: cd0 -------------------------- cd1

    if (cd0 <= ab1 && ab0 <= cd1) {
      // Collision. Now we just have to find a point the collision.
      // Choose the leftmost point.
      if (ab0 < cd0) {
        // Case 2.
        if (ct < dt) {
          // c--d is in the same direction as a--b
          const s = projectPointToLine(c, [a, b]).factor;
          const t = 0;
          if (debug)
            console.log("intersection case 2, same dir", { s, t, pt: c });
          return { s, t, pt: c };
        } else {
          const s = projectPointToLine(d, [a, b]).factor;
          const t = 1;
          if (debug)
            console.log("intersection case 2, diff dir", { s, t, pt: d });
          return { s, t, pt: d };
        }
      } else {
        // Case 1 or 3, but it doesn't matter.
        const s = ab0;
        const t = projectPointToLine(a, [c, d]).factor;
        if (debug)
          console.log("intersection case 1/3", { s, t, pt: a, ct, dt });
        return { s, t, pt: a };
      }
    }

    // Lines are parallel, so `s` and `t` are not defined.  Leave them as NaN so callers can detect this case.
    return { s: NaN, t: NaN, pt: a };
  }

  const dx = c[0] - a[0];
  const dy = c[1] - a[1];

  // Inverse matrix is
  // |-cdy  cdx|  /
  // |-aby  abx| / det
  // and we wan the first row of A^-1 * d
  const s = (-cdy * dx + cdx * dy) / det;
  const t = (-aby * dx + abx * dy) / det;

  return { s, t, pt: [a[0] + s * abx, a[1] + s * aby] };
}

export function pointInPolygon(
  point: turf.Point,
  polygon: turf.Polygon,
  eps: number = EPS
): boolean {
  if (turf.booleanPointInPolygon(point, polygon)) return true;
  // If we are on the boundary, the above method sometimes fails.  Check that we're really close instead.
  const closest = polygon.coordinates.reduce((min, polygon) => {
    const dist = distancePointToPolygonBoundary(point.coordinates, polygon);
    return dist < min ? dist : min;
  }, Infinity);
  return isApprox(closest, 0, eps);
}

export const vertexAngle = (
  inSegment: Position[],
  outSegment: Position[]
): number => {
  const rotate = (vec: Position, angle: number): Position => {
    // Rotation matrix is
    // [ cos t  -sin t]
    // [ sin t   cos t]
    const cost = Math.cos(angle);
    const sint = Math.sin(angle);
    return [vec[0] * cost + vec[1] * -sint, vec[0] * sint + vec[1] * cost];
  };
  const inAngle = segmentAngle(inSegment);
  const outVector = vecSub(outSegment[1], outSegment[0]);
  const rotated = rotate(outVector, -inAngle);
  const angle = vectorAngle(rotated);
  return angle;
};

export function totalPolygonCurvature(polygon: turf.Polygon) {
  const segments = movingWindow(polygon.coordinates[0]);
  return movingWindowLoop(segments).reduce(
    (a, [lastSegment, nextSegment]) =>
      a + vertexAngle(lastSegment, nextSegment),
    0
  );
}
