import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import {
  publicProjectLayersSelectorFamily,
  selectedDynamicLayersAtom,
} from "../../state/layer";
import { useTypedPath } from "../../state/pathParams";
import { useEffect, useState } from "react";

const SyncLayersWithSelectedLayers = () => {
  const { projectId, customerId, branchId } = useTypedPath(
    "projectId",
    "customerId",
    "branchId"
  );

  const publicProjectLayersLoadeble = useRecoilValueLoadable(
    publicProjectLayersSelectorFamily({ customerId, projectId, branchId })
  );
  const setSelectedDynamicLayers = useSetRecoilState(
    selectedDynamicLayersAtom(projectId)
  );
  const [toggledLayers, setToggledLayers] = useState(false);

  useEffect(() => {
    if (toggledLayers || publicProjectLayersLoadeble.state !== "hasValue")
      return;

    const publicProjectLayers = publicProjectLayersLoadeble.contents;

    const layersToToggle = publicProjectLayers.layers.filter(
      (l) => l.toggledDefault
    );
    setSelectedDynamicLayers(layersToToggle);
    setToggledLayers(true);
  }, [
    toggledLayers,
    setToggledLayers,
    publicProjectLayersLoadeble,
    setSelectedDynamicLayers,
  ]);

  return null;
};

export default SyncLayersWithSelectedLayers;
