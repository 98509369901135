import React, { ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";

import Tooltip from "./Tooltip";
import { typography } from "../../styles/typography";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string | JSX.Element;
  icon?: ReactNode;
  buttonType?: "primary" | "secondary" | "text";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  tooltip?: string;
  style?: React.CSSProperties;
}

const StyledButton = styled.button`
  transition: all 200ms ease;
  display: flex;
  flex-direction: row;
  min-width: fit-content;

  align-items: center;
  justify-content: space-around;

  border: none;
  border-radius: 0.4rem;
  padding: 0.6rem 1.2rem;

  text-align: center;

  cursor: pointer;
  outline: none;

  span {
    white-space: nowrap;
  }

  &.large {
    height: 4.8rem;
    min-width: 4.8rem;
    gap: 1.2rem;

    ${typography.buttonLarge}

    > svg {
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
      min-height: 3rem;

      &:only-child {
        margin: 0 -1.2rem;
      }
    }

    &.text {
      > svg {
        margin: 0;
      }
    }
  }

  &.medium {
    height: 3.2rem;
    min-width: 3.2rem;
    gap: 0.6rem;

    ${typography.buttonMedium}

    > svg {
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
      min-height: 2.4rem;

      &:only-child {
        margin: 0 -1.2rem;
      }
    }

    &.text {
      > svg {
        margin: 0;
      }
    }
  }

  &.small {
    height: 3rem;
    min-width: 3rem;
    gap: 0.4rem;

    ${typography.buttonSmall}

    > svg {
      width: 1.6rem;
      height: 1.6rem;
      min-width: 1.6rem;
      min-height: 1.6rem;

      &:only-child {
        margin: 0 -1.2rem;
      }
    }

    &.text {
      > svg {
        margin: 0;
      }
    }
  }

  &.secondary {
    outline: 0.15rem solid ${colors.blueMediumLight};
    box-sizing: border-box;
    background-color: transparent;
    color: ${colors.blueDark};

    svg path {
      stroke: ${colors.blueDark};
    }

    :hover {
      outline-color: ${colors.blueHover};
      color: ${colors.blueHover};
      svg path {
        stroke: ${colors.blueHover};
      }
    }

    :active {
      outline-color: ${colors.bluePressed};
      color: ${colors.bluePressed};
      svg path {
        stroke: ${colors.bluePressed};
      }
    }

    :disabled {
      outline: 1px solid;
      outline-color: ${colors.grayDisabled};
      color: ${colors.grayDisabled};
      svg path {
        stroke: ${colors.grayDisabled};
      }
    }
  }

  &.primary {
    background: ${colors.blueDark};
    color: white;
    svg path {
      stroke: white;
    }

    :hover {
      background: ${colors.blueHover};
    }

    :active {
      background: ${colors.bluePressed};
    }
    :disabled {
      background: ${colors.grayDisabled};
      color: rgba(255, 255, 255, 0.6);
    }
  }

  &.text {
    border: none;
    box-sizing: border-box;
    background-color: transparent;
    color: ${colors.blueDark};

    svg path {
      stroke: ${colors.blueDark};
    }

    :hover {
      color: ${colors.blueHover};
      text-decoration-line: underline;
      svg path {
        stroke: ${colors.blueHover};
      }
    }

    :active {
      color: ${colors.bluePressed};
      svg path {
        stroke: ${colors.bluePressed};
      }
    }

    :disabled {
      color: ${colors.grayDisabled};
      text-decoration-line: none;
      svg path {
        stroke: ${colors.grayDisabled};
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export default function Button({
  text,
  icon,
  buttonType = "primary",
  tooltip,
  size = "medium",
  ...props
}: ButtonProps) {
  if (tooltip) {
    return (
      <Tooltip text={tooltip}>
        <StyledButton className={[buttonType, size].join(" ")} {...props}>
          {icon ? icon : undefined}
          {text && <span>{text}</span>}
        </StyledButton>
      </Tooltip>
    );
  }
  return (
    <StyledButton className={[buttonType, size].join(" ")} {...props}>
      {icon ? icon : undefined}
      {text && <span>{text}</span>}
    </StyledButton>
  );
}
