import { Map } from "mapbox-gl";
import { useCallback } from "react";
import { BOUNDS_BUFFER } from "../constants/misc";
import { getBBOXArray } from "../utils/geojson/validate";

const goToFeatures = (features, map, boundBuffer?: number) => {
  const bbox = getBBOXArray(features);
  if (bbox.some((coord) => coord > 180 || coord < -180)) return;
  map.fitBounds([
    [
      bbox[0] - (boundBuffer ?? BOUNDS_BUFFER),
      bbox[1] - (boundBuffer ?? BOUNDS_BUFFER),
    ],
    [
      bbox[2] + (boundBuffer ?? BOUNDS_BUFFER),
      bbox[3] + (boundBuffer ?? BOUNDS_BUFFER),
    ],
  ]);
};

export const useGoToFeatures = (map?: Map) => {
  return useCallback(
    (features: any[], e?: any, boundBuffer?: number) => {
      if (e) e.stopPropagation();
      if (features.length === 0 || !map) return;
      goToFeatures(features, map, boundBuffer);
    },
    [map]
  );
};
