import React, { MouseEvent, useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { leftMenuModeActiveAtom } from "../../state/filter";
import { ReactComponent as Chevron } from "../../icons/24/ArrowRight.svg";
import { ReactComponent as Image } from "../../icons/image/image.svg";
import { ReactComponent as Download } from "../../icons/24/Download.svg";
import {
  bathymetryLayerFeaturesSelector,
  canvasLayerFeatureHiddenAtom,
  canvasLayerFeaturesSelector,
  georefImagesLayerFeaturesSelector,
  findFeatureChildren,
} from "../../state/projectLayers";
import Spinner from "../../icons/spinner/Spinner";
import { ReactComponent as Point } from "../../icons/24/Pin.svg";
import EyeOpen from "../../icons/24/View.svg";
import EyeClosed from "../../icons/24/ViewOff.svg";
import Search from "../../icons/24/Target.svg";
import { mapRefAtom } from "../../state/map";
import FeatureToCanvas from "../FeatureToCanvas/FeatureToCanvas";
import { useGoToFeatures } from "../../hooks/map";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
  ProjectFeature,
} from "../../services/types";
import { getParkFeaturesSelector } from "../../state/park";
import CloseableMenuPopup from "../MenuPopup/CloseableMenuPopup";
import { useTypedPath } from "../../state/pathParams";
import { getPublicBranchDataAtomFamily } from "../../state/projectAPI";
import useDownloadFeatures from "../../hooks/useDownloadFeaturesAsZipped";
import { isDefined } from "../../utils/utils";

export const ProjectElementMenuType = "ProjectElement";

const ElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`;

const ThemeTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 0.5rem 0 0;
  svg {
    width: 1.3rem;
    height: 2.2rem;
  }
`;

const ChevronWrapper = styled.div<{ open?: boolean }>`
  transform: rotate(${(p) => (p.open ? "90deg" : "0deg")});
  transition: 0.1s;
`;

const HiddenIcon = styled.div<{ icon: string }>`
  background-image: url(${(p) => p.icon});
  display: none;
  background-size: cover;
  width: 1.6rem;
  height: 1.6rem;
  flex: 0 0 auto;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const RowDownloadWrapper = styled.div`
  display: none;
  svg {
    height: 16px;
    width: 16px;
    path {
      stroke: gray;
    }
  }
  &:hover {
    svg {
      path {
        stroke: black;
      }
    }
  }
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  fill: #023859;
  stroke: #023859;
  word-break: break-word;

  &:hover {
    fill: #508196;
    stroke: #508196;
    img {
      opacity: 1;
    }
    div {
      color: #333;
    }
    ${RowDownloadWrapper} {
      display: inherit;
    }
    ${HiddenIcon} {
      display: inherit;
    }
  }
`;

const ElementNameAndTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  user-select: none;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const RowFunctions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
`;

const RowEyeIcon = styled.div<{ selected: boolean }>`
  filter: ${(p) =>
    p.selected
      ? "invert(48%) sepia(13%) saturate(1207%) hue-rotate(130deg) brightness(95%) contrast(80%)"
      : "inherit"};
  background-image: url(${(p) => (p.selected ? EyeOpen : EyeClosed)});
  background-size: cover;
  width: 1.6rem;
  height: 1.6rem;
  flex: 0 0 auto;
`;

const ElementToIcon = ({ element }: { element: ProjectFeature }) => {
  if (
    [BathymetryUserUploadedType, GeoTiffUserUploadedImageType].includes(
      element?.properties?.type ?? ""
    )
  )
    return <Image />;
  return element.geometry.type === "Point" ? (
    <Point />
  ) : (
    <FeatureToCanvas features={[element]} />
  );
};

const Element = ({ element }: { element: ProjectFeature }) => {
  const map = useRecoilValue(mapRefAtom);
  const { projectId, customerId, branchId } = useTypedPath(
    "projectId",
    "customerId",
    "branchId"
  );
  const { downloadMultipleFeaturesShape, isLoading } = useDownloadFeatures();
  const publicBranchFeatures = useRecoilValue(
    getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
  );

  const [canvasLayerFeatureHidden, setCanvasLayerFeatureHidden] =
    useRecoilState(canvasLayerFeatureHiddenAtom);

  const goToFeatures = useGoToFeatures(map);

  const _onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setCanvasLayerFeatureHidden((clfh) =>
        clfh.includes(element.id)
          ? clfh.filter((c) => c !== element.id)
          : [...clfh, element.id]
      );
    },
    [setCanvasLayerFeatureHidden, element]
  );

  return (
    <ElementWrapper onClick={_onClick}>
      <ElementNameAndTypeWrapper>
        <ElementToIcon element={element} />
        {element.properties.name}
      </ElementNameAndTypeWrapper>
      <RowFunctions>
        {!isLoading[element.id] && (
          <>
            {element.properties.type !== BathymetryUserUploadedType && (
              <RowDownloadWrapper
                title={"Download feature"}
                onClick={(e) => {
                  e.stopPropagation();
                  const childIds = findFeatureChildren(
                    publicBranchFeatures,
                    element.id
                  );
                  const childrenFeatures = childIds
                    .map((id) => publicBranchFeatures.find((c) => c.id === id))
                    .filter(isDefined);

                  downloadMultipleFeaturesShape(
                    [element, ...childrenFeatures],
                    element.properties.name ?? "Unnamed feature",
                    element.id
                  );
                }}
              >
                <Download />
              </RowDownloadWrapper>
            )}
            <HiddenIcon
              onClick={(e) => goToFeatures([element], e)}
              title={"Go to feature"}
              icon={Search}
            />

            <RowEyeIcon
              title={"Eye"}
              selected={!canvasLayerFeatureHidden.includes(element.id)}
            />
          </>
        )}
        {isLoading[element.id] && (
          <Spinner style={{ height: "16px", width: "16px" }} />
        )}
      </RowFunctions>
    </ElementWrapper>
  );
};

const Theme = ({ title, elements }: { title: string; elements: any[] }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <ThemeTitleWrapper onClick={() => setOpen(!open)}>
        <div>{title}</div>
        <ChevronWrapper open={open}>
          <Chevron />
        </ChevronWrapper>
      </ThemeTitleWrapper>
      {open && (
        <>
          {elements.map((e) => (
            <Element key={e.id} element={e} />
          ))}
        </>
      )}
    </>
  );
};

const ProjectElements = ({ disablePark = false }) => {
  const menuToggledOpen = useRecoilValue(leftMenuModeActiveAtom);

  if (menuToggledOpen !== ProjectElementMenuType) return null;

  return (
    <CloseableMenuPopup
      title="Project elements"
      style={{
        top: 0,
        bottom: 0,
        marginTop: "auto",
        marginBottom: "auto",
        height: "fit-content",
        maxHeight: "70vh",
        overflow: "scroll",
      }}
    >
      <React.Suspense fallback={<Spinner />}>
        <ProjectElementsList disablePark={disablePark} />
      </React.Suspense>
    </CloseableMenuPopup>
  );
};

const ProjectElementsList = ({ disablePark }: { disablePark: boolean }) => {
  const menuToggledOpen = useRecoilValue(leftMenuModeActiveAtom);
  const parks = useRecoilValue(getParkFeaturesSelector);
  const otherPolygonFeatures = useRecoilValue(canvasLayerFeaturesSelector);
  const bathymetryLayers = useRecoilValue(bathymetryLayerFeaturesSelector);
  const georefImageLayers = useRecoilValue(georefImagesLayerFeaturesSelector);

  if (menuToggledOpen !== ProjectElementMenuType) return null;

  return (
    <ElementsWrapper>
      {!disablePark && <Theme elements={parks} title={"Parks"} />}
      <Theme elements={georefImageLayers} title={"Images"} />
      <Theme elements={bathymetryLayers} title={"Bathymetry"} />
      <Theme elements={otherPolygonFeatures} title={"Other"} />
    </ElementsWrapper>
  );
};

export default ProjectElements;
