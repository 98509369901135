import { atom, selector } from "recoil";
import { LayerMouseHandlingCallbacks } from "../hooks/mouseHandling";

export const currentSelectionArrayAtom = atom<any[]>({
  key: "currentSelectionArrayAtom",
  default: [],
  dangerouslyAllowMutability: true,
});

export const currentWiringSelectionArrayAtom = atom<
  undefined | { type: string; id: string; source: string }[]
>({
  key: "currentWiringSelectionArrayAtom",
  default: [],
  dangerouslyAllowMutability: true,
});

export const firstCurrentSelectionSelector = selector({
  key: "firstCurrentSelectionSelector",
  get: async ({ get }) => {
    const csa = get(currentSelectionArrayAtom);
    if (csa.length === 1) return csa[0];
    return;
  },
  dangerouslyAllowMutability: true,
});

export type WMSSelection = {
  type: string;
  url: string;
  legendGraphicUrl: string;
  html?: string;
};

export const currentSelectionWMSAtom = atom<WMSSelection[]>({
  key: "currentSelectionWMSAtom",
  default: [],
});

export const defaultMouseHandlerCallBackClickableFeature =
  atom<LayerMouseHandlingCallbacks>({
    key: "defaultMouseHandlerCallBackClickableFeature",
    default: {},
  });
