import { z } from "zod";

export type MetadataType = {
  bbox: number[];
  source: string;
  name: string;
  type: string;
  id: string;
};

export type TileJSONLayersInfoType = MetadataType & {
  sourceType: string;
};

export enum LayerStrokeStyle {
  Dashed = "dashed",
  Solid = "solid",
  Dotted = "dotted",
}

export enum SourceTypesLayer {
  wms = "wms",
  wfs = "wfs",
  arcgis = "arcgis",
  tilejson = "tileJSON",
  hosted = "hosted",
  customLayer = "CustomLayerSourceType",
}

export enum LayerType {
  wms = "wms",
  Line = "line",
  Point = "point",
  Polygon = "polygon",
  Circle = "circle",
  FeatureCollection = "featureCollection",
}

const _PublicGisEndpoint = z.object({
  id: z.string(),
  abstract: z.string().optional().nullable(),
  abstractEnglish: z.string().optional().nullable(),
  attribution: z.string().optional().nullable(),
  skipProxy: z.boolean().optional().nullable(),
  url: z.string(),
  licenseUrl: z.string().optional().nullable(),
  urlWhereFound: z.string().optional().nullable(),
});

const _PublicGisSource = z.object({
  id: z.string(),
  name: z.string(),
  originalName: z.string().optional().nullable(),
});

const _LayerStyle = z
  .object({
    /** In [0, 1] */
    opacity: z.number().optional(),
    /** Hex */
    color: z.string().optional(),
    strokeColor: z.string().optional(),
    strokeStyle: z.nativeEnum(LayerStrokeStyle).optional(),
    pinnedProperty: z.string().optional(),
    zoomLevels: z.tuple([z.number(), z.number()]).optional(),
  })
  .nullable();
export type LayerStyle = z.infer<typeof _LayerStyle>;

const _LayerSetting = z
  .object({
    overrideLayerStyle: z.boolean().optional(),
    layerStyle: _LayerStyle,
    selected: z.boolean().optional(),
    standard: z.boolean().optional(),
    visible: z.boolean().optional(),
  })
  .nullable();
export type LayerSetting = z.infer<typeof _LayerSetting>;

const _PublicGisLayerCommon = z.object({
  bbox: z.number().array(),
  id: z.string(),
  endpoint: _PublicGisEndpoint,
  endpointId: z.string(),
  layerSetting: _LayerSetting.optional().nullable(),
  name: z.string(),
  source: _PublicGisSource,
  sourceId: z.string(),
  type: z.nativeEnum(LayerType),
  sourceType: z.nativeEnum(SourceTypesLayer),
  toggledDefault: z.boolean(),
});

const _PublicGisLayerArcgis = _PublicGisLayerCommon.extend({
  sourceType: z.literal(SourceTypesLayer.arcgis),
  sourceLayerId: z.number(),
});
const _PublicGisLayerWMS = _PublicGisLayerCommon.extend({
  sourceType: z.literal(SourceTypesLayer.wms),
  sourceLayerId: z.string(),
});
const _PublicGisLayerWFS = _PublicGisLayerCommon.extend({
  sourceType: z.literal(SourceTypesLayer.wfs),
  outputValue: z.string(),
  sourceLayerId: z.string(),
});
const _PublicGisLayerTilejson = _PublicGisLayerCommon.extend({
  sourceType: z.literal(SourceTypesLayer.tilejson),
  sourceLayerId: z.string(),
});
const _PublicGisLayerHosted = _PublicGisLayerCommon.extend({
  sourceType: z.literal(SourceTypesLayer.hosted),
});

export const _PublicGisLayers = z.object({
  layers: _PublicGisLayerArcgis
    .or(_PublicGisLayerWMS)
    .or(_PublicGisLayerWFS)
    .or(_PublicGisLayerTilejson)
    .or(_PublicGisLayerHosted)
    .array(),
});

export type PublicGisLayerArcgis = z.infer<typeof _PublicGisLayerArcgis>;
export type PublicGisLayerWMS = z.infer<typeof _PublicGisLayerWMS>;
export type PublicGisLayerWFS = z.infer<typeof _PublicGisLayerWFS>;
export type PublicGisLayerTilejson = z.infer<typeof _PublicGisLayerTilejson>;
export type PublicGisLayerHosted = z.infer<typeof _PublicGisLayerHosted>;

export type PublicVectorGisLayer =
  | PublicGisLayerArcgis
  | PublicGisLayerWFS
  | PublicGisLayerHosted
  | PublicGisLayerTilejson;

export const isWMSLayer = <
  G extends PublicGisLayerCommon,
  PG extends PublicGisLayerWMS & G
>(
  f: G
): f is PG => {
  return f.sourceType === SourceTypesLayer.wms;
};
export const isWFSLayer = <
  G extends PublicGisLayerCommon,
  PG extends PublicGisLayerWFS & G
>(
  f: G
): f is PG => {
  return f.sourceType === SourceTypesLayer.wfs;
};
export const isTileJSONLayer = <
  G extends PublicGisLayerCommon,
  PG extends PublicGisLayerTilejson & G
>(
  f: G
): f is PG => {
  return f.sourceType === SourceTypesLayer.tilejson;
};
export const isHostedLayer = <
  G extends PublicGisLayerCommon,
  PG extends PublicGisLayerHosted & G
>(
  f: G
): f is PG => {
  return f.sourceType === SourceTypesLayer.hosted;
};
export const isArcgisLayer = <
  G extends PublicGisLayerCommon,
  PG extends PublicGisLayerArcgis & G
>(
  f: G
): f is PG => {
  return f.sourceType === SourceTypesLayer.arcgis;
};

export type PublicGisLayerCommon = z.infer<typeof _PublicGisLayerCommon>;
export type PublicGisLayers = z.infer<typeof _PublicGisLayers>;
