import { LineString, Point } from "@turf/turf";
import {
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
} from "../constants/wiring";
import { ProjectFeature } from "../services/types";

export type Cable = {
  type: typeof CABLE_PROPERTY_TYPE;
  fromId: string;
  toId: string;
  // NOTE: this is unused for now.  When we add in proper cables we'll use it again.
  cableType?: string;
  /** The partition the cable is in from the sweeping cable algorithm. */
  partition?: number;
  powerLoad?: number;
};

export type CableFeature = ProjectFeature<LineString, Cable>;
export const isCable = (f: ProjectFeature | undefined): f is CableFeature =>
  f?.properties?.type === CABLE_PROPERTY_TYPE;

export type ExportCable = {
  type: typeof EXPORT_CABLE_PROPERTY_TYPE;
  powerLoad?: number;
};

export const isExportCable = (
  f: ProjectFeature | undefined
): f is ExportCableFeature =>
  f?.properties?.type === EXPORT_CABLE_PROPERTY_TYPE;

export type ExportCableFeature = ProjectFeature<LineString, ExportCable>;

export type Substation = {
  type: typeof SUBSTATION_PROPERTY_TYPE;
  name: string;
  color: string;
};

export type SubstationFeature = ProjectFeature<Point, Substation>;
