import styled from "styled-components";
import EyeOpen from "../../icons/24/View.svg";
import EyeClosed from "../../icons/24/ViewOff.svg";
import Search from "../../icons/24/Search.svg";
import { StandardBox } from "../../styles/boxes/Boxes";

export const LAYER_WIDTH = 270;

export const Wrapper = styled(StandardBox)<{ open?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: 0.1s;
  margin-right: ${(p) => (p.open ? "0" : `-${LAYER_WIDTH}px`)};
  width: ${LAYER_WIDTH}px;
  border-radius: 0;
  height: 100vh;
  z-index: 2;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 10px 20px 30px 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const RowName = styled.div`
  overflow-wrap: anywhere;
`;

export const IconWithName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  svg {
    width: 1.6rem;
    height: 3rem;
  }
  img {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const ActionIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  svg {
    width: 1.6rem;
    height: 3rem;
  }
`;

export const HiddenIcon = styled.div<{ icon: string }>`
  background-image: url(${(p) => p.icon});
  display: none;
  background-size: cover;
  width: 1.6rem;
  height: 1.6rem;
  flex: 0 0 auto;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

export const RowEyeIcon = styled.div<{ selected?: boolean }>`
  filter: ${(p) =>
    p.selected
      ? "inherit"
      : "invert(48%) sepia(13%) saturate(1207%) hue-rotate(130deg) brightness(95%) contrast(80%)"};
  background-image: url(${(p) => (p.selected ? EyeOpen : EyeClosed)});
  background-size: cover;
  width: 1.6rem;
  height: 1.6rem;
  flex: 0 0 auto;
`;

export const RowSearchIcon = styled.div`
  background-image: url(${Search});
  background-size: cover;
  width: 1.6rem;
  height: 1.6rem;
  flex: 0 0 auto;
`;

export const RowWrapper = styled.div`
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  margin: 5px 0;
  align-items: center;

  svg {
    fill-opacity: 0.7;
  }

  img {
    opacity: 0.7;
  }
  &:hover {
    svg {
      fill-opacity: 1;
    }
    img {
      opacity: 1;
    }
    div {
      color: #333;
    }
    ${HiddenIcon} {
      display: inherit;
    }
  }
`;

export const SubHeader = styled.h4`
  padding: 1rem 0;
  margin: 0;
`;

export const Center = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const LanguageSwitchWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;
