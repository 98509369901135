import React, { useCallback, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { modalTypeOpenAtom } from "../../state/modal";
import { StandardBox } from "../../styles/boxes/Boxes";
import { Ui14Bold, Ui18Bold } from "../../styles/typography";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import Button from "../General/Button";
import Spinner from "../../icons/spinner/Spinner";
import { dynamicLayersSelector } from "../../state/layer";
import { PublicGisLayerCommon } from "../../types/gisData";
import { Row } from "../CanvasLayerInfoModal/InfoModal.style";
import Fuse from "fuse.js";
import { colors } from "../../styles/colors";
import { mapRefAtom } from "../../state/map";
import { useGoToFeatures } from "../../hooks/map";

export const SearchExernalLayerModalType = "SearchExernalLayerModalType";

const DEFAULT_PROPERTY_FOR_RWE = "fastighet";

const Wrapper = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 2rem;
  max-width: 60%;
`;

const SearchRow = styled(Row)`
  gap: 0.8rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
  justify-content: flex-end;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Header = styled(Ui18Bold)`
  color: #264e5d;
  margin: 0;
`;

const SubHeader = styled(Ui14Bold)`
  color: #264e5d;
  margin: 0;
`;

const SearchResultWrapper = styled.div`
  display: flex;
  position: relative;
`;

const SearchResults = styled.div`
  display: flex;
  top: -2rem;
  position: absolute;
  flex-direction: column;
  background-color: white;
  max-height: 30rem;
  overflow-y: auto;
`;

const SearchResultRow = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 1rem 1rem;

  &:hover {
    background-color: ${colors.blueMediumLight};
  }
`;

const SearchExernalLayerModalInner = ({
  layer,
}: {
  layer: PublicGisLayerCommon;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const rawData = useRecoilValue(dynamicLayersSelector({ layer }));
  const map = useRecoilValue(mapRefAtom);
  const goToFeatures = useGoToFeatures(map);

  const availableProperties = useMemo(
    () => [
      ...new Set(rawData.features.flatMap((f) => Object.keys(f.properties))),
    ],
    [rawData.features]
  );

  const [chosenProperty, setChosenProperty] = useState(
    availableProperties.length === 0
      ? undefined
      : availableProperties.find((p) => p === DEFAULT_PROPERTY_FOR_RWE) ??
          availableProperties[0]
  );
  const [searchText, setSearchText] = useState("");

  const fuseSearchList = useMemo(
    () =>
      new Fuse(
        (rawData.features as any[]).reduce(
          (acc, f) => [...acc, { ...(f.properties ?? {}), id: f.id }],
          [] as Record<string, any>[]
        ) as Record<string, any>[],
        { keys: [chosenProperty ?? ""], threshold: 0.4 }
      ),
    [chosenProperty, rawData.features]
  );

  const fuseSearchResult = useMemo(
    () => fuseSearchList.search(searchText),
    [fuseSearchList, searchText]
  );

  const goToFeature = useCallback(
    (featureId: string, e?: any, boundBuffer?: number) => {
      const feature = rawData.features.find((f) => f.id === featureId);
      if (!feature) return;
      goToFeatures([feature], e, boundBuffer);
      setModalTypeOpen(undefined);
    },
    [rawData, setModalTypeOpen, goToFeatures]
  );

  return (
    <>
      <HeaderWrapper>
        <Header>Search in</Header>
        <SubHeader>{layer.name}</SubHeader>
      </HeaderWrapper>
      {chosenProperty && (
        <SearchRow>
          <input
            value={searchText}
            placeholder={"Search..."}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <select
            value={chosenProperty}
            onChange={(e) => setChosenProperty(e.target.value)}
          >
            {availableProperties.map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </select>
        </SearchRow>
      )}
      {!chosenProperty && <div>Feature lacks properties...</div>}
      {chosenProperty && fuseSearchResult.length !== 0 && (
        <SearchResultWrapper>
          <SearchResults>
            {fuseSearchResult.map((r) => (
              <SearchResultRow
                key={r.item.id}
                onClick={(e) => goToFeature(r.item.id, e, 0.01)}
              >
                {r.item[chosenProperty]}
              </SearchResultRow>
            ))}
          </SearchResults>
        </SearchResultWrapper>
      )}
      <ButtonContainer>
        <Button text="Close" onClick={() => setModalTypeOpen(undefined)} />
      </ButtonContainer>
    </>
  );
};

const SearchExernalLayerModal = () => {
  const modalTypeOpen = useRecoilValue(modalTypeOpenAtom);

  if (modalTypeOpen?.modalType !== SearchExernalLayerModalType) return null;

  return (
    <FullScreenModal>
      <Wrapper>
        <React.Suspense fallback={<Spinner />}>
          <SearchExernalLayerModalInner layer={modalTypeOpen.metadata} />
        </React.Suspense>
      </Wrapper>
    </FullScreenModal>
  );
};

export default SearchExernalLayerModal;
