import { atom } from "recoil";
import { ProjectElementMenuType } from "../components/ProjectElements/ProjectElements";
import { OptimizeWindTurbinesMenuType } from "../components/OptimizeLayout/OptimizeLayout";
import { MeasureAreaMode } from "../components/Area/Area";
import { MeasureDistanceMode } from "../components/Distance/Distance";

export const DrawLineStringMenuType = "draw-line-string-menu-type";
export const DrawParkMenuType = "draw-park-menu-type";
export const DrawPolygonMenuType = "draw-polygon-menu-type";
export const DrawPointMenuType = "draw-point-menu-type";

type LeftMenuModeType =
  | undefined
  | typeof ProjectElementMenuType
  | typeof OptimizeWindTurbinesMenuType
  | typeof DrawLineStringMenuType
  | typeof DrawParkMenuType
  | typeof DrawPolygonMenuType
  | typeof DrawPointMenuType
  | typeof MeasureDistanceMode
  | typeof MeasureAreaMode;

export const leftMenuModeActiveAtom = atom<LeftMenuModeType>({
  key: "leftMenuModeActiveAtom",
  default: undefined,
});
