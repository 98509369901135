import { ProjectNodeInformation } from "../types/node";
import { _ProjectNodeInformation } from "./projectDataAPIService";
import { fetchSchema } from "./utils";

export const getPublicProjectInfo = async (
  customerId: string,
  projectId: string
): Promise<ProjectNodeInformation> =>
  fetchSchema(
    _ProjectNodeInformation,
    `/api/customer/public/project/${customerId}/${projectId}`,
    {
      method: "get",
    }
  );
