import { useRecoilState, useRecoilValue } from "recoil";
import {
  canvasLayerImageFeaturesSelector,
  canvasLayerLineFeaturesSelector,
  canvasLayerPointFeaturesSelector,
  canvasLayerPolygonFeaturesSelector,
} from "../state/projectLayers";
import { mapRefAtom } from "../state/map";
import { currentSelectionArrayAtom } from "../state/selection";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getGeorefImageSelectorFamily } from "../state/georef";
import { transformBBOXToWGS84Square } from "../utils/proj4";
import mapboxgl, {
  CirclePaint,
  CustomLayerInterface,
  FillPaint,
  LinePaint,
  MapboxGeoJSONFeature,
  SymbolLayer,
} from "mapbox-gl";
import { colors } from "../styles/colors";
import { fetchEnhancer } from "../services/utils";
import Polygon from "../components/MapFeatures/Polygon";
import LineString from "../components/MapFeatures/LineString";
import Point from "../components/MapFeatures/Point";
import { TypedArray } from "geotiff";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
} from "../services/types";
import { useTypedPath } from "../state/pathParams";
import {
  opacityPropertyName,
  zoomPropertyName,
  ZOOM_SHOW_THRESHOLD,
} from "../constants/canvas";

export const canvasGeotiffLayerId = "canvas-geotiff-layer-id";
export const canvasPolygonLayerId = `canvas-polygon-layer-id`;
export const canvasPointLayerId = `canvas-point-layer-id`;
export const canvasLineLayerId = `canvas-line-layer-id`;

export const canvasGeotiffLayerSource = "canvas-layer-geotiff-source-id";
export const canvasPolygonLayerSource = "canvas-layer-polygon-source-id";
export const canvasPointLayerSource = "canvas-layer-point-source-id";
export const canvasLineLayerSource = "canvas-layer-line-source-id";

export const canvasSources = [
  canvasGeotiffLayerSource,
  canvasPolygonLayerSource,
  canvasPointLayerSource,
  canvasLineLayerSource,
];

const DEFAULT_CANVAS_LAYER_OPACITY = 0.4;
export const DEFAULT_CANVAS_LAYER_COLOR = colors.gray;
export const DEFAULT_CANVAS_POINT_COLOR = colors.brown;

const polygonSymbols = {
  type: "symbol",
  minzoom: 5,
  layout: {
    "symbol-placement": "point",
    "text-field": [
      "case",
      ["boolean", ["has", "fastighet"], false],
      ["get", "fastighet"],
      ["get", "name"],
    ],
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: {
    "text-opacity": [
      "step",
      ["zoom"],
      ["case", ["==", ["get", zoomPropertyName], true], 0, 0],
      ZOOM_SHOW_THRESHOLD,
      [
        "case",
        ["boolean", ["feature-state", "editing"], false],
        0.0,
        ["==", ["get", "type"], BathymetryUserUploadedType],
        0.0,
        ["==", ["get", "type"], GeoTiffUserUploadedImageType],
        0.0,
        0.6,
      ],
    ],
  },
} as Omit<SymbolLayer, "id" | "source">;

const polygonFillNormalOpacityCase = [
  "case",
  ["==", ["get", "type"], GeoTiffUserUploadedImageType],
  0.0,
  ["==", ["get", "type"], BathymetryUserUploadedType],
  0.0,
  ["boolean", ["feature-state", "editing"], false],
  0.0,
  ["boolean", ["feature-state", "hover"], ["feature-state", "selected"], false],
  [
    "+",
    ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
    0.2,
  ],
  ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
];

const polygonPaint = {
  "fill-color": ["string", ["get", "color"], DEFAULT_CANVAS_LAYER_COLOR],
  "fill-opacity": [
    "step",
    ["zoom"],
    [
      "case",
      ["==", ["get", zoomPropertyName], true],
      0,
      polygonFillNormalOpacityCase,
    ],
    ZOOM_SHOW_THRESHOLD,
    polygonFillNormalOpacityCase,
  ],
} as FillPaint;

const polygonLineNormalOpacityCase = [
  "case",
  ["boolean", ["feature-state", "editing"], false],
  1.0,
  ["boolean", ["feature-state", "selected"], false],
  1.0,
  ["==", ["get", opacityPropertyName], 0.0],
  1.0,
  0.0,
];

const polygonLinePaint = {
  "line-color": ["string", ["get", "color"], DEFAULT_CANVAS_LAYER_COLOR],
  "line-width": [
    "case",
    ["boolean", ["feature-state", "editing"], false],
    3.0,
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    3.0,
    1.0,
  ],
  "line-opacity": [
    "step",
    ["zoom"],
    [
      "case",
      ["==", ["get", zoomPropertyName], true],
      0,
      polygonLineNormalOpacityCase,
    ],
    ZOOM_SHOW_THRESHOLD,
    polygonLineNormalOpacityCase,
  ],
} as LinePaint;

const pointSymbols = {
  type: "symbol",
  minzoom: 5,
  layout: {
    "symbol-placement": "point",
    "text-field": "{name}",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: { "text-opacity": 0.6 },
} as Omit<SymbolLayer, "id" | "source">;

const pointPaint = {
  "circle-color": ["string", ["get", "color"], DEFAULT_CANVAS_LAYER_COLOR],
  "circle-radius": 5,
  "circle-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    [
      "+",
      ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
      0.2,
    ],
    ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
  ],
} as CirclePaint;

const linePaint = {
  "line-color": ["string", ["get", "color"], DEFAULT_CANVAS_LAYER_COLOR],
  "line-width": 5,
  "line-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    [
      "+",
      ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
      0.2,
    ],
    ["number", ["get", opacityPropertyName], DEFAULT_CANVAS_LAYER_OPACITY],
  ],
} as LinePaint;

const lineSymbols = {
  type: "symbol",
  minzoom: 5,
  layout: {
    "symbol-placement": "line",
    "text-field": "{name}",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: { "text-opacity": 0.6 },
} as Omit<SymbolLayer, "id" | "source">;

export const selectMethod = (append, features, setCurrentSelectionArray) => {
  setCurrentSelectionArray((csa) => {
    const selected = csa.find((s) => s.id === features[0].id);

    const newSelection = features[0];
    if (append) {
      if (selected) {
        return csa.filter((s) => s.id !== features[0].id);
      }
      return [...csa, newSelection];
    } else {
      if (selected && csa.length === 1) {
        return [];
      }
      return [newSelection];
    }
  });
};

export const CanvasLayerPublic = ({ selectable = true, beforeId }) => {
  const canvasLayerPolygonFeatures = useRecoilValue(
    canvasLayerPolygonFeaturesSelector
  );
  const canvasLayerLineFeatures = useRecoilValue(
    canvasLayerLineFeaturesSelector
  );
  const canvasLayerPointFeatures = useRecoilValue(
    canvasLayerPointFeaturesSelector
  );
  const canvasLayerImageFeatures = useRecoilValue(
    canvasLayerImageFeaturesSelector
  );

  return (
    <>
      <CanvasPolygonLayers
        canvasLayerPolygonFeatures={canvasLayerPolygonFeatures}
        selectable={selectable}
        beforeId={beforeId}
      />
      <CanvasLineLayers
        canvasLayerLineFeatures={canvasLayerLineFeatures}
        selectable={selectable}
      />
      <CanvasPointLayers
        canvasLayerPointFeatures={canvasLayerPointFeatures}
        selectable={selectable}
      />
      {canvasLayerImageFeatures.map((canvasLayerImageFeature) => (
        <CanvasImageLayer
          key={canvasLayerImageFeature.id}
          canvasLayerImageFeature={canvasLayerImageFeature}
        />
      ))}
    </>
  );
};

const CanvasImageLayer = ({ canvasLayerImageFeature }) => {
  const { customerId, projectId, branchId } = useTypedPath(
    "customerId",
    "projectId",
    "branchId"
  );
  const geotiff = useRecoilValue(
    getGeorefImageSelectorFamily({
      customerId,
      projectId,
      branchId,
      filename: canvasLayerImageFeature.properties.filename,
    })
  );
  const map = useRecoilValue(mapRefAtom);
  const [image, setImage] = useState<HTMLImageElement>();
  const [points, setPoints] = useState<number[]>();

  const layerId = useMemo(
    () => `geotiff-image-layer-${canvasLayerImageFeature.id}`,
    [canvasLayerImageFeature]
  );

  useEffect(() => {
    if (!geotiff) return;
    let isCancelled = false;
    const initBBOX = async () => {
      const image = await geotiff.getImage();
      const bbox = image.getBoundingBox();
      const epsg =
        image.geoKeys.ProjectedCSTypeGeoKey ||
        image.geoKeys.GeographicTypeGeoKey;

      let square = [
        { lng: bbox[0], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[1] },
        { lng: bbox[2], lat: bbox[3] },
        { lng: bbox[0], lat: bbox[3] },
      ];

      if (epsg !== 4326) {
        const response = await fetchEnhancer(`https://epsg.io/${epsg}.proj4`, {
          method: "get",
        });
        const proj4String = await response.text();
        square = transformBBOXToWGS84Square(bbox, proj4String);
      }

      const lowerLeft = mapboxgl.MercatorCoordinate.fromLngLat(square[0]);
      const lowerRight = mapboxgl.MercatorCoordinate.fromLngLat(square[1]);
      const upperRight = mapboxgl.MercatorCoordinate.fromLngLat(square[2]);
      const upperLeft = mapboxgl.MercatorCoordinate.fromLngLat(square[3]);

      if (isCancelled) return;

      setPoints([
        upperLeft.x,
        upperLeft.y,
        upperRight.x,
        upperRight.y,
        lowerLeft.x,
        lowerLeft.y,

        lowerLeft.x,
        lowerLeft.y,
        upperRight.x,
        upperRight.y,
        lowerRight.x,
        lowerRight.y,
      ]);

      const data = (await image.readRGB({
        interleave: true,
        enableAlpha: true,
      })) as TypedArray & { height: number; width: number }; // NOTE: see https://geotiffjs.github.io/geotiff.js/module-geotiff.html#~ReadRasterResult

      const canvas = document.createElement("canvas");
      canvas.width = data.width;
      canvas.height = data.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      var imgData = ctx.createImageData(data.width, data.height);

      if (image.getSamplesPerPixel() === 4) {
        imgData.data.set(data);
      } else {
        let alphaSkip = 0;
        for (var i = 0; i < data.length; i += 3) {
          imgData.data[i + alphaSkip] = data[i]; //red
          imgData.data[i + 1 + alphaSkip] = data[i + 1]; //green
          imgData.data[i + 2 + alphaSkip] = data[i + 2]; //blue
          imgData.data[i + 3 + alphaSkip] = 255; //alpha
          alphaSkip++;
        }
      }

      ctx.putImageData(imgData, 0, 0);

      const rotatedImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      var imageTag = new Image();
      imageTag.src = rotatedImage;
      imageTag.onload = function () {
        if (isCancelled) return;
        setImage(imageTag);
      };
    };
    initBBOX();
    return () => {
      isCancelled = true;
    };
  }, [geotiff, setImage, setPoints]);

  useEffect(() => {
    if (!map || !points || !image || !layerId) return;

    const geotiffLayer: CustomLayerInterface & {
      program?: WebGLProgram;
      positionLocation?: number;
      texcoordLocation?: number;
      positionBuffer?: WebGLBuffer;
      texcoordBuffer?: WebGLBuffer;
      texture?: WebGLTexture;
    } = {
      id: layerId,
      type: "custom" as const,

      // method called when the layer is added to the map
      // https://docs.mapbox.com/mapbox-gl-js/api/#styleimageinterface#onadd
      onAdd: function (map, gl) {
        // create GLSL source for vertex shader
        const vertexSource = `
          attribute vec2 a_texCoord;
          varying vec2 v_texCoord;

          uniform mat4 u_matrix;
          attribute vec2 a_position;
          void main() {
            v_texCoord = a_texCoord;
            gl_Position = u_matrix * vec4(a_position, 0.0, 1.0);
          }`;

        // create GLSL source for fragment shader
        const fragmentSource = `
          precision mediump float;
          uniform sampler2D u_image;
          varying vec2 a_texCoord;
          varying vec2 v_texCoord;

          void main() {
            gl_FragColor = texture2D(u_image, v_texCoord);
          }`;

        // create a vertex shader
        const vertexShader = gl.createShader(gl.VERTEX_SHADER)!;
        gl.shaderSource(vertexShader, vertexSource);
        gl.compileShader(vertexShader);

        // create a fragment shader
        const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)!;
        gl.shaderSource(fragmentShader, fragmentSource);
        gl.compileShader(fragmentShader);

        // link the two shaders into a WebGL program
        this.program = gl.createProgram()!;
        gl.attachShader(this.program, vertexShader);
        gl.attachShader(this.program, fragmentShader);
        gl.linkProgram(this.program);

        this.positionLocation = gl.getAttribLocation(
          this.program,
          "a_position"
        );
        this.texcoordLocation = gl.getAttribLocation(
          this.program,
          "a_texCoord"
        );
      },

      // method fired on each animation frame
      // https://docs.mapbox.com/mapbox-gl-js/api/#map.event:render
      render: function (gl, matrix) {
        this.positionBuffer = gl.createBuffer()!;
        gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer);
        gl.bufferData(
          gl.ARRAY_BUFFER,
          new Float32Array(points),
          gl.STATIC_DRAW
        );

        this.texcoordBuffer = gl.createBuffer()!;
        gl.bindBuffer(gl.ARRAY_BUFFER, this.texcoordBuffer);
        gl.bufferData(
          gl.ARRAY_BUFFER,
          new Float32Array([
            0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
          ]),
          gl.STATIC_DRAW
        );

        this.texture = gl.createTexture()!;
        gl.bindTexture(gl.TEXTURE_2D, this.texture);

        // Set the parameters so we can render any size image.
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

        // Upload the image into the texture.
        gl.texImage2D(
          gl.TEXTURE_2D,
          0,
          gl.RGBA,
          gl.RGBA,
          gl.UNSIGNED_BYTE,
          image
        );

        gl.useProgram(this.program!);

        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        gl.enableVertexAttribArray(this.positionLocation!);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer);

        gl.vertexAttribPointer(
          this.positionLocation!,
          2,
          gl.FLOAT,
          false,
          0,
          0
        );

        gl.enableVertexAttribArray(this.texcoordLocation!);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.texcoordBuffer);

        gl.vertexAttribPointer(
          this.texcoordLocation!,
          2,
          gl.FLOAT,
          false,
          0,
          0
        );

        gl.uniformMatrix4fv(
          gl.getUniformLocation(this.program!, "u_matrix"),
          false,
          matrix
        );

        gl.drawArrays(gl.TRIANGLES, 0, 6);
      },
    };

    map.addLayer(
      geotiffLayer,
      map.getLayer(canvasPolygonLayerId) ? canvasPolygonLayerId : undefined
    );

    return () => {
      map.removeLayer(geotiffLayer.id);
    };
  }, [points, image, map, layerId]);

  return null;
};

const CanvasPointLayers = ({ canvasLayerPointFeatures, selectable }) => {
  const map = useRecoilValue(mapRefAtom);

  const [currentSelectionArray, setCurrentSelectionArray] = useRecoilState(
    currentSelectionArrayAtom
  );

  const selectedIds = useMemo(
    () =>
      currentSelectionArray
        .filter((cs) => cs.source === canvasPointLayerSource)
        .map((s) => s.properties.id),
    [currentSelectionArray]
  );

  const onClickCallback = useCallback(
    (features: MapboxGeoJSONFeature[], shiftClicked: boolean) => {
      selectMethod(shiftClicked, features, setCurrentSelectionArray);
    },
    [setCurrentSelectionArray]
  );

  if (!map) return null;
  return (
    <Point
      symbols={pointSymbols}
      paint={pointPaint}
      map={map}
      features={canvasLayerPointFeatures}
      layerId={canvasPointLayerId}
      sourceId={canvasPointLayerSource}
      onClickCallback={selectable && onClickCallback}
      selectedIds={selectedIds}
    />
  );
};

const CanvasLineLayers = ({ canvasLayerLineFeatures, selectable }) => {
  const map = useRecoilValue(mapRefAtom);

  const [currentSelectionArray, setCurrentSelectionArray] = useRecoilState(
    currentSelectionArrayAtom
  );

  const onClickCallback = useCallback(
    (features: MapboxGeoJSONFeature[], shiftClicked: boolean) => {
      selectMethod(shiftClicked, features, setCurrentSelectionArray);
    },
    [setCurrentSelectionArray]
  );

  const selectedIds = useMemo(
    () =>
      currentSelectionArray
        .filter((cs) => cs.source === canvasLineLayerSource)
        .map((s) => s.properties.id),
    [currentSelectionArray]
  );

  if (!map) return null;
  return (
    <LineString
      paint={linePaint}
      symbols={lineSymbols}
      features={canvasLayerLineFeatures}
      map={map}
      sourceId={canvasLineLayerSource}
      layerId={canvasLineLayerId}
      onClickCallback={selectable && onClickCallback}
      selectedIds={selectedIds}
    />
  );
};

const CanvasPolygonLayers = ({
  canvasLayerPolygonFeatures,
  selectable,
  beforeId,
}) => {
  const map = useRecoilValue(mapRefAtom);
  const [currentSelectionArray, setCurrentSelectionArray] = useRecoilState(
    currentSelectionArrayAtom
  );
  const selectedIds = useMemo(
    () =>
      currentSelectionArray
        .filter((cs) => cs.source === canvasPolygonLayerSource)
        .map((s) => s.properties.id),
    [currentSelectionArray]
  );

  const onClickCallback = useCallback(
    (features: MapboxGeoJSONFeature[], shiftClicked: boolean) => {
      selectMethod(shiftClicked, features, setCurrentSelectionArray);
    },
    [setCurrentSelectionArray]
  );

  if (!map) return null;

  return (
    <Polygon
      features={canvasLayerPolygonFeatures}
      sourceId={canvasPolygonLayerSource}
      layerId={canvasPolygonLayerId}
      symbols={polygonSymbols}
      map={map}
      paint={polygonPaint}
      linePaint={polygonLinePaint}
      beforeId={beforeId}
      onClickCallback={selectable && onClickCallback}
      selectedIds={selectedIds}
    />
  );
};
