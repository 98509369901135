import { atom } from "recoil";
export const toastMessagesAtom = atom<
  { text: string; timeout: number; id?: string }[]
>({
  key: "toastMessagesAtom",
  default: [],
});
export const notificationMessageAtom = atom<string | undefined>({
  key: "notificationMessageAtom",
  default: undefined,
});
