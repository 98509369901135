import GeoTIFF, { fromBlob } from "geotiff";
import { selectorFamily } from "recoil";
import { getGeotiffImage } from "../services/projectDataAPIService";
import { publicNodeInfoSelectorFamily } from "./projectAPI";

export const getGeorefImageSelectorFamily = selectorFamily<
  GeoTIFF,
  { customerId: string; projectId: string; branchId: string; filename: string }
>({
  key: "getGeorefImageSelectorFamily",
  get:
    ({ customerId, projectId, branchId, filename }) =>
    async ({ get }) => {
      const node = get(publicNodeInfoSelectorFamily({ customerId, projectId }));
      const blob = await getGeotiffImage(node.id, branchId, filename);
      return await fromBlob(blob);
    },
  dangerouslyAllowMutability: true,
});
