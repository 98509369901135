export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export function movingWindow<T>(array: T[], size: number = 2): T[][] {
  const end = array.length - (size - 1);
  return movingWindowLoop(array, size).slice(0, end);
}

export function movingWindowLoop<T>(array: T[], size: number = 2): T[][] {
  if (array.length < size)
    throw new Error(
      `size must be smaller than array length: ${array.length} < ${size}`
    );
  let ret: T[][] = [];
  for (let i = 0; i < array.length; i++) {
    const end = i + size;
    if (array.length <= end) {
      ret.push([...array.slice(i), ...array.slice(0, end - array.length)]);
    } else {
      ret.push(array.slice(i, end));
    }
  }
  return ret;
}

export const indexOfMin = <T>(
  array: T[],
  fn: (t: T) => number
): undefined | number => {
  if (array.length === 0) return;
  return array.reduce(
    ([bestI, bestC], e, i) => {
      const c = fn(e);
      if (c < bestC) return [i, c];
      return [bestI, bestC];
    },
    [Infinity, Infinity]
  )[0];
};

export const downloadBlob = (blob, filename) => {
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export function objectEquals(a: any, b: any): boolean {
  if (a === b) return true;
  if (typeof a !== typeof b) return false;
  if (typeof a !== "object" || a === null) return a === b;
  if (Array.isArray(a) !== Array.isArray(b)) return false;
  if (Object.keys(a).length !== Object.keys(b).length) return false;
  for (const key of Object.keys(a)) {
    if (!(key in b)) return false;
    if (!objectEquals(a[key], b[key])) return false;
  }
  return true;
}

/** Ensures the argument is of type `never`.
 *
 * This is useful for making sure that all union types are covered in a code path. For instance, if you have
 * a variable `const a: string | number = ...` and branch on the type you can assert at compile time that
 * both the `string` and `number` paths are covered.  If you then add a third type to the union, it will be
 * a type error to not also update the branch.
 * ```
 * const a: string | number = ...
 * if (typeof a === 'string') {
 *     ...
 * } else if (typeof a === 'number') {
 *     ...
 * } else {
 *     isNever(a); // ok
 * }
 * ```
 * If another variant is added, this will not typecheck
 * ```
 * const a: string | number | Feature = ...
 * if (typeof a === 'string') {
 *     ...
 * } else if (typeof a === 'number') {
 *     ...
 * } else {
 *     isNever(a); // Argument of type 'Feature' is not assignable to parameter of type 'never'.
 * }
 * ```
 */
export const isNever = (_: never): void => {};

export const isInChecklyMode = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  return !!params["checkly"];
};

/** Type for our Worker wrapper with input and output types. If we need more
 * functionality from `Worker` (similar to `terminate()`), add it here. */
export type TypedWorker<Args, Ret> = {
  postMessage: (args: Args) => void;
  /** A callback for receiving messages in the worker.  Note that this is a slightly different
   * API than the standard Worker api, since we pass a callback instad of assinging one.*/
  readonly onmessage: (f: (ret: MessageEvent<Ret>) => any) => void;
  /** A callback for worker errors.  Note that this is a slightly different
   * API than the standard Worker api, since we pass a callback instad of assinging one.*/
  readonly onerror: (f: (e: ErrorEvent) => any) => void;
  terminate: () => void;
};

/** Wrap a `Worker` with the specified type to get type safety for web workers. */
export const typedWorker = <Args, Ret>(
  worker: Worker
): TypedWorker<Args, Ret> => ({
  postMessage: (args: Args) => worker.postMessage(args),
  onmessage: (f: (ret: MessageEvent<Ret>) => void) => (worker.onmessage = f),
  onerror: (f: (e: ErrorEvent) => void) => {
    worker.onerror = f;
  },
  terminate: () => worker.terminate(),
});

/** Assertion useful for type narrowing. */
export function assert(
  condition: boolean,
  message: string = ""
): asserts condition {
  if (!condition) throw new Error(message);
}

/** Assertion useful for type narrowing. */
export function defined<T>(
  expr: T | undefined,
  message: string = ""
): asserts expr is T {
  assert(expr !== undefined, message);
}

/** Get a partial type with the given union type required.  For example,
 * `With<api.ProjectMeta, 'id'>` is a `Partial<api.ProjectMeta>` but with the `id` field required.
 *
 * From https://stackoverflow.com/a/57390160
 */
export type With<T, K extends keyof T> = Partial<T> & Required<Pick<T, K>>;

export const zip = <A, B>(a: A[], b: B[]): [A, B][] => {
  const n = Math.min(a.length, b.length);
  return a.slice(0, n).map((aa, i) => [aa, b[i]]);
};

export const clamp = (a: number, x: number, b: number): number => {
  if (x < a) return a;
  if (b < x) return b;
  return x;
};

/** Utility for type narrowing to filter out undefined objects. */
export const isDefined = <T>(t: T | undefined): t is T => t !== undefined;

export const mapToRecord = <K extends string | number | symbol, V>(
  map: Map<K, V>
): Record<K, V> => {
  const fields = [...map.entries()];
  const ret = fields.reduce((a, [k, v]) => {
    return { ...a, [k]: v };
  }, {} as Partial<Record<K, V>>);
  return ret as Record<K, V>;
};

export const dedup = <T>(array: T[]): T[] => [...new Set(array).values()];

export const appendQueryParamsSign = (url: string) =>
  url.includes("?") ? "&" : "?";
