import { useEffect, useMemo, useRef, useState } from "react";
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import * as THREE from "three";
import styled from "styled-components";
import { Water } from "three/examples/jsm/objects/Water";
import { Sky } from "three/examples/jsm/objects/Sky";
import WaterNormals from "./waternormals.jpg";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import {
  getDistanceFromLatLonInM,
  projectedToWGS84,
  wgs84ToProjected,
} from "../../utils/proj4";
import * as turf from "@turf/turf";
import { getBBOXArray } from "../../utils/geojson/validate";
import SimplePark from "../MapboxRendering/SimplePark";
import { mapboxAccessToken } from "../MapNative/MapNative";
import mapboxgl, { LngLatBoundsLike } from "mapbox-gl";
import Eye from "../../icons/24/View.png";
import SunCalc from "suncalc";
import { ToEastLatLng } from "../../utils/geojson/utils";
import { Input, InputContainer, InputLabel } from "../General/Input";
import { TurbineFeature, getTurbinesSelectorFamily } from "../../state/layout";
import { v4 as uuidv4 } from "uuid";
import {
  Group,
  Mesh,
  PerspectiveCamera,
  PMREMGenerator,
  Scene,
  WebGLRenderTarget,
} from "three";
import { LineString } from "@turf/turf";
import RangeWithLabel from "../General/RangeWithLabel";
import Checkbox from "../General/Checkbox";
import * as utm from "utm";
import { ReactComponent as HelpIcon } from "../../icons/24/Help.svg";
import {
  viewCameraAspectAtom,
  viewDateAtom,
  viewFovAtom,
  viewFromShoreVisibleAtom,
  viewHeightAtom,
  viewOrigoSelector,
  viewOrigoWGS84Atom,
  viewParkRotationAtom,
  viewPositionAtom,
  viewProj4StringAtom,
  viewTurbineCoordsAtom,
  viewTurbineHeightsAtom,
  viewTurbineLightsAtom,
  viewViewModeAtom,
  VIEW_MODE,
  LngLat,
} from "../../state/viewToPark";
import { BOUNDS_BUFFER } from "../../constants/misc";
import { useLocation, useNavigate } from "react-router-dom";
import Tooltip from "../General/Tooltip";
import { libraryLayersOpenAtom } from "../../state/layer";
import { isDefined, isInChecklyMode } from "../../utils/utils";
import Button from "../General/Button";
import { getParkFeatureSelectorFamily } from "../../state/park";
import { ProjectFeature } from "../../services/types";

let windTurbineLightsGlobal = new Group();
let turbineRotorsGlobal = new Group();

mapboxgl.accessToken = mapboxAccessToken;

const MESH_TURBINE_NAME = "turbine";
const MESH_ROTOR_NAME = "rotor";

const HEIGHT_OF_TURBINE_MODEL = 80;
const ROTOR_OFFSET = 1.03;
export const DIVISION_FACTOR = 100;
const DISTANCE_TO_WIRE_FRAME_SUN = 300000 / DIVISION_FACTOR;
const WIRE_FRAME_SUN_RADIUS = 32;

const ViewPositionMapBoxId = "viewPosition";
const FOVLineMapBoxId = "FOVLine";
const ViewOrigoPositionMapBoxId = "viewOrigo";

const EARTH_RADIUS_NORMALIZED = 6371000 / DIVISION_FACTOR;

const calcDistanceToHorizon = (viewHeight: number) =>
  Math.sqrt(2 * EARTH_RADIUS_NORMALIZED * viewHeight + Math.pow(viewHeight, 2));

const ThreeSceneWrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  height: ${(p) => (p.visible ? "100%" : "1px")};
  width: ${(p) => (p.visible ? "100%" : "1px")};
  align-items: center;
  background-color: white;
  z-index: ${(p) => (p.visible ? "2" : "-1")};
`;

const ThreeScene = styled.div`
  width: calc(100%);
  height: calc((100vw) * 0.5625);
`;

const ViewPositionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const HelpWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const MapControllerWrapper = styled.div`
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-height: 85vh;
  padding: 1rem;
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 15rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  align-items: center;
`;

const DistanceWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const ViewToPark = ({
  parkId,
  projectData,
  turbineHeights,
  publicMode,
}: {
  parkId: string;
  projectData: ProjectFeature[];
  turbineHeights: Map<string, number>;
  publicMode: boolean;
}) => {
  const [viewPosition, setViewPosition] = useRecoilState(viewPositionAtom);
  const [date, setDate] = useRecoilState(viewDateAtom);
  const [fov, setFov] = useRecoilState(viewFovAtom);
  const [parkRotation, setParkRotation] = useRecoilState(viewParkRotationAtom);
  const [viewHeight, setViewHeight] = useRecoilState(viewHeightAtom);
  const [origoWGS84, setOrigoWGS84] = useRecoilState(viewOrigoWGS84Atom);
  const cameraAspect = useRecoilValue(viewCameraAspectAtom);
  const [viewMode, setViewMode] = useRecoilState(viewViewModeAtom);
  const [turbineLights, setTurbineLights] = useRecoilState(
    viewTurbineLightsAtom
  );
  const [proj4String, setProj4String] = useRecoilState(viewProj4StringAtom);
  const location = useLocation();
  const setLibraryLayersOpen = useSetRecoilState(libraryLayersOpenAtom);
  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));

  useEffect(() => {
    setLibraryLayersOpen(false);
  }, [setLibraryLayersOpen]);

  useEffect(() => {
    if (!park) {
      setViewPosition(undefined);
      return;
    }

    const origo = turf.center(park);
    const origoUTM = utm.fromLatLon(
      origo.geometry.coordinates[1],
      origo.geometry.coordinates[0]
    );
    setProj4String(
      `+proj=utm +zone=${origoUTM.zoneNum} +datum=WGS84 +units=m +no_defs +type=crs`
    );
    const id = uuidv4();
    setOrigoWGS84({ ...origo, id, properties: { ...origo.properties, id } });
    setViewPosition(
      ToEastLatLng(
        {
          lng: origo.geometry.coordinates[0],
          lat: origo.geometry.coordinates[1],
        },
        20
      )
    );
  }, [projectData, setProj4String, setOrigoWGS84, setViewPosition, park]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (!publicMode) return;
    if (query.get("viewposlng") && query.get("viewposlat")) {
      setViewPosition({
        lng: parseFloat(query.get("viewposlng")!),
        lat: parseFloat(query.get("viewposlat")!),
      });
    }
  }, [location, setViewPosition, publicMode]);

  const origo = useRecoilValue(viewOrigoSelector);

  const turbineFeatures = useRecoilValue(getTurbinesSelectorFamily({ parkId }));

  const setViewTurbineCoordsAtom = useSetRecoilState(viewTurbineCoordsAtom);
  const setViewTurbineHeightsAtom = useSetRecoilState(viewTurbineHeightsAtom);

  useEffect(() => {
    if (!origo || !proj4String) {
      setViewTurbineCoordsAtom(undefined);
      return;
    }

    const coordinates = turbineFeatures.map((f) => {
      return wgs84ToProjected(f.geometry.coordinates, proj4String);
    });

    if (coordinates.length === 0) {
      setViewTurbineCoordsAtom([]);
      return;
    }

    setViewTurbineCoordsAtom(
      coordinates.map((coords) => [
        (coords[0] - origo[0]) / DIVISION_FACTOR,
        (coords[1] - origo[1]) / DIVISION_FACTOR,
      ])
    );
    setViewTurbineHeightsAtom(
      turbineFeatures
        .map((f) => turbineHeights.get(f.properties.id))
        .filter(isDefined)
    );
  }, [
    origo,
    proj4String,
    setViewTurbineCoordsAtom,
    setViewTurbineHeightsAtom,
    turbineFeatures,
    turbineHeights,
  ]);

  if (!origo) return null;

  return (
    <MapController
      publicMode={publicMode}
      parkId={parkId}
      turbineFeatures={turbineFeatures}
      viewPosition={viewPosition}
      setViewPosition={setViewPosition}
      date={date}
      setDate={setDate}
      fov={fov}
      setFov={setFov}
      parkRotation={parkRotation}
      setParkRotation={setParkRotation}
      origoWGS84={origoWGS84}
      setOrigoWGS84={setOrigoWGS84}
      cameraAspect={cameraAspect}
      viewMode={viewMode}
      setViewMode={setViewMode}
      viewHeight={viewHeight}
      setViewHeight={setViewHeight}
      origo={origo}
      turbineLights={turbineLights}
      setTurbineLights={setTurbineLights}
      proj4String={proj4String}
    />
  );
};

const MapController = ({
  publicMode,
  turbineLights,
  setTurbineLights,
  parkId,
  turbineFeatures,
  viewPosition,
  setViewPosition,
  date,
  setDate,
  fov,
  setFov,
  parkRotation,
  setParkRotation,
  origoWGS84,
  setOrigoWGS84,
  cameraAspect,
  viewMode,
  setViewMode,
  viewHeight,
  setViewHeight,
  origo,
  proj4String,
}: {
  parkId: string;
  turbineFeatures: TurbineFeature[];
  setViewPosition: SetterOrUpdater<LngLat | undefined>;
  publicMode: boolean;
  [key: string]: any;
}) => {
  const checkly = useMemo(() => isInChecklyMode(), []);
  const [mapLoaded, setMapLoaded] = useState<mapboxgl.Map | null>(null);

  const park = useRecoilValue(getParkFeatureSelectorFamily({ parkId }));

  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (map || checkly || !mapContainer.current || !park) return;
    const bbox = getBBOXArray([park]);
    const bounds: LngLatBoundsLike = [
      [bbox[0] - BOUNDS_BUFFER, bbox[1] - BOUNDS_BUFFER],
      [bbox[2] + BOUNDS_BUFFER, bbox[3] + BOUNDS_BUFFER],
    ];
    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/vindai/cl2q0kcz2006h14qxs4mcxu5t",
      bounds,
    });
    setMap(newMap);
    newMap.on("load", () => {
      newMap.resize();
      setMapLoaded(newMap);
      newMap.loadImage(Eye, (error, image) => {
        if (error) throw error;
        if (image) newMap.addImage("eye", image);
      });
      newMap.getCanvas().style.cursor = "crosshair";
    });
  }, [map, mapContainer, setMapLoaded, checkly, park]);

  useEffect(() => {
    if (!mapLoaded) return;
    const onClick = (e: mapboxgl.MapMouseEvent) => {
      const { metaKey, altKey } = e.originalEvent;
      if (metaKey || altKey) {
        setOrigoWGS84({
          ...origoWGS84,
          geometry: {
            ...origoWGS84.geometry,
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
        });
      } else {
        setViewPosition(e.lngLat);
        if (publicMode) {
          navigate({
            pathname: location.pathname,
            search: `?viewposlng=${e.lngLat.lng}&viewposlat=${e.lngLat.lat}`,
          });
        }
      }
    };
    mapLoaded.on("click", onClick);
    return () => {
      mapLoaded.off("click", onClick);
    };
  }, [
    mapLoaded,
    setViewPosition,
    origoWGS84,
    setOrigoWGS84,
    location,
    navigate,
    publicMode,
  ]);

  useEffect(() => {
    if (!mapLoaded || !viewPosition) return;
    mapLoaded.addSource(ViewPositionMapBoxId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [viewPosition.lng, viewPosition.lat],
            },
            properties: {},
          },
        ],
      },
    });
    mapLoaded.addLayer({
      id: ViewPositionMapBoxId,
      type: "symbol",
      source: ViewPositionMapBoxId, // reference the data source
      layout: {
        "icon-image": "eye", // reference the image
        "icon-size": 1,
      },
    });
    return () => {
      mapLoaded.removeLayer(ViewPositionMapBoxId);
      mapLoaded.removeSource(ViewPositionMapBoxId);
    };
  }, [mapLoaded, viewPosition]);

  useEffect(() => {
    if (!mapLoaded || !origoWGS84) return;
    mapLoaded.addSource(ViewOrigoPositionMapBoxId, {
      type: "geojson",
      promoteId: "id",
      data: {
        type: "FeatureCollection",
        features: [origoWGS84],
      },
    });

    mapLoaded.addLayer({
      id: ViewOrigoPositionMapBoxId,
      type: "circle",
      source: ViewOrigoPositionMapBoxId,
      paint: {
        "circle-radius": 6,
        "circle-color": "#0000ff",
      },
    });
    return () => {
      mapLoaded.removeLayer(ViewOrigoPositionMapBoxId);
      mapLoaded.removeSource(ViewOrigoPositionMapBoxId);
    };
  }, [mapLoaded, origoWGS84]);

  const horizontalFOV = useMemo(
    () =>
      cameraAspect != null
        ? Math.round(
            (2 *
              Math.atan(Math.tan((fov * Math.PI) / 180 / 2) * cameraAspect) *
              180) /
              Math.PI
          )
        : undefined,
    [fov, cameraAspect]
  );

  const horizontalFOVFeatures = useMemo(() => {
    if (!origo || !horizontalFOV || !origoWGS84 || !proj4String) return;

    const halfFov = THREE.MathUtils.degToRad(horizontalFOV / 2);
    const viewPositionCoords = [viewPosition.lng, viewPosition.lat];
    const viewPositionPsuedoMercator = wgs84ToProjected(
      viewPositionCoords,
      proj4String
    );
    const vector = [
      origo[0] - viewPositionPsuedoMercator[0],
      origo[1] - viewPositionPsuedoMercator[1],
    ];

    const fov1 = [
      vector[0] * Math.cos(halfFov) - vector[1] * Math.sin(halfFov),
      vector[0] * Math.sin(halfFov) + vector[1] * Math.cos(halfFov),
    ];
    const fov2 = [
      vector[0] * Math.cos(-halfFov) - vector[1] * Math.sin(-halfFov),
      vector[0] * Math.sin(-halfFov) + vector[1] * Math.cos(-halfFov),
    ];

    return [fov1, fov2]
      .map((c) => [
        c[0] + viewPositionPsuedoMercator[0],
        c[1] + viewPositionPsuedoMercator[1],
      ])
      .map((c) => projectedToWGS84(c, proj4String))
      .map((f) => ({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [viewPositionCoords, f],
        },
      }));
  }, [origo, viewPosition, horizontalFOV, origoWGS84, proj4String]);

  useEffect(() => {
    if (!mapLoaded || !horizontalFOVFeatures) return;

    mapLoaded.addSource(FOVLineMapBoxId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: horizontalFOVFeatures as GeoJSON.Feature<LineString>[],
      },
    });
    mapLoaded.addLayer({
      id: FOVLineMapBoxId,
      type: "line",
      source: FOVLineMapBoxId, // reference the data source
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#888",
        "line-width": 1,
      },
    });
    return () => {
      mapLoaded.removeLayer(FOVLineMapBoxId);
      mapLoaded.removeSource(FOVLineMapBoxId);
    };
  }, [mapLoaded, horizontalFOVFeatures]);

  const distanceToViewPoint = useMemo(
    () =>
      (
        getDistanceFromLatLonInM(origoWGS84.geometry.coordinates, [
          viewPosition.lng,
          viewPosition.lat,
        ]) / 1000
      ).toFixed(1),
    [viewPosition, origoWGS84]
  );

  return (
    <MapControllerWrapper>
      <InputContainer>
        <ViewPositionWrapper>
          <InputLabel>View position</InputLabel>
          <Tooltip
            position="bottom"
            text="Left mouse click to change camera point | Left mouse click + alt/cmd to change view point"
          >
            <HelpWrapper>
              <HelpIcon />
            </HelpWrapper>
          </Tooltip>
        </ViewPositionWrapper>
        <MapWrapper ref={mapContainer} />
        {mapLoaded && park && (
          <SimplePark
            park={park}
            turbines={{ features: turbineFeatures }}
            map={mapLoaded}
          />
        )}
        <DistanceWrapper>
          Distance to view point: {distanceToViewPoint} km
        </DistanceWrapper>
      </InputContainer>
      {viewPosition && !publicMode && (
        <InputContainer>
          <InputLabel>Coordinates (lat/lng)</InputLabel>
          <Row>
            <Input
              style={{ width: "100%" }}
              value={viewPosition.lat.toFixed(4)}
              onChange={(e) => {
                setViewPosition((coords?: LngLat) =>
                  coords
                    ? {
                        ...coords,
                        lat: parseFloat(e.target.value),
                      }
                    : undefined
                );
              }}
            />
            <Input
              style={{ width: "100%" }}
              value={viewPosition.lng.toFixed(4)}
              onChange={(e) => {
                setViewPosition((coords) =>
                  coords
                    ? {
                        ...coords,
                        lng: parseFloat(e.target.value),
                      }
                    : undefined
                );
              }}
            />
          </Row>
        </InputContainer>
      )}
      <InputContainer>
        <InputLabel>Date</InputLabel>
        <Input
          type="date"
          value={`${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`}
          onChange={(e) => {
            const newDate = new Date(
              new Date(e.target.value).setHours(
                date.getHours(),
                date.getMinutes()
              )
            );

            if (isNaN(newDate.getDate())) return;

            setDate(newDate);
          }}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Time</InputLabel>
        <Row>
          <Input
            style={{ width: "48%" }}
            type="time"
            value={`${date.getHours().toString().padStart(2, "0")}:${date
              .getMinutes()
              .toString()
              .padStart(2, "0")}`}
            onChange={(e) => {
              const [hour, minute] = e.target.value
                .split(":")
                .map((t) => parseInt(t));
              setDate(new Date(date.setHours(hour, minute)));
            }}
          />
          <div style={{ flex: 1 }}>
            <RangeWithLabel
              min={0}
              max={1439}
              step={1}
              value={Math.round(date.getHours() * 60 + date.getMinutes())}
              onChange={(e) => {
                setDate(
                  new Date(
                    date.setHours(
                      Math.floor(parseFloat(e.target.value) / 60),
                      parseFloat(e.target.value) % 60
                    )
                  )
                );
              }}
              showLabel={false}
            />
          </div>
        </Row>
      </InputContainer>
      {cameraAspect && !publicMode && (
        <InputContainer>
          <InputLabel>Field of view</InputLabel>
          <RangeWithLabel
            min={20}
            max={100}
            step={1}
            value={fov}
            onChange={(e) => {
              setFov(parseInt(e.target.value));
            }}
            showLabel={true}
            labelSuffix={`° vert / ${horizontalFOV}° hor`}
          />
        </InputContainer>
      )}
      <InputContainer>
        <InputLabel>Park rotation</InputLabel>
        <RangeWithLabel
          min={0}
          max={360}
          step={1}
          value={parkRotation}
          onChange={(e) => {
            setParkRotation(parseInt(e.target.value));
          }}
          showLabel={true}
          labelSuffix={"°"}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>View height</InputLabel>
        <RangeWithLabel
          step={1}
          min={1}
          max={100}
          value={viewHeight}
          onChange={(e) => {
            setViewHeight(parseInt(e.target.value));
          }}
          showLabel={true}
          labelSuffix={"m"}
        />
      </InputContainer>
      {!publicMode && (
        <Checkbox
          checked={turbineLights}
          onChange={() => setTurbineLights(!turbineLights)}
          label={"Lights (BETA)"}
        />
      )}
      <InputContainer>
        <InputLabel>Map mode</InputLabel>
        <Row>
          <Button
            buttonType={
              viewMode !== VIEW_MODE.NATURAL_MODE ? "secondary" : "primary"
            }
            onClick={() => setViewMode(VIEW_MODE.NATURAL_MODE)}
            text="Natural"
          />
          <Button
            buttonType={
              viewMode !== VIEW_MODE.WIRE_FRAME_MODE ? "secondary" : "primary"
            }
            text="Wireframe"
            onClick={() => setViewMode(VIEW_MODE.WIRE_FRAME_MODE)}
          />
        </Row>
      </InputContainer>
    </MapControllerWrapper>
  );
};

export const ViewToParkThreeScene = () => {
  const viewFromShoreVisible = useRecoilValue(viewFromShoreVisibleAtom);
  const turbineCoords = useRecoilValue(viewTurbineCoordsAtom);
  const turbineHeights = useRecoilValue(viewTurbineHeightsAtom);
  const viewPosition = useRecoilValue(viewPositionAtom);
  const origo = useRecoilValue(viewOrigoSelector);
  const date = useRecoilValue(viewDateAtom);
  const fov = useRecoilValue(viewFovAtom);
  const parkRotation = useRecoilValue(viewParkRotationAtom);
  const setCameraAspect = useSetRecoilState(viewCameraAspectAtom);
  const viewMode = useRecoilValue(viewViewModeAtom);
  const viewHeight = useRecoilValue(viewHeightAtom);
  const turbineLights = useRecoilValue(viewTurbineLightsAtom);
  const proj4String = useRecoilValue(viewProj4StringAtom);
  const checkly = useMemo(() => isInChecklyMode(), []);

  const threeSceneRef = useRef<HTMLDivElement | null>(null);
  const [scene, setScene] = useState<Scene | null>(null);
  const [camera, setCamera] = useState<PerspectiveCamera | null>(null);
  const [sun, setSun] = useState();
  const [sunSphere, setSunSphere] = useState<Mesh | null>();
  const [sky, setSky] = useState<Sky | null>(null);
  const [water, setWater] = useState<undefined | Water>();
  const [renderTarget, setRenderTarget] = useState<WebGLRenderTarget | null>(
    null
  );
  const [pmremGenerator, setPmrGenerator] = useState<PMREMGenerator | null>(
    null
  );
  const [windTurbineGroup, setWindTurbineGroup] = useState<Group | null>(null);
  const [turbineBladeGroup, setTurbineBladeGroup] = useState<Group | null>(
    null
  );
  const [windTurbineBlinkingLightGroup, setWindTurbineBlinkingLightGroup] =
    useState<Group | null>(null);
  const [
    windTurbineLowIntensityLightGroup,
    setWindTurbineLowIntensityLightGroup,
  ] = useState<Group | null>(null);
  const [wireFrameTerrain, setWireFrameTerrain] = useState<Mesh | null>();

  function updateSun(
    parameters,
    sun,
    sky,
    water,
    renderTarget,
    pmremGenerator,
    scene
  ) {
    const phi = THREE.MathUtils.degToRad(90 - parameters.elevation);
    const theta = THREE.MathUtils.degToRad(180 - parameters.azimuth);

    sun.setFromSphericalCoords(1, phi, theta);

    sky.material.uniforms["sunPosition"].value.copy(sun);
    water.material.uniforms["sunDirection"].value.copy(sun).normalize();

    if (renderTarget !== undefined) renderTarget.dispose();

    renderTarget = pmremGenerator.fromScene(sky);

    scene.environment = renderTarget.texture;
  }

  const sunPosition = useMemo(() => {
    if (!viewPosition) return;
    return SunCalc.getPosition(date, viewPosition.lat, viewPosition.lng);
  }, [viewPosition, date]);

  useEffect(() => {
    if (
      !sun ||
      !sunPosition ||
      !sky ||
      !water ||
      !renderTarget ||
      !pmremGenerator ||
      !scene
    )
      return;
    updateSun(
      {
        elevation: THREE.MathUtils.radToDeg(sunPosition.altitude),
        azimuth: THREE.MathUtils.radToDeg(sunPosition.azimuth),
      },
      sun,
      sky,
      water,
      renderTarget,
      pmremGenerator,
      scene
    );
  }, [sunPosition, sun, sky, water, renderTarget, pmremGenerator, scene]);

  useEffect(() => {
    if (!sunPosition || !sunSphere) return;

    const { altitude, azimuth } = sunPosition;
    const phi = THREE.MathUtils.degToRad(THREE.MathUtils.radToDeg(altitude));
    const theta = THREE.MathUtils.degToRad(THREE.MathUtils.radToDeg(azimuth));

    const X = Math.round(
      DISTANCE_TO_WIRE_FRAME_SUN * (Math.cos(phi) * Math.sin(theta))
    );
    const Z = Math.round(
      DISTANCE_TO_WIRE_FRAME_SUN * (Math.cos(phi) * Math.cos(theta))
    );
    const Y = Math.round(DISTANCE_TO_WIRE_FRAME_SUN * Math.sin(phi));
    sunSphere.position.x = X;
    sunSphere.position.y = Y;
    sunSphere.position.z = -Z;
  }, [sunPosition, sunSphere]);

  const cameraPosition = useMemo(() => {
    if (!viewPosition || !proj4String) return;
    const viewPositionPseudoMercator = wgs84ToProjected(
      [viewPosition.lng, viewPosition.lat],
      proj4String
    );
    return [
      (viewPositionPseudoMercator[0] - origo[0]) / DIVISION_FACTOR,
      (viewPositionPseudoMercator[1] - origo[1]) / DIVISION_FACTOR,
    ];
  }, [viewPosition, origo, proj4String]);

  useEffect(() => {
    let camera, scene, renderer, water, sun;

    if (!threeSceneRef.current) return;
    const container = threeSceneRef.current;

    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    container.appendChild(renderer.domElement);

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xeeeeee);

    camera = new THREE.PerspectiveCamera(
      30,
      window.innerWidth / window.innerHeight,
      0.01,
      4000
    );

    setCameraAspect(camera.aspect);

    sun = new THREE.Vector3();

    const waterGeometry = new THREE.PlaneGeometry(10000, 10000);

    const material = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      side: THREE.DoubleSide,
    });
    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(10000, 10000, 1000, 1000),
      material
    );
    plane.rotation.x = Math.PI / 2;
    plane.material.wireframe = true;
    setWireFrameTerrain(plane);

    const sunSphere = new THREE.Mesh(
      new THREE.SphereGeometry(WIRE_FRAME_SUN_RADIUS, 14, 14),
      new THREE.MeshBasicMaterial({ color: 0xffff00 })
    );
    sunSphere.material.wireframe = true;

    water = new Water(waterGeometry, {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: new THREE.TextureLoader().load(
        WaterNormals,
        function (texture) {
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        }
      ),
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: scene.fog !== undefined,
      clipBias: 2,
    });

    water.rotation.x = -Math.PI / 2;

    const sky = new Sky();
    sky.scale.setScalar(10000);

    const skyUniforms = sky.material.uniforms;

    skyUniforms["turbidity"].value = 100;
    skyUniforms["rayleigh"].value = 1.5;
    skyUniforms["mieCoefficient"].value = 0.005;
    skyUniforms["mieDirectionalG"].value = 0.8;

    const parameters = {
      elevation: 2,
      azimuth: 180,
    };

    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    const renderTarget = pmremGenerator.fromScene(scene);

    updateSun(parameters, sun, sky, water, renderTarget, pmremGenerator, scene);

    const waterUniforms = water.material.uniforms;
    waterUniforms.distortionScale = { value: 3.7 };
    waterUniforms.size = { value: 20.0 };

    function onWindowResize() {
      camera.aspect = container.clientWidth / container.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(container.clientWidth, container.clientHeight);
      setCameraAspect(camera.aspect);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      onWindowResize();
    });
    resizeObserver.observe(container);
    window.addEventListener("resize", onWindowResize);

    function animate() {
      requestAnimationFrame(animate);
      render();
    }

    function render() {
      water.material.uniforms["time"].value += 1.0 / 240.0;

      const timestamp = Math.round(Date.now() / 1000);
      const seconds = timestamp - Math.floor(timestamp / 10) * 10;
      windTurbineLightsGlobal.visible = seconds % 2 === 0;

      const timestampDeci = Math.round(Date.now() / 60);
      turbineRotorsGlobal.children.forEach((c) =>
        c.children.forEach(
          (c2) =>
            (c2.rotation.x = THREE.MathUtils.degToRad(timestampDeci % 360))
        )
      );

      renderer.render(scene, camera);
    }

    animate();

    setScene(scene);
    setCamera(camera);
    setSun(sun);
    setSunSphere(sunSphere);
    setWater(water);
    setSky(sky);
    setRenderTarget(renderTarget);
    setPmrGenerator(pmremGenerator);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [
    threeSceneRef,
    setCameraAspect,
    setScene,
    setCamera,
    setSun,
    setSunSphere,
    setWater,
    setWireFrameTerrain,
    setSky,
    setRenderTarget,
    setPmrGenerator,
  ]);

  useEffect(() => {
    if (!scene) return;
    if (viewMode === VIEW_MODE.NATURAL_MODE) {
      scene.add(water!);
      scene.add(sky!);
      scene.remove(sunSphere!);
      scene.remove(wireFrameTerrain!);
    } else if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
      scene.add(wireFrameTerrain!);
      scene.add(sunSphere!);
      scene.remove(water!);
      scene.remove(sky!);
    }
  }, [viewMode, scene, water, sky, wireFrameTerrain, sunSphere]);

  useEffect(() => {
    if (!cameraPosition || !camera) return;
    camera.position.set(
      -cameraPosition[0],
      viewHeight / DIVISION_FACTOR,
      cameraPosition[1]
    );
    camera.lookAt(new THREE.Vector3(0, 0, 0));
  }, [camera, cameraPosition, viewHeight]);

  useEffect(() => {
    if (!camera) return;
    camera.fov = fov;
    camera.updateProjectionMatrix();
  }, [camera, fov]);

  const rotationOffset = useMemo(
    () =>
      turbineCoords ? turbineCoords.map((c) => Math.random() * 360) : undefined,
    [turbineCoords]
  );

  useEffect(() => {
    if (!turbineCoords || !rotationOffset || !turbineHeights) return;

    const distanceToHorizon = calcDistanceToHorizon(
      viewHeight / DIVISION_FACTOR
    );
    const curvatureOfEarthOffset = turbineCoords.map((c) => {
      const distCamTurbine = Math.sqrt(
        Math.pow(cameraPosition![0] - c[0], 2) +
          Math.pow(cameraPosition![1] - c[1], 2)
      );

      return distCamTurbine < distanceToHorizon
        ? 0
        : Math.sqrt(
            Math.pow(distanceToHorizon, 2) -
              2 * distanceToHorizon * distCamTurbine +
              Math.pow(distCamTurbine, 2) +
              Math.pow(EARTH_RADIUS_NORMALIZED, 2)
          ) - EARTH_RADIUS_NORMALIZED;
    });

    const rotateAndScaleGltf = (gltf, coords, height) => {
      const newTurbine = gltf.scene.clone(true);
      newTurbine.translateX(-coords[0]);
      newTurbine.translateZ(coords[1]);
      newTurbine.rotateX(THREE.MathUtils.degToRad(-90));
      newTurbine.scale.set(
        ((1 / HEIGHT_OF_TURBINE_MODEL) * height) / DIVISION_FACTOR,
        ((1 / HEIGHT_OF_TURBINE_MODEL) * height) / DIVISION_FACTOR,
        ((1 / HEIGHT_OF_TURBINE_MODEL) * height) / DIVISION_FACTOR
      );
      return newTurbine;
    };

    const loader = new GLTFLoader();
    loader.load(
      "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/tower.gltf",
      function (gltf) {
        if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
          gltf.scene.traverse((node: any) => {
            if (!node.isMesh) return;
            node.material.wireframe = true;
            node.material.color.setHex(0x000000);
          });
        }
        const group = new THREE.Group();
        const blinkingLightGroup = new THREE.Group();
        const lowIntensityLightGroup = new THREE.Group();
        turbineCoords.forEach((coords, i) => {
          const turbineHeight = turbineHeights[i];
          const newTurbine = rotateAndScaleGltf(gltf, coords, turbineHeight);
          newTurbine.translateZ(
            (-0.1 * turbineHeight) / DIVISION_FACTOR - curvatureOfEarthOffset[i]
          );
          newTurbine.name = MESH_TURBINE_NAME;

          if (turbineHeight >= 150) {
            const geometry = new THREE.SphereGeometry(
              (5 * turbineHeight) / 100 / DIVISION_FACTOR,
              16,
              16
            );
            const material = new THREE.MeshBasicMaterial({
              color: 0xff0000,
            });
            const sphere = new THREE.Mesh(geometry, material);

            sphere.translateX(-coords[0]);
            sphere.translateZ(coords[1]);
            sphere.translateY(
              turbineHeight / 2 / DIVISION_FACTOR - curvatureOfEarthOffset[i]
            );

            lowIntensityLightGroup.add(sphere);
          }

          const geometry = new THREE.SphereGeometry(
            (10 * turbineHeight) / 100 / DIVISION_FACTOR,
            16,
            16
          );
          const material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
          });
          const sphere = new THREE.Mesh(geometry, material);
          sphere.translateX(-coords[0]);
          sphere.translateZ(coords[1]);
          sphere.translateY(
            turbineHeight / DIVISION_FACTOR - curvatureOfEarthOffset[i]
          );
          blinkingLightGroup.add(sphere);

          group.add(newTurbine);
        });
        setWindTurbineGroup(group);
        setWindTurbineBlinkingLightGroup(blinkingLightGroup);
        setWindTurbineLowIntensityLightGroup(lowIntensityLightGroup);
      }
    );

    const loaderTurbine = new GLTFLoader();
    loaderTurbine.load(
      "https://vind-public-files-eu-west-1.s3.eu-west-1.amazonaws.com/rotor_rotated_center.glb",
      function (gltf) {
        if (viewMode === VIEW_MODE.WIRE_FRAME_MODE) {
          gltf.scene.traverse((node: any) => {
            if (!node.isMesh) return;
            node.material.wireframe = true;
            node.material.color.setHex(0x000000);
          });
        }
        const group = new THREE.Group();
        turbineCoords.forEach((coords, i) => {
          const turbineHeight = turbineHeights[i];
          const newRotor = rotateAndScaleGltf(gltf, coords, turbineHeight);

          newRotor.translateZ(
            (turbineHeight * ROTOR_OFFSET) / DIVISION_FACTOR -
              curvatureOfEarthOffset[i]
          );
          newRotor.rotateX(THREE.MathUtils.degToRad(90));
          newRotor.name = MESH_ROTOR_NAME;

          newRotor.children.forEach((c) =>
            c.children.forEach(
              (c2) =>
                (c2.rotation.x = THREE.MathUtils.degToRad(rotationOffset[i]))
            )
          );

          group.add(newRotor);
        });
        setTurbineBladeGroup(group);
      }
    );
  }, [
    turbineCoords,
    setTurbineBladeGroup,
    setWindTurbineGroup,
    setWindTurbineBlinkingLightGroup,
    turbineHeights,
    viewMode,
    rotationOffset,
    cameraPosition,
    viewHeight,
  ]);

  useEffect(() => {
    if (!scene || !windTurbineGroup || !turbineBladeGroup) return;
    turbineBladeGroup.children.forEach((c) => {
      c.rotation.y = THREE.MathUtils.degToRad(parkRotation);
    });
    turbineRotorsGlobal = turbineBladeGroup;
    scene.add(windTurbineGroup);
    scene.add(turbineBladeGroup);
    return () => {
      scene.remove(windTurbineGroup);
      scene.remove(turbineBladeGroup);
    };
  }, [parkRotation, windTurbineGroup, turbineBladeGroup, scene]);

  useEffect(() => {
    if (!scene || !windTurbineBlinkingLightGroup) return;
    windTurbineLightsGlobal = turbineLights
      ? windTurbineBlinkingLightGroup
      : new Group();
    if (!turbineLights) return;
    scene.add(windTurbineBlinkingLightGroup);
    scene.add(windTurbineLowIntensityLightGroup!);
    return () => {
      scene.remove(windTurbineBlinkingLightGroup);
      scene.remove(windTurbineLowIntensityLightGroup!);
    };
  }, [
    windTurbineBlinkingLightGroup,
    scene,
    turbineLights,
    windTurbineLowIntensityLightGroup,
  ]);

  return (
    <ThreeSceneWrapper visible={!!viewFromShoreVisible && !!turbineCoords}>
      {!checkly && <ThreeScene ref={threeSceneRef} />}
    </ThreeSceneWrapper>
  );
};
