import {
  getPublicBranchData,
  getProjectPublicSettings,
} from "./../services/projectDataAPIService";
import { atomFamily, selectorFamily } from "recoil";
import { ProjectFeature } from "../services/types";
import { isInChecklyMode } from "../utils/utils";
import { ProjectNodeInformation } from "../types/node";
import { getPublicProjectInfo } from "../services/customerAPIService";

// ------------------ Public --------------------------

export const getPublicBranchDataAtomFamily = atomFamily({
  key: "getPublicBranchDataAtomFamily",
  default: selectorFamily<
    ProjectFeature[],
    {
      customerId: string;
      projectId: string;
      branchId: string;
    }
  >({
    key: "getPublicBranchDataSelectorFamily",
    get:
      ({ customerId, projectId, branchId }) =>
      async ({ get }) => {
        const isCheckly = isInChecklyMode();
        if (!customerId || !projectId || !branchId) return [];
        const node = get(
          publicNodeInfoSelectorFamily({ customerId, projectId })
        );
        const features = await getPublicBranchData(
          node.id,
          branchId,
          isCheckly
        ).then((o) => o.features);
        return features ?? [];
      },
  }),
});

export type PublicProjectBranchMeta = {
  is_public: boolean;
  data_layers: any[];
  greeting_title: string;
  greeting_text: string;
};

export type PublicProjectMeta = {
  data_layers?: any[];
  greeting_title?: string;
  greeting_text?: string;
  public_collections_renamed_layers?: Record<string, string>;
};

export const publicProjectSettingsSelectorFamily = selectorFamily<
  PublicProjectMeta,
  {
    customerId: string;
    projectId: string;
  }
>({
  key: "publicMetadataSelectorFamily",
  get:
    ({ customerId, projectId }) =>
    async ({ get }) => {
      const node = get(publicNodeInfoSelectorFamily({ customerId, projectId }));
      return getProjectPublicSettings(node.id);
    },
});

export const publicNodeInfoSelectorFamily = selectorFamily<
  ProjectNodeInformation,
  {
    customerId: string;
    projectId: string;
  }
>({
  key: "publicMetadataSelectorFamily",
  get:
    ({ customerId, projectId }) =>
    async () =>
      getPublicProjectInfo(customerId, projectId),
});
