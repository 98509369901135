import { NotAuthorizedError } from "../components/ErrorBoundaries/ErrorBoundaryGlobal";
import * as Sentry from "@sentry/browser";
import { z } from "zod";

export const fetchEnhancer = async (
  url: string,
  options: any
): Promise<Response> => {
  const res = await fetch(url, options);
  if (res.status === 426) {
    window.location.reload();
  } else if (res.status === 403) {
    Sentry.addBreadcrumb({
      category: "403",
      message: "Returned 403 from server",
      level: "error",
      data: {
        url,
      },
    });
    throw new NotAuthorizedError("Not authorized");
  }
  return res;
};

export const fetchJson = async <T = any>(
  url: string,
  options: any
): Promise<T> => {
  return fetchEnhancer(url, options).then((r) => r.json() as T);
};

export const fetchSchema = async <T, In = unknown>(
  schema: z.ZodType<T, any, In>,
  url: string,
  options?: RequestInit,
  retries?: number
): Promise<T> => {
  const res = await fetchWithRetries(url, options ?? {}, retries);
  const json = await res.json();
  const value = schema.parse(json);
  return value;
};

export const fetchWithRetries = async (
  url: string,
  options: any,
  retries = 3
): Promise<Response> => {
  const res = await fetchEnhancer(url, options);
  if (res.ok) {
    return res;
  }
  if (retries > 0) {
    return fetchWithRetries(url, options, retries - 1);
  }
  throw new Error("Fetching failed after retries");
};
