import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { RecoilRoot } from "recoil";
import ErrorBoundaryGlobal from "./components/ErrorBoundaries/ErrorBoundaryGlobal";
import FullScreenLoader from "./components/FullScreenLoader/FullScreenLoader";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://6ab320e853f3499bbbb79c489ad6d2f7@o1417537.ingest.sentry.io/4504597973958656",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.0,
  environment:
    window.location.host === "app.public.vind.ai" ? "production" : "dev",
  enabled: window.location.host === "app.public.vind.ai",
  release: process.env.REACT_APP_SENTRY_RELEASE,
  ignoreErrors: [
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "TypeError: cancelled",
    "ResizeObserver loop limit exceeded",
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ErrorBoundaryGlobal>
        <React.Suspense fallback={<FullScreenLoader />}>
          <App />
        </React.Suspense>
      </ErrorBoundaryGlobal>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);
