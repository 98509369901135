import styled from "styled-components";
import { LAYER_WIDTH } from "../LayerList/LayerList.style";

export const LayerListWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 0px;
  top: 0px;
  height: 100vh;
`;

export const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  transition: right 0.1s linear;
`;

export const LayerInfoWrapper = styled(LayerWrapper)<{ open?: boolean }>`
  right: ${(p) => (p.open ? `${LAYER_WIDTH + 8}px` : "8px")};
  top: 8px;
`;

export const LayerControlWrapper = styled(LayerWrapper)<{ open?: boolean }>`
  right: ${(p) => (p.open ? `${LAYER_WIDTH + 8}px` : "8px")};
  bottom: 25px;
  z-index: 2;
`;

export const ProjectControlWrapper = styled(LayerWrapper)<{ open?: boolean }>`
  right: ${(p) => (p.open ? `${LAYER_WIDTH + 8}px` : "8px")};
  top: 8px;
  z-index: 3;
  pointer-events: none;
  & > * {
    & > * {
      pointer-events: auto;
    }
  }
`;

export const ControllerRowSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
`;
