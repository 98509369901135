export const colors = {
  bluePressed: "#022E4F",
  blueHover: "#022840",
  blueDark: "#023859",
  blueMedium: "#508196",
  blueMediumLight: "#9fbac5",
  black: "#15151C",
  brown: "#592302", // tetradic blueMedium
  grayDisabled: "#A0A6AA",
  gray: "#4D586A",
  grayLight: "#f5f5f5",
  redMedium: "#eb1902",
  warningRed: "#ff5050",
  yellow: "#fcba03",
};

export const colorsBlueGradient = {
  blue1: "#023859",
  blue2: "#2c5975",
  blue3: "#567a90",
  blue4: "#819bac",
  blue5: "#abbdc8",
  blue6: "#d5dee3",
};

export const opacity = {
  park: 0.1,
  inclusionZone: 0.4,
  cableCorridor: 0.4,
  externalLayer: 0.4,
  default: 0.4,
};
