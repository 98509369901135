import { TurbineFeature } from "../../state/layout";
import { ParkFeature } from "../../state/park";
import ParkPolygon from "../MapFeatures/ParkPolygon";
import TurbinePoint from "../MapFeatures/TurbinePoint";

const turbinesSourceId = "turbines-layout-source";
const turbinesCircleLayerId = "turbines-layout-circle-id";

const parkSourceId = "park-layout-source";
const parkPolygonLayerId = "park-layout-polygon-id";

const SimplePark = ({
  map,
  park,
  turbines = { features: [] },
}: {
  map: mapboxgl.Map;
  park: ParkFeature;
  turbines: { features: TurbineFeature[] };
}) => {
  return (
    <>
      <ParkPolygon
        map={map}
        features={[park]}
        layerId={parkPolygonLayerId}
        sourceId={parkSourceId}
      />
      <TurbinePoint
        map={map}
        features={turbines.features}
        layerId={turbinesCircleLayerId}
        sourceId={turbinesSourceId}
      />
    </>
  );
};

export default SimplePark;
