import styled from "styled-components";
import { colors, colorsBlueGradient } from "../../../styles/colors";
import { Ui12Bold, Ui12Regular, Ui14Bold } from "../../../styles/typography";
import { ReactComponent as Checkmark } from "../../../icons/checkmark/checkmark.svg";
import { DropDownItem } from "./DropdownButton";

const DropDownListContainer = styled.div<{
  position: "top" | "bottom";
  size: "small" | "large";
}>`
  position: absolute;
  ${(p) =>
    p.position === "bottom"
      ? p.size === "large"
        ? `top: 4.4rem;`
        : "top: 3.2rem;"
      : "bottom: 0;"}
  ${(p) =>
    p.position === "top" &&
    `transform: translateY(-${p.size === "small" ? 32 : 44}px);`}
  width: 100%;
  z-index: 100;
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: ${colors.grayLight};

  box-sizing: border-box;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  width: fit-content;
  min-width: 100%;
`;

const ListItem = styled.li<{ disabled: boolean; size: "small" | "large" }>`
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  padding: ${(p) =>
    p.size === "small"
      ? "0.6rem 6rem 0.6rem 0.8rem"
      : "1.2rem 6rem 1.2rem 1.4rem"};
  margin: 0;
  cursor: pointer;
  ${(p) => p.disabled && "cursor: not-allowed;"}

  :first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  :last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  > p {
    color: ${colors.blueDark};
    margin: 0;
    ${(p) => p.disabled && `color: ${colors.gray};`}
  }
  &:hover {
    background-color: ${colorsBlueGradient.blue6};
    ${(p) => p.disabled && `background-color: ${colors.grayLight};`}
  }
  > svg {
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
`;

export const DropDownItemHeader = styled(Ui14Bold)<{
  position: "top" | "bottom";
}>`
  white-space: ${(p) => (p.position === "top" ? "nowrap" : "inherit")};
  margin: 0;
`;
export const DropDownItemHeaderSmall = styled(Ui12Bold)<{
  position: "top" | "bottom";
}>`
  white-space: ${(p) => (p.position === "top" ? "nowrap" : "inherit")};
  margin: 0;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${colors.blueMediumLight};
`;

const Info = styled(Ui12Regular)`
  margin: 4px 0 0 0;
  white-space: nowrap;
  padding: 0;
`;

export function DropDownItems({
  items,
  onOptionClicked,
  selectedOption,
  isOpen,
  position = "bottom",
  size = "large",
  actionItems = [],
}: {
  items: DropDownItem[];
  onOptionClicked: (item: DropDownItem) => void;
  selectedOption?: string;
  isOpen: boolean;
  position?: "top" | "bottom";
  size?: "large" | "small";
  actionItems?: DropDownItem[];
}) {
  return (
    <DropDownListContainer
      position={position}
      size={size}
      style={!isOpen ? { display: "none" } : {}}
    >
      <DropDownList>
        {items.map((item) => (
          <ListItem
            onClick={() => {
              onOptionClicked(item);
            }}
            key={item.value}
            disabled={item.disabled ?? false}
            size={size}
          >
            {item.renderItem ? (
              item.renderItem()
            ) : (
              <>
                {size === "large" ? (
                  <DropDownItemHeader position={position}>
                    {item.name}
                  </DropDownItemHeader>
                ) : (
                  <DropDownItemHeaderSmall position={position}>
                    {item.name}
                  </DropDownItemHeaderSmall>
                )}
                <Info>{item.info}</Info>
                {item.value === selectedOption && <Checkmark />}
              </>
            )}
          </ListItem>
        ))}
        {actionItems.length > 0 && <Divider />}
        {actionItems.map((a) => (
          <ListItem
            onClick={() => {
              onOptionClicked(a);
            }}
            key={a.value}
            disabled={a.disabled ?? false}
            size={size}
          >
            {size === "large" ? (
              <DropDownItemHeader
                position={position}
                style={{ fontWeight: 500 }}
              >
                {a.name}
              </DropDownItemHeader>
            ) : (
              <DropDownItemHeaderSmall
                position={position}
                style={{ fontWeight: 500 }}
              >
                {a.name}
              </DropDownItemHeaderSmall>
            )}
            <Info>{a.info}</Info>
            {a.icon ?? null}
          </ListItem>
        ))}
      </DropDownList>
    </DropDownListContainer>
  );
}
