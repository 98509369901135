import { StandardBox } from "../../styles/boxes/Boxes";
import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Wrapper = styled.div`
  position: fixed;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const MenuWrapper = styled(StandardBox)``;

export const Divider = styled.div`
  border-bottom: 1px solid #9fbac5;
  margin: 4px 5px;
`;

export const IconWrapperGeneral = styled.button`
  cursor: pointer;
  position: relative;
  border: none;
  display: flex;
  padding: 0.6rem;
  svg {
    height: 2.4rem;
    min-height: 2.4rem;
    width: 2.4rem;
    min-width: 2.4rem;
  }
`;

export const IconWrapperStroke = styled(IconWrapperGeneral)<{
  somethingActive?: boolean;
  active?: boolean;
}>`
  border-radius: 4px;
  background-color: ${(p) => (p.active ? "#023859" : colors.grayLight)};
  path {
    stroke: ${(p) => (p.active ? colors.grayLight : "#023859")};
  }
  circle {
    stroke: ${(p) => (p.active ? colors.grayLight : "#023859")};
  }
  rect {
    stroke: ${(p) => (p.active ? colors.grayLight : "#023859")};
    fill: ${(p) => (p.active ? colors.grayLight : "#023859")};
  }
  &:hover {
    background-color: ${(p) => (p.active ? "#2c5975" : "#d5dee3")};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const IconWrapperFill = styled(IconWrapperGeneral)<{
  active?: boolean;
  disabled?: boolean;
}>`
  border-radius: 4px;
  background-color: ${(p) => (p.active ? "#023859" : colors.grayLight)};
  path {
    fill: ${(p) => (p.active ? colors.grayLight : "#023859")};
  }
  rect {
    fill: ${(p) => (p.active ? colors.grayLight : "#023859")};
  }
  circle {
    stroke: ${(p) => (p.active ? colors.grayLight : "#023859")};
  }
  &:hover {
    background-color: ${(p) => (p.active ? "#2c5975" : "#d5dee3")};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const MenuColumnWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0.4rem;
`;
