import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getPublicBranchesForProject } from "../../state/timeline";
import DropdownButton, {
  DropDownItem,
} from "../General/Dropdown/DropdownButton";
import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";
import { useTypedPath } from "../../state/pathParams";
import Spinner from "../../icons/spinner/Spinner";

const PositionWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
`;

const Wrapper = styled(StandardBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  gap: 0.5rem;
  height: 5rem;
`;

const PublicBranchSelectorInner = () => {
  const { customerId, projectId, branchId } = useTypedPath(
    "customerId",
    "projectId",
    "branchId"
  );
  const publicBranches = useRecoilValue(
    getPublicBranchesForProject({ customerId, projectId })
  );
  const selectedBranch = useMemo(
    () => publicBranches.find((b) => b.id === branchId),
    [publicBranches, branchId]
  );

  const navigate = useNavigate();
  const BranchMenuItems: DropDownItem[] = useMemo(() => {
    return publicBranches.map((m) => ({
      value: m.id,
      name: m.title,
    }));
  }, [publicBranches]);

  if (publicBranches.length < 2) return <></>;

  if (!selectedBranch) return null;

  return (
    <PositionWrapper>
      <Wrapper>
        <DropdownButton
          items={BranchMenuItems}
          selectedItemValue={
            BranchMenuItems.find((item) => item.value === branchId)?.value
          }
          onSelectItem={(id) => {
            navigate(`/${customerId}/${projectId}/${id}`);
          }}
          buttonText={selectedBranch.title}
          position={"top"}
        />
      </Wrapper>
    </PositionWrapper>
  );
};

const PublicBranchSelector = () => {
  return (
    <React.Suspense fallback={<Spinner />}>
      <PublicBranchSelectorInner />
    </React.Suspense>
  );
};

export default PublicBranchSelector;
