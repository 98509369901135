import { useCallback, useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";

export const onMobileState = atom<boolean>({
  key: "onMobile",
  default: window.innerWidth <= 768,
});

export function useOnMobile() {
  const setOnMobile = useSetRecoilState(onMobileState);

  const handleWindowSizeChange = useCallback(() => {
    setOnMobile(window.innerWidth <= 768);
  }, [setOnMobile]);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);
}
