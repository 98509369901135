import { z } from "zod";
import {
  Point,
  MultiPoint,
  LineString,
  MultiLineString,
  Polygon,
  MultiPolygon,
  GeometryCollection,
  Feature,
  FeatureCollection,
  BBox,
} from "geojson";
import { pointsAreEqual } from "../geometry";

const _FeatureLiteral = z.literal("Feature");
const _FeatureCollectionLiteral = z.literal("FeatureCollection");
const _PointLiteral = z.literal("Point");
const _MultiPointLiteral = z.literal("MultiPoint");
const _LineStringLiteral = z.literal("LineString");
const _MultiLineStringLiteral = z.literal("MultiLineString");
const _PolygonLiteral = z.literal("Polygon");
const _MultiPolygonLiteral = z.literal("MultiPolygon");
const _GeometryCollectionLiteral = z.literal("GeometryCollection");

export const _Type = z.union([_FeatureLiteral, _FeatureCollectionLiteral]);

export const _GeometryType = z.union([
  _PointLiteral,
  _MultiPointLiteral,
  _LineStringLiteral,
  _MultiLineStringLiteral,
  _PolygonLiteral,
  _MultiPolygonLiteral,
  _GeometryCollectionLiteral,
]);

export const _Bbox: z.ZodType<BBox> = z
  .tuple([z.number(), z.number(), z.number(), z.number()])
  .or(
    z.tuple([
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
      z.number(),
    ])
  );

export const _Position = z.number().array().min(2).max(3);

export const _Point: z.ZodType<Point> = z.object({
  type: _PointLiteral,
  coordinates: _Position,
  bbox: _Bbox.optional(),
});

export const _MultiPoint: z.ZodType<MultiPoint> = z.object({
  type: _MultiPointLiteral,
  coordinates: _Position.array(),
  bbox: _Bbox.optional(),
});

export const _LineString: z.ZodType<LineString> = z.object({
  type: _LineStringLiteral,
  coordinates: _Position.array().min(2),
  bbox: _Bbox.optional(),
});

export const _MultiLineString: z.ZodType<MultiLineString> = z.object({
  type: _MultiLineStringLiteral,
  coordinates: _Position.array().min(2).array(),
  bbox: _Bbox.optional(),
});

export const _ClosedLineString = _Position
  .array()
  .min(4)
  .refine((coords) => pointsAreEqual(coords.at(0)!, coords.at(-1)!));

export const _PolygonOutline = _ClosedLineString.array().min(1);

export const _Polygon: z.ZodType<Polygon> = z.object({
  type: _PolygonLiteral,
  coordinates: _PolygonOutline,
  bbox: _Bbox.optional(),
});

export const _MultiPolygon: z.ZodType<MultiPolygon> = z.object({
  type: _MultiPolygonLiteral,
  coordinates: _PolygonOutline.array(),
  bbox: _Bbox.optional(),
});

const _GeometryCollection_RecursionHack = z.object({
  type: _GeometryCollectionLiteral,
  bbox: _Bbox.optional(),
});

export const _GeometryCollection: z.ZodType<GeometryCollection> =
  _GeometryCollection_RecursionHack.extend({
    geometries: z.lazy(() =>
      z
        .union([
          _Point,
          _MultiPoint,
          _LineString,
          _MultiLineString,
          _Polygon,
          _MultiPolygon,
        ])
        .or(_GeometryCollection)
        .array()
    ),
  });

export const _GeometryNoCollection = z.union([
  _Point,
  _MultiPoint,
  _LineString,
  _MultiLineString,
  _Polygon,
  _MultiPolygon,
]);

export const _Geometry = z.union([
  _Point,
  _MultiPoint,
  _LineString,
  _MultiLineString,
  _Polygon,
  _MultiPolygon,
  _GeometryCollection,
]);

export const _Feature: z.ZodType<Feature> = z
  .object({
    type: _FeatureLiteral,
    id: z.string().or(z.number()).optional(),
    geometry: _Geometry,
    properties: z.object({}).passthrough().nullable(),
    bbox: _Bbox.optional(),
  })
  .passthrough();

export const _FeatureCollection: z.ZodType<FeatureCollection> = z
  .object({
    type: _FeatureCollectionLiteral,
    features: _Feature.array(),
    bbox: _Bbox.optional(),
  })
  .passthrough();
