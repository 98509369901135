import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { mapRefAtom } from "../state/map";
import { useCallback, useEffect, useMemo } from "react";
import {
  dynamicLayersSelector,
  selectedDynamicPolygonLayersSelectorFamily,
  visibleDynamicLayersAtom,
} from "../state/layer";
import { currentSelectionArrayAtom } from "../state/selection";
import { canvasPolygonLayerId, selectMethod } from "./canvasLayer";
import { addIdOnFeaturesIfNotUUID4 } from "../utils/geojson/utils";
import Polygon from "../components/MapFeatures/Polygon";
import { opacity as colorOpacity } from "../styles/colors";
import { ProjectFeature } from "../services/types";
import { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { useTypedPath } from "../state/pathParams";
import {
  StrokeStyleToDashArray,
  filterFeatureCollectionAccordingToType,
  getLayerSymbols,
  rgbToHex,
} from "./utils";
import { layerToSourceId } from "../utils/externalLayers";
import {
  LayerStrokeStyle,
  PublicVectorGisLayer,
  isHostedLayer,
} from "../types/gisData";
import {
  DrawingInfoClassBreakInfosRenderer,
  arcgisLayerDrawingInfoSelector,
} from "../state/arcgisRestAPI";
import stc from "string-to-color";

const POLYGON_TYPES = ["Polygon", "MultiPolygon"];
export const POLYGON_LAYERS_SUFFIX = "-polygon";

const getOutlinePaint = (
  color: string,
  strokeStyle: LayerStrokeStyle
): mapboxgl.LinePaint => ({
  "line-color": [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    "#ffffff",
    color,
  ],
  "line-width": [
    "case",
    [
      "boolean",
      ["feature-state", "selected"],
      ["feature-state", "hover"],

      false,
    ],
    strokeStyle === LayerStrokeStyle.Solid ? 4 : 2,
    1,
  ],
  "line-opacity": 1.0,
  "line-dasharray": StrokeStyleToDashArray[strokeStyle],
});

const getFillPaint = (
  color: mapboxgl.FillPaint["fill-color"],
  opacity: number
): mapboxgl.FillPaint => ({
  "fill-color": color,
  "fill-opacity": opacity,
});

const DynamicPolygonLayers = () => {
  const { projectId } = useTypedPath("projectId");
  const selectedDynamicPolygonLayers = useRecoilValue(
    selectedDynamicPolygonLayersSelectorFamily(projectId)
  );

  return (
    <>
      {selectedDynamicPolygonLayers.map((layer) => (
        <DynamicPolygonLayer key={layer.name} layer={layer} />
      ))}
    </>
  );
};

function generateInfoClassBreakExpression(
  classBreaks: DrawingInfoClassBreakInfosRenderer,
  defaultColor: string
): mapboxgl.FillPaint["fill-color"] {
  const expression: mapboxgl.FillPaint["fill-color"] = [
    "step",
    ["get", classBreaks.field],
  ];
  classBreaks.classBreakInfos.forEach((breakInfo) => {
    // @ts-ignore
    expression.push(rgbToHex(...breakInfo.symbol.color));
    expression.push(breakInfo.classMaxValue);
  });
  expression.push(defaultColor); // Default color if no class matches
  return expression;
}

const DynamicPolygonLayer = ({ layer }: { layer: PublicVectorGisLayer }) => {
  const rawData = useRecoilValue(dynamicLayersSelector({ layer }));
  const filteredGeojson = useMemo(
    () =>
      isHostedLayer(layer)
        ? filterFeatureCollectionAccordingToType(rawData, "Polygon")
        : rawData,
    [rawData, layer]
  );
  const map = useRecoilValue(mapRefAtom);
  const features = useMemo(() => {
    if (layer.type === "polygon")
      return addIdOnFeaturesIfNotUUID4(filteredGeojson.features);
    return addIdOnFeaturesIfNotUUID4(
      filteredGeojson.features.filter((f) =>
        POLYGON_TYPES.includes(f.geometry.type)
      )
    );
  }, [filteredGeojson, layer]) as ProjectFeature[];

  return (
    <DynamicPolygonLayerRender features={features} layer={layer} map={map} />
  );
};

const DynamicPolygonLayerRender = ({
  features,
  layer,
  map,
}: {
  features: ProjectFeature[];
  layer: PublicVectorGisLayer;
  map: Map | undefined;
}) => {
  const drawingInfo = useRecoilValue(arcgisLayerDrawingInfoSelector({ layer }));

  const layerId = useMemo(
    () => `${layer.name}-${POLYGON_LAYERS_SUFFIX}`,
    [layer.name]
  );
  const sourceId = useMemo(
    () => layerToSourceId(layer, POLYGON_LAYERS_SUFFIX),
    [layer]
  );

  const [currentSelectionArray, setCurrentSelectionArray] = useRecoilState(
    currentSelectionArrayAtom
  );

  const onClickCallback = useCallback(
    (features: MapboxGeoJSONFeature[], shiftClicked: boolean) => {
      selectMethod(shiftClicked, features, setCurrentSelectionArray);
    },
    [setCurrentSelectionArray]
  );

  const selectedIds = useMemo(
    () =>
      currentSelectionArray
        .filter((cs) => cs.source === sourceId && cs.layer.type === "fill")
        .map((s) => s.id),
    [currentSelectionArray, sourceId]
  );

  const layerSetting = layer.layerSetting;

  const color = useMemo(() => {
    if (!layerSetting?.overrideLayerStyle && drawingInfo) {
      if (drawingInfo.type === "simple" && drawingInfo.symbol.color) {
        // @ts-ignore
        return rgbToHex(...drawingInfo.symbol.color);
      } else if (drawingInfo.type === "classBreaks") {
        return generateInfoClassBreakExpression(drawingInfo, stc(layer.id));
      }
    }
    if (layerSetting?.layerStyle?.color) {
      return layerSetting.layerStyle.color;
    }
    return stc(layer.id);
  }, [drawingInfo, layer.id, layerSetting]);

  const strokeColor = useMemo(() => {
    if (
      !layerSetting?.overrideLayerStyle &&
      drawingInfo?.type === "simple" &&
      drawingInfo.symbol?.outline?.color
    ) {
      // @ts-ignore
      return rgbToHex(...drawingInfo.symbol.outline.color);
    }
    if (layerSetting?.layerStyle?.strokeColor) {
      return layerSetting.layerStyle.strokeColor;
    }
    return stc(layer.id);
  }, [layer, layerSetting, drawingInfo]);

  const strokeStyle = useMemo(() => {
    return layerSetting?.layerStyle?.strokeStyle ?? LayerStrokeStyle.Solid;
  }, [layerSetting?.layerStyle]);

  const opacity = useMemo(() => {
    return layerSetting?.layerStyle?.opacity ?? colorOpacity.externalLayer;
  }, [layerSetting?.layerStyle]);

  const pinnedProperty = useMemo(() => {
    return layerSetting?.layerStyle?.pinnedProperty;
  }, [layerSetting?.layerStyle]);

  const setVisibleDynamicLayers = useSetRecoilState(visibleDynamicLayersAtom);
  useEffect(() => {
    setVisibleDynamicLayers((vdl) => [...vdl, layerId]);
    return () =>
      setVisibleDynamicLayers((vdl) => vdl.filter((l) => l !== layerId));
  }, [setVisibleDynamicLayers, layerId, layer]);

  const pinnedSymbols = useMemo(
    () => getLayerSymbols(pinnedProperty),
    [pinnedProperty]
  );

  const linePaint = useMemo(
    () => getOutlinePaint(strokeColor, strokeStyle),
    [strokeColor, strokeStyle]
  );

  const fillPaint = useMemo(
    () => getFillPaint(color, opacity),
    [color, opacity]
  );

  if (!map || features.length === 0) return null;
  return (
    <Polygon
      features={features}
      sourceId={sourceId}
      layerId={layerId}
      symbols={layer["symbols"] ?? pinnedSymbols}
      map={map}
      onClickCallback={onClickCallback}
      selectedIds={selectedIds}
      beforeId={canvasPolygonLayerId}
      linePaint={linePaint}
      paint={fillPaint}
      zoomLevels={layerSetting?.layerStyle?.zoomLevels}
    />
  );
};

export default DynamicPolygonLayers;

export const forTesting = DynamicPolygonLayerRender;
