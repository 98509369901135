import { useState, useCallback, useEffect } from "react";

const THRESHOLD = 5;
function hasMoved(p1: [number, number], p2: [number, number]) {
  const a = p1[0] - p2[0];
  const b = p1[1] - p2[1];

  var distance = Math.sqrt(a * a + b * b);

  return distance > THRESHOLD;
}

export function useClickOutside<E extends HTMLElement>(
  htmlElement: null | E,
  onClickOutside: () => void
) {
  const [mouseDownPos, setMouseDownPos] = useState<[number, number] | null>(
    null
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        mouseDownPos &&
        hasMoved([event.clientX, event.clientY], mouseDownPos)
      ) {
        return;
      }
      // This cast seems to be okay; see
      // https://stackoverflow.com/questions/61164018/typescript-ev-target-and-node-contains-eventtarget-is-not-assignable-to-node
      const targetNode = event.target as Node;
      if (
        htmlElement &&
        !htmlElement.contains(targetNode) &&
        !event.composedPath().includes(htmlElement)
      ) {
        onClickOutside();
      }
    },
    [mouseDownPos, onClickOutside, htmlElement]
  );

  const onMouseDown = useCallback((event: MouseEvent) => {
    setMouseDownPos([event.clientX, event.clientY]);
  }, []);

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    document.addEventListener("mousedown", onMouseDown);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
      document.removeEventListener("mousedown", onMouseDown);
    };
  }, [handleClickOutside, onMouseDown, htmlElement]);
}
