import React from "react";
import { useRecoilValue } from "recoil";
import { InfoModalPublic } from "../InfoModal/InfoModal";
import { LowerRightPublic } from "../LowerRight/LowerRight";
import { libraryLayersOpenAtom } from "../../state/layer";
import {
  ControllerRowSelector,
  LayerControlWrapper,
  LayerListWrapper,
  ProjectControlWrapper,
} from "./RightSide.style";
import { LayerListPublic } from "../LayerList/LayerList";
import { onMobileState } from "../../state/onMobile";
import { LAYER_WIDTH } from "../LayerList/LayerList.style";

const RightSidePublic = () => {
  const open = useRecoilValue(libraryLayersOpenAtom);
  const onMobile = useRecoilValue(onMobileState);

  return (
    <>
      <ProjectControlWrapper open={open}>
        <ControllerRowSelector>
          <InfoModalPublic />
        </ControllerRowSelector>
      </ProjectControlWrapper>

      <LayerControlWrapper
        open={open}
        style={
          onMobile
            ? { bottom: "8px", right: open ? `${LAYER_WIDTH + 8}px` : "48px" }
            : {}
        }
      >
        <LowerRightPublic />
      </LayerControlWrapper>

      {!onMobile && (
        <LayerListWrapper>
          <LayerListPublic />
        </LayerListWrapper>
      )}
    </>
  );
};

export default RightSidePublic;
