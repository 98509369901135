import { useRecoilState, useRecoilValue } from "recoil";
import { addFeatureAtom, editFeatureAtom } from "../../state/map";
import styled from "styled-components";
import { toastMessagesAtom } from "../../state/toast";
import { useEffect, useRef } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  top: 13.75rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  font-size: 1.6rem;
  font-weight: 500;
`;

const ADD_FEATURE_ELEMENT_ID = "addFeatureToast";
const EDIT_FEATURE_ELEMENT_ID = "editFeatureToast";

const Toast = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef}>
      <ToastHandler toastRef={wrapperRef} />
    </Wrapper>
  );
};

const ToastHandler = ({
  toastRef,
}: {
  toastRef: React.RefObject<HTMLElement>;
}) => {
  const addFeature = useRecoilValue(addFeatureAtom);
  const editFeature = useRecoilValue(editFeatureAtom);
  const [toastMessages, setToastMessage] = useRecoilState(toastMessagesAtom);

  useEffect(() => {
    if (!toastRef || !toastRef.current) return;
    if (addFeature && !document.getElementById(ADD_FEATURE_ELEMENT_ID)) {
      const newElement = document.createElement("div");
      const newContent = document.createTextNode(
        'Finish by pressing "Enter" or cancel with "Escape"'
      );
      newElement.appendChild(newContent);
      newElement.className = "toast-message";
      newElement.id = ADD_FEATURE_ELEMENT_ID;
      toastRef.current.appendChild(newElement);
    } else if (
      (!addFeature || !addFeature.mode) &&
      document.getElementById(ADD_FEATURE_ELEMENT_ID)
    ) {
      document.getElementById(ADD_FEATURE_ELEMENT_ID)!.remove();
    }
  }, [toastRef, addFeature]);

  useEffect(() => {
    if (!toastRef || !toastRef.current) return;
    const elem = document.getElementById(EDIT_FEATURE_ELEMENT_ID);
    if (editFeature && !elem) {
      const newElement = document.createElement("div");
      const newContent = document.createTextNode('Finish by pressing "Enter"');
      newElement.appendChild(newContent);
      newElement.className = "toast-message";
      newElement.id = EDIT_FEATURE_ELEMENT_ID;
      toastRef.current.appendChild(newElement);
    } else if (!editFeature && elem) {
      elem.remove();
    }
  }, [toastRef, editFeature]);

  useEffect(() => {
    if (toastMessages.length === 0) return;
    toastMessages.forEach((tm) => {
      const newElement = document.createElement("div");
      const newContent = document.createTextNode(tm.text);
      newElement.appendChild(newContent);
      newElement.className = "toast-message";
      if (toastRef.current) toastRef.current.appendChild(newElement);
      if (tm.id) newElement.id = tm.id;
      setTimeout(() => {
        newElement.remove();
      }, tm.timeout);
    });
    setToastMessage([]);
  }, [toastMessages, toastRef, setToastMessage]);

  if (toastMessages.length !== 0)
    return <div>{toastMessages.map((tm) => tm.text)}</div>;

  return null;
};

export default Toast;
