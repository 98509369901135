import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { notificationMessageAtom } from "../../state/toast";
import { useEffect, useRef } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  font-size: 1.6rem;
`;

const NOTIFICATION_ELEMENT_ID = "notification-element";

const Notification = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef}>
      <NotificationHandler notificationRef={wrapperRef} />
    </Wrapper>
  );
};

const NotificationHandler = ({ notificationRef }) => {
  const notificationMessage = useRecoilValue(notificationMessageAtom);

  useEffect(() => {
    if (!notificationRef || !notificationRef.current) return;
    let elem = document.getElementById(NOTIFICATION_ELEMENT_ID);
    if (!notificationMessage) {
      if (elem) elem.remove();
      return;
    }
    if (!elem) {
      elem = document.createElement("div");
      elem.className = "toast-message";
      elem.id = NOTIFICATION_ELEMENT_ID;
      notificationRef.current.appendChild(elem);
    }
    const newContent = document.createTextNode(notificationMessage);
    elem.replaceChildren(newContent);
  }, [notificationRef, notificationMessage]);

  return null;
};

export default Notification;
