import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import usePrevious from "../../../hooks/usePrevious";
import { selectedChildIndexForParentAtom } from "../../../state/map";
import { useTypedPath } from "../../../state/pathParams";
import { getPublicBranchDataAtomFamily } from "../../../state/projectAPI";
import {
  currentSelectionArrayAtom,
  firstCurrentSelectionSelector,
} from "../../../state/selection";
import { turbinePolygonsSourceId } from "../../Layout";

export const KeepSelectionInSyncWithPathParameters = () => {
  const firstCurrentSelection = useRecoilValue(firstCurrentSelectionSelector);
  const previousSelection = usePrevious(firstCurrentSelection);
  const { parkId } = useParams();
  const navigate = useNavigate();
  const selectedChildIndexForParent = useRecoilValue(
    selectedChildIndexForParentAtom
  );

  const { projectId, customerId, branchId } = useTypedPath(
    "projectId",
    "customerId",
    "branchId"
  );
  const publicBranchFeatures = useRecoilValue(
    getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
  );
  const [hasRendered, setHasRendered] = useState(false);
  const setCurrentSelecationArray = useSetRecoilState(
    currentSelectionArrayAtom
  );

  useEffect(() => {
    if (hasRendered) return;
    if (!parkId) {
      setHasRendered(true);
      return;
    }
    const feature = publicBranchFeatures.find((f) => f.id === parkId);
    if (!feature) return;
    setCurrentSelecationArray([
      { ...feature, source: turbinePolygonsSourceId },
    ]);
    setHasRendered(true);
  }, [
    parkId,
    hasRendered,
    setHasRendered,
    setCurrentSelecationArray,
    publicBranchFeatures,
  ]);

  useEffect(() => {
    if (!hasRendered) return;
    if (firstCurrentSelection !== previousSelection) {
      if (
        firstCurrentSelection?.source !== turbinePolygonsSourceId &&
        parkId != null
      ) {
        navigate(`/${customerId}/${projectId}/${branchId}`, {
          replace: true,
        });
      }
      if (
        firstCurrentSelection?.source === turbinePolygonsSourceId &&
        firstCurrentSelection &&
        parkId !== firstCurrentSelection?.id
      ) {
        const navigateURL = `/${customerId}/${projectId}/${branchId}/${firstCurrentSelection.id}`;
        navigate(navigateURL, {
          replace: true,
        });
      }
    }
  }, [
    navigate,
    firstCurrentSelection,
    customerId,
    projectId,
    parkId,
    selectedChildIndexForParent,
    previousSelection,
    branchId,
    hasRendered,
  ]);

  return null;
};
