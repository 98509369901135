import React, { Suspense } from "react";
import { turbinePolygonsLayerId } from "../components/Layout";
import MapNative from "../components/MapNative/MapNative";
import Contours from "./contours";
import DynamicPolygonLayers from "./dynamicPolygonLayers";
import DynamicPointLayers from "./dynamicPointLayers";
import DynamicCircleLayers from "./dynamicCircleLayers";
import DynamicLineLayers from "./dynamicLineLayers";
import DynamicWMSLayers from "./dynamicWMSLayers";
import { CanvasLayerPublic } from "./canvasLayer";
import Noise from "./noise";
import Satellite from "./satellite";
import MouseHandling from "./MouseHandling";
import DynamicTileJSONLayers from "./dynamicTileJSONLayers";
import PublicLayout from "../components/PublicLayout";
import styled from "styled-components";
import FullScreenLoaderWithBlur from "../components/FullScreenLoader/FullScreenLoaderWithBlur";

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const PublicOffshoreMap = () => {
  return (
    <MapWrapper>
      <MapNative />
      <Contours />
      <Suspense fallback={<FullScreenLoaderWithBlur />}>
        <CanvasLayerPublic beforeId={turbinePolygonsLayerId} />
        <PublicLayout />
        <Satellite />
        <DynamicPolygonLayers />
        <DynamicCircleLayers />
        <DynamicPointLayers />
        <DynamicLineLayers />
        <DynamicWMSLayers />
        <DynamicTileJSONLayers />
        <MouseHandling />
        <Noise />
      </Suspense>
    </MapWrapper>
  );
};

export default PublicOffshoreMap;
