import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { isTurbine } from "../state/layout";
import { isPark } from "../state/park";
import { getPublicBranchDataAtomFamily } from "../state/projectAPI";
import { canvasLayerFeatureHiddenAtom } from "../state/projectLayers";
import { TurbinePolygons, Turbines } from "./Layout";
import { useTypedPath } from "../state/pathParams";

const PublicLayout = () => {
  return (
    <div>
      <TurbinePolygonsWrapper />
      <TurbinesWrapper />
    </div>
  );
};

const TurbinePolygonsWrapper = () => {
  const { projectId, customerId, branchId } = useTypedPath(
    "projectId",
    "customerId",
    "branchId"
  );
  const canvasLayerFeatureHidden = useRecoilValue(canvasLayerFeatureHiddenAtom);
  const features = useRecoilValue(
    getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
  );
  const filteredFeatures = useMemo(() => {
    return features
      .filter(isPark)
      .filter((f) => !canvasLayerFeatureHidden.includes(f.properties.id));
  }, [canvasLayerFeatureHidden, features]);

  return <TurbinePolygons parks={filteredFeatures} />;
};

const TurbinesWrapper = () => {
  const { projectId, customerId, branchId } = useTypedPath(
    "customerId",
    "projectId",
    "branchId"
  );

  const features = useRecoilValue(
    getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
  );
  const turbineFeatures = useMemo(() => {
    return features.filter(isTurbine);
  }, [features]);

  return <Turbines features={turbineFeatures} />;
};

export default PublicLayout;
