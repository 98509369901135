import { useMemo } from "react";
import { useParams } from "react-router";
import { atom, useRecoilValue } from "recoil";
import * as Sentry from "@sentry/browser";

/** Hook for getting an object with the IDs that we have stored in the path.  All requested fields
 * are checked if they are present.  Intended usage is to requetst fields you know are in the path,
 * for instance, `parkId` when you are in layout generation mode.  Example usage:
 * ```
 * const { projectId, parkId } = useTypedPath('projectId', 'parkId');
 * ```
 */
export const useTypedPath = <K extends keyof PathParams>(
  ...args: K[]
): { [k in K]: string } => {
  // Note: for components that are not in the route which sets the `useParams` we have used
  // this recoil state to store the IDs.
  const recoilParams = useRecoilValue(pathParamsAtom);
  const params = useParams();
  const ret: Partial<{ [k in K]: string }> = {};
  for (const field of args) {
    if (field in recoilParams) ret[field] = recoilParams[field];
    else if (field in params) ret[field] = params[field];
    else {
      const err = new Error("useTypedPath: something was undefined");
      console.error(err, { recoilParams, params, field });
      Sentry.captureException(err, {
        extra: { recoilParams, params, field },
      });
      // NOTE: we leave the field undefined here, so that we have the same behavior that we would have had without this hook.
    }
  }
  return ret as { [k in K]: string };
};

type PathParams = Partial<
  Record<"customerId" | "projectId" | "branchId" | "parkId", string>
>;
export const pathParamsAtom = atom<PathParams>({
  key: "pathParamsAtom",
  default: {},
});

export function useStructuredPath() {
  const { customerId, projectId, branchId, parkId } =
    useRecoilValue(pathParamsAtom);

  const path = useMemo(() => {
    let pathString = "";
    if (customerId) {
      pathString = pathString + `/${customerId}`;
    } else {
      return pathString;
    }
    if (projectId) {
      pathString = pathString + `/${projectId}`;
    } else {
      return pathString;
    }
    if (branchId) {
      pathString = pathString + `/${branchId}`;
    } else {
      return pathString;
    }
    if (parkId) {
      pathString = pathString + `/${parkId}`;
    } else {
      return pathString;
    }
    return pathString;
  }, [branchId, customerId, parkId, projectId]);

  return path;
}
