import {
  GeoJSONSource,
  Map,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
} from "mapbox-gl";
import { defaultMouseHandlerCallBackClickableFeature } from "../../state/selection";
import { mapInteractionSelector } from "../../state/map";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { addIdOnFeatureCollectionIfNotUUID4 } from "../../utils/geojson/utils";
import { Feature } from "geojson";

const MapFeature = ({
  features,
  sourceId,
  layerId,
  map,
  layers,
  beforeId,
  onClickCallback,
  onDbClickCallback,
  selectedIds,
}: {
  features: Feature[];
  sourceId: string;
  layerId: string;
  map: Map;
  layers: mapboxgl.AnyLayer[];
  beforeId?: string;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean
  ) => void;
  onDbClickCallback?: (features: any[]) => void;
  selectedIds?: (string | number)[];
}) => {
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const [hoveredId, setHoveredId] = useState<number | string>();
  const setMouseHandlerCallBack = useSetRecoilState(
    defaultMouseHandlerCallBackClickableFeature
  );

  useEffect(() => {
    if (!map) return;
    map.addSource(sourceId, {
      type: "geojson",
      promoteId: "id",
      data: { type: "FeatureCollection", features: [] },
    });

    layers.forEach((layer) =>
      map.addLayer(
        layer,
        beforeId && map.getLayer(beforeId) ? beforeId : undefined
      )
    );

    return () => {
      layers.forEach((layer) => map.removeLayer(layer.id));
      map.removeSource(sourceId);
    };
  }, [map, layerId, sourceId, beforeId, layers]);

  useEffect(() => {
    if (!map || !features) return;
    (map.getSource(sourceId) as GeoJSONSource).setData(
      addIdOnFeatureCollectionIfNotUUID4({
        type: "FeatureCollection",
        features: features,
      })
    );
  }, [map, features, sourceId]);

  useEffect(() => {
    if (!map || (!onClickCallback && !onDbClickCallback)) return;
    const mouseMove = (e: MapLayerMouseEvent) => {
      if (!mapInteraction.hover) return;
      const id = e.features?.[0]?.id;
      if (id) setHoveredId(id);
    };

    const mouseLeave = (e) => {
      if (!mapInteraction.hover) return;
      setHoveredId(undefined);
    };

    const onClick = (e: MapLayerMouseEvent) => {
      if (!onClickCallback) return;
      e.preventDefault();
      if (!mapInteraction.hover) return;
      if (e.features !== undefined)
        onClickCallback(e.features, e.originalEvent.shiftKey);
    };

    const onDblClick = (e: MapLayerMouseEvent) => {
      if (!onDbClickCallback) return;
      e.preventDefault();
      if (!mapInteraction.hover) return;
      if (e.features !== undefined) onDbClickCallback(e.features);
    };

    setMouseHandlerCallBack((l) => ({
      ...l,
      [layerId]: {
        onDblClick: onDblClick,
        onClick: onClick,
        onMouseMove: mouseMove,
        onMouseLeave: mouseLeave,
      },
    }));

    return () => {
      setMouseHandlerCallBack((l) => {
        const cleanedL = { ...l };
        delete cleanedL[layerId];
        return cleanedL;
      });
    };
  }, [
    setMouseHandlerCallBack,
    map,
    mapInteraction,
    setHoveredId,
    onClickCallback,
    onDbClickCallback,
    layerId,
  ]);

  useEffect(() => {
    if (!map || !map.getSource(sourceId) || hoveredId == null) return;
    map.setFeatureState({ source: sourceId, id: hoveredId }, { hover: true });
    return () => {
      if (!map.getSource(sourceId)) return;
      map.removeFeatureState({ source: sourceId, id: hoveredId }, "hover");
    };
  }, [hoveredId, map, sourceId]);

  useEffect(() => {
    if (!map || !selectedIds) return;

    selectedIds.forEach((id) => {
      map.setFeatureState({ source: sourceId, id: id }, { selected: true });
    });

    return () => {
      if (!map.getSource(sourceId)) return;
      selectedIds.forEach((id) => {
        map.removeFeatureState({ source: sourceId, id: id }, "selected");
      });
    };
  }, [selectedIds, map, sourceId]);

  return null;
};

export default MapFeature;
