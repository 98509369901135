import { useMemo } from "react";
import styled from "styled-components";
import { Range } from "../../styles/input/input";
import { Ui12Regular } from "../../styles/typography";
import { clamp } from "../../utils/utils";

const RangeSliderWrapper = styled.div<{ showLabel: boolean }>`
  position: relative;
  padding: ${(p) => (!p.showLabel ? "0" : "0 0 1.2rem 0")};
`;

const Label = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2;
  border-radius: 30px;
`;

const RangeWithLabel = ({
  min,
  max,
  value,
  onChange,
  step,
  showLabel = true,
  labelSuffix = "",
  indeterminate = false,
}: {
  min: number;
  max: number;
  value: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  step: number;
  showLabel?: boolean;
  labelSuffix?: string;
  indeterminate?: boolean;
}) => {
  const val = indeterminate ? (max + min) / 2 : value;

  const leftPos = useMemo(() => {
    const range = max - min;
    const f = (clamp(min, val, max) - min) / range;
    // Experimentally found nice numbers
    const offset = 3;
    const shrink = 0.94;
    return Math.floor(f * 100) * shrink + offset;
  }, [max, min, val]);

  const label = indeterminate ? "indeterminate" : `${val} ${labelSuffix}`;

  return (
    <RangeSliderWrapper showLabel={showLabel}>
      {showLabel && (
        <Label style={{ left: `${leftPos}%`, top: "15px" }}>
          <Ui12Regular
            style={{ margin: 0, position: "relative", left: "-50%" }}
          >
            {label}
          </Ui12Regular>
        </Label>
      )}
      <Range
        type="range"
        min={min.toString()}
        max={max.toString()}
        step={step.toString()}
        value={val}
        onChange={onChange}
        indeterminate={indeterminate}
      />
    </RangeSliderWrapper>
  );
};

export default RangeWithLabel;
