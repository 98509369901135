import { useRef, useEffect, useState, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import { useSetRecoilState } from "recoil";
import { mapRefAtom } from "../../state/map";
import styled from "styled-components";
import { isInChecklyMode } from "../../utils/utils";

export const mapboxAccessToken =
  "pk.eyJ1IjoidmluZGFpIiwiYSI6ImNsdW1pZThqODBvb2cya29mOXEyNTlsMTkifQ.UF8K0j_fwN67ZerA9n6F1A";

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const startBounds = [
  [-6.15234375, 52.05249047600099],
  [17.75390625, 66.08936427047088],
] as [[number, number], [number, number]];

export const maxBounds = [
  [-1000, -60],
  [1000, 75],
] as [[number, number], [number, number]];

mapboxgl.accessToken = mapboxAccessToken;

const MapNative = () => {
  const setMapRef = useSetRecoilState(mapRefAtom);
  const mapContainer = useRef<HTMLDivElement>(null);
  const [mapLoaded, setMapLoaded] = useState<mapboxgl.Map>();
  const [mapResized, setMapResized] = useState(false);
  const inChecklyMode = useMemo(() => isInChecklyMode(), []);

  useEffect(() => {
    if (!mapLoaded || !mapResized) return;
    setMapRef(mapLoaded);
  }, [setMapRef, mapLoaded, mapResized]);

  useEffect(() => {
    if (inChecklyMode || !mapContainer.current) return;
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/vindai/clnucl45400nm01plhzq2eja6",
      bounds: startBounds,
      maxBounds,
      logoPosition: "bottom-right",
      preserveDrawingBuffer: true,
    });
    map.on("load", () => {
      setMapLoaded(map);
    });
  }, [inChecklyMode]);

  useEffect(() => {
    if (mapLoaded) {
      const curentMapContainer = mapContainer.current;
      if (!curentMapContainer) return;
      const resizeObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          mapLoaded.resize();
          setMapResized(true);
        });
      });
      resizeObserver.observe(curentMapContainer);
      return () => resizeObserver.unobserve(curentMapContainer);
    }
  }, [mapLoaded]);

  return <MapWrapper ref={mapContainer} />;
};

export default MapNative;
