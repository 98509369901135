import mapboxgl, { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import MapFeature from "./Feature";
import { Feature } from "geojson";
import { getZoomLevels } from "../../layers/utils";

const SELECTED_OUTLINE_LAYER_ID_SUFFIX = "selected-outline-layer-id";
const DEFAULT_COLOR = "#77bb77";

const Polygon = ({
  features,
  sourceId,
  layerId,
  map,
  symbols,
  paint,
  linePaint,
  color,
  opacity,
  beforeId,
  onClickCallback,
  onDbClickCallback,
  selectedIds,
  zoomLevels,
}: {
  features: Feature[];
  sourceId: string;
  layerId: string;
  map: Map;
  symbols?: Omit<mapboxgl.SymbolLayer, "id" | "source">;
  paint?: mapboxgl.FillPaint;
  linePaint?: mapboxgl.LinePaint;
  color?: string;
  opacity?: number;
  beforeId?: string;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean
  ) => void;
  onDbClickCallback?: (features: MapboxGeoJSONFeature[]) => void;
  selectedIds?: (string | number)[];
  zoomLevels?: [number, number];
}) => {
  const layers = useMemo(() => {
    return [
      {
        id: layerId,
        type: "fill",
        source: sourceId,
        paint: {
          "fill-color": "rgba(0,0,0,0)",
          ...(onClickCallback
            ? {
                "fill-opacity": [
                  "case",
                  [
                    "boolean",
                    ["feature-state", "hover"],
                    ["feature-state", "selected"],
                    false,
                  ],
                  1.0,
                  0.4,
                ],
              }
            : {}),
          ...(paint ?? {}),
        },
        ...getZoomLevels(zoomLevels),
      },
      {
        id: layerId + SELECTED_OUTLINE_LAYER_ID_SUFFIX,
        type: "line",
        source: sourceId,
        paint: {
          "line-color": "#000",
          "line-width": 3,
          "line-opacity": [
            "case",
            ["boolean", ["feature-state", "selected"], false],
            1,
            0.0,
          ],
          ...(linePaint ?? {}),
        },
        ...getZoomLevels(zoomLevels),
      },
      ...(symbols
        ? [
            {
              ...symbols,
              id: `${layerId}-symbolid`,
              source: sourceId,
              ...getZoomLevels(zoomLevels),
            },
          ]
        : []),
    ];
  }, [
    layerId,
    sourceId,
    onClickCallback,
    symbols,
    paint,
    linePaint,
    zoomLevels,
  ]) as mapboxgl.AnyLayer[];

  useEffect(() => {
    if (!map || (paint && "fill-color" in paint)) return;
    map.setPaintProperty(layerId, "fill-color", color ?? DEFAULT_COLOR);
  }, [layerId, map, color, layers, paint]);

  useEffect(() => {
    if (!map) return;
    if (opacity !== undefined) {
      map.setPaintProperty(layerId, "fill-opacity", opacity);
    }
  }, [layerId, map, opacity, layers]);

  return (
    <MapFeature
      layers={layers}
      features={features}
      sourceId={sourceId}
      layerId={layerId}
      map={map}
      beforeId={beforeId}
      onClickCallback={onClickCallback}
      onDbClickCallback={onDbClickCallback}
      selectedIds={selectedIds}
    />
  );
};

export default Polygon;
