import { useCallback, useEffect, useMemo } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { mapRefAtom } from "../state/map";
import { currentSelectionArrayAtom } from "../state/selection";
import {
  dynamicLayersSelector,
  selectedDynamicLineLayersSelectorFamily,
  visibleDynamicLayersAtom,
} from "../state/layer";
import { addIdOnFeaturesIfNotUUID4 } from "../utils/geojson/utils";
import { canvasPolygonLayerId, selectMethod } from "./canvasLayer";
import LineString from "../components/MapFeatures/LineString";
import { ProjectFeature } from "../services/types";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { useTypedPath } from "../state/pathParams";
import { filterFeatureCollectionAccordingToType } from "./utils";
import { layerToSourceId } from "../utils/externalLayers";
import { PublicVectorGisLayer, isHostedLayer } from "../types/gisData";

const LINE_TYPES = ["MultiLineString", "LineString"];
export const LINE_LAYERS_SUFFIX = "-line";

const DynamicLineLayers = () => {
  const { projectId } = useTypedPath("projectId");
  const selectedDynamicLineLayers = useRecoilValue(
    selectedDynamicLineLayersSelectorFamily(projectId)
  );

  return (
    <>
      {selectedDynamicLineLayers.map((layer) => (
        <DynamicLineLayer key={layer.name} layer={layer} />
      ))}
    </>
  );
};

const DynamicLineLayer = ({ layer }: { layer: PublicVectorGisLayer }) => {
  const setVisibleDynamicLayers = useSetRecoilState(visibleDynamicLayersAtom);
  const rawData = useRecoilValue(dynamicLayersSelector({ layer }));
  const filteredGeojson = useMemo(
    () =>
      isHostedLayer(layer)
        ? filterFeatureCollectionAccordingToType(rawData, "LineString")
        : rawData,
    [rawData, layer]
  );
  const features = useMemo(() => {
    if (layer.type === "line")
      return addIdOnFeaturesIfNotUUID4(filteredGeojson.features);

    return addIdOnFeaturesIfNotUUID4(
      filteredGeojson.features.filter((f) =>
        LINE_TYPES.includes(f.geometry.type)
      )
    );
  }, [filteredGeojson, layer]) as ProjectFeature[];
  const map = useRecoilValue(mapRefAtom);
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);

  const layerId = useMemo(() => `${layer.name}-${LINE_LAYERS_SUFFIX}`, [layer]);
  const sourceId = useMemo(
    () => layerToSourceId(layer, LINE_LAYERS_SUFFIX),
    [layer]
  );

  const layerStyling = layer.layerSetting?.layerStyle;

  const color = useMemo(() => {
    if (layerStyling?.color) return layerStyling.color;
  }, [layerStyling]);

  const opacity = useMemo(() => {
    if (layerStyling?.opacity !== undefined) return layerStyling?.opacity;
    return 0.4;
  }, [layerStyling]);

  useEffect(() => {
    setVisibleDynamicLayers((vdl) => [...vdl, layerId]);
    return () =>
      setVisibleDynamicLayers((vdl) => vdl.filter((l) => l !== layerId));
  }, [setVisibleDynamicLayers, layerId]);

  const onClickCallBack = useCallback(
    (features: MapboxGeoJSONFeature[], shiftClicked: boolean) => {
      selectMethod(shiftClicked, features, setCurrentSelectionArray);
    },
    [setCurrentSelectionArray]
  );

  if (!map || features.length === 0) return null;

  return (
    <LineString
      color={color}
      opacity={opacity}
      features={features}
      map={map}
      sourceId={sourceId}
      layerId={layerId}
      beforeId={canvasPolygonLayerId}
      onClickCallback={onClickCallBack}
      zoomLevels={layerStyling?.zoomLevels}
    />
  );
};

export default DynamicLineLayers;
