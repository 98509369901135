import { ReactComponent as North } from "../../icons/24/Compass.svg";
import { ReactComponent as Layers } from "../../icons/24/Layers.svg";
import Satellite from "../../../src/assets/satellite.jpg";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { mapRefAtom } from "../../state/map";
import { useCallback } from "react";
import {
  backgroundLayerActiveAtom,
  libraryLayersOpenAtom,
} from "../../state/layer";
import { IconWrapperStroke } from "../ControlPanels/ControlPanel.layout";
import { ToggleWrapper } from "./styles";
import Tooltip from "../General/Tooltip";
import { onMobileState } from "../../state/onMobile";

const ParentWrapper = styled.div`
  display: inline-block;
`;

const LowerRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
  gap: 0.5rem;
`;

const Divider = styled.div`
  border-right: 1px solid #9fbac5;
  margin: 1rem 0;
  height: 2rem;
`;

export const LowerRightPublic = () => {
  const map = useRecoilValue(mapRefAtom);
  const onMobile = useRecoilValue(onMobileState);
  const resetToNorth = useCallback(() => {
    if (!map) return;
    map.easeTo({ pitch: 0, bearing: 0, duration: 500 });
  }, [map]);
  const [backgroundLayerActive, setBackgroundLayerActive] = useRecoilState(
    backgroundLayerActiveAtom
  );
  const [libraryLayersOpen, setLibraryLayersOpen] = useRecoilState(
    libraryLayersOpenAtom
  );

  return (
    <ParentWrapper style={{ visibility: onMobile ? "hidden" : "visible" }}>
      <LowerRightWrapper>
        <ToggleWrapper>
          <Tooltip position="top" text="Satellite">
            <IconWrapperStroke
              active={backgroundLayerActive === "satellite"}
              onClick={() => {
                setBackgroundLayerActive(
                  backgroundLayerActive === "satellite"
                    ? "bathymetry"
                    : "satellite"
                );
              }}
            >
              <img
                src={Satellite}
                style={{
                  height: "3rem",
                  borderRadius: "4px",
                  opacity: backgroundLayerActive === "satellite" ? 0.5 : 1,
                }}
                alt={"satelliteImage"}
              />
            </IconWrapperStroke>
          </Tooltip>
          <Divider />
          <Tooltip position="top" text="Reset orientation">
            <IconWrapperStroke onClick={resetToNorth}>
              <North height={"100%"} />
            </IconWrapperStroke>
          </Tooltip>
          <Divider />
          <Tooltip position="topLeft" text="External layers">
            <IconWrapperStroke
              onClick={() => setLibraryLayersOpen(!libraryLayersOpen)}
              active={libraryLayersOpen}
              id={"externalDataLayers"}
            >
              <Layers height={"100%"} />
            </IconWrapperStroke>
          </Tooltip>
        </ToggleWrapper>
      </LowerRightWrapper>
    </ParentWrapper>
  );
};
