import { addCorsAndCacheProxyURL } from "./gisSourceCorsProxy";
import {
  PublicGisLayerArcgis,
  PublicGisLayerCommon,
  isArcgisLayer,
} from "../types/gisData";
import { z } from "zod";
import { selectorFamily } from "recoil";
import { fetchEnhancer } from "../services/utils";

export const ARCGIS_REST_API_TYPE = "arcgis";

export const getArcgisPath = (layer: PublicGisLayerArcgis) => {
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;

  if (isPrivate)
    throw new Error("PRivate layer not yet supported in public mode");

  return `${addCorsAndCacheProxyURL(layer.endpoint.url)}/${
    layer.sourceLayerId
  }/query?where=1%3D1&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=5&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultRecordCount=100&returnExtentOnly=false&sqlFormat=none&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson`;
};

enum DrawingType {
  SIMPLE = "simple",
  CLASSBREAKS = "classBreaks",
  UNIQUEVALUE = "uniqueValue",
}
const _DrawingInfo = z.object({
  type: z.nativeEnum(DrawingType),
});

const _DrawingInfoClassBreakInfosRenderer = _DrawingInfo.extend({
  type: z.literal(DrawingType.CLASSBREAKS),
  classBreakInfos: z.array(
    z.object({
      classMaxValue: z.number(),
      label: z.string(),
      symbol: z.object({
        color: z.number().array(),
        outline: z.object({
          color: z.number().array(),
          width: z.number(),
          style: z.string().optional(),
          type: z.string().optional(),
        }),
        style: z.string(),
        type: z.string(),
      }),
    })
  ),
  classificationMethod: z.string(),
  field: z.string(),
  minValue: z.number().optional(),
});

const _DrawingInfoSimpleColor = _DrawingInfo.extend({
  type: z.literal(DrawingType.SIMPLE),
  symbol: z.object({
    color: z.number().array(),
    outline: z
      .object({
        color: z.number().array(),
        width: z.number(),
      })
      .nullish(),
  }),
});

const _DrawingInfoUniqueValue = _DrawingInfo.extend({
  type: z.literal(DrawingType.UNIQUEVALUE),
  field1: z.string(),
  uniqueValueInfos: z.array(
    z.object({
      value: z.string(),
      symbol: z.object({
        color: z.number().array(),
      }),
    })
  ),
});

export type DrawingInfoClassBreakInfosRenderer = z.infer<
  typeof _DrawingInfoClassBreakInfosRenderer
>;
export type DrawingInfoSimple = z.infer<typeof _DrawingInfoSimpleColor>;
export type DrawingInfoUniqueValue = z.infer<typeof _DrawingInfoUniqueValue>;

export type DrawingInfo =
  | DrawingInfoClassBreakInfosRenderer
  | DrawingInfoSimple
  | DrawingInfoUniqueValue;

export const arcgisLayerDrawingInfoSelector = selectorFamily<
  DrawingInfo | undefined,
  {
    layer: PublicGisLayerCommon;
  }
>({
  key: "arcgisLayerDrawingInfoSelector",
  get:
    ({ layer }) =>
    async ({ get }) => {
      if (!isArcgisLayer(layer)) {
        return undefined;
      }
      const url = addCorsAndCacheProxyURL(layer.endpoint.url).concat(
        !layer.endpoint.url.endsWith("/") ? "/" : "",
        layer.sourceLayerId.toString(),
        // "/legend",
        "?f=json"
      );

      let response: Response;
      try {
        response = await fetchEnhancer(url, {
          method: "get",
        });
      } catch (error) {
        console.error("Could not fetch arcgis layer drawing info", {
          error,
        });
        return;
      }

      const json = await response.json();
      const rendererJson = (json as any).drawingInfo?.renderer;
      const renderer = _DrawingInfo.safeParse(rendererJson);

      if (!renderer.success) {
        return;
      }

      if (renderer.data.type === DrawingType.SIMPLE) {
        const parsed = _DrawingInfoSimpleColor.safeParse(rendererJson);
        if (parsed.success) {
          return parsed.data;
        }
      } else if (renderer.data.type === DrawingType.CLASSBREAKS) {
        const parsed =
          _DrawingInfoClassBreakInfosRenderer.safeParse(rendererJson);
        if (parsed.success) {
          return parsed.data;
        }
      } else if (renderer.data.type === DrawingType.UNIQUEVALUE) {
        const parsed = _DrawingInfoUniqueValue.safeParse(rendererJson);
        if (parsed.success) {
          return parsed.data;
        }
      }
    },
});
