import { PublicGisLayers, _PublicGisLayers } from "../types/gisData";
import { fetchSchema } from "./utils";

export const getPublicProjectLayers = async (
  nodeId: string,
  branchId: string
): Promise<PublicGisLayers> =>
  fetchSchema(
    _PublicGisLayers,
    `/api/gis-data/public/v2/${nodeId}/${branchId}`,
    {
      method: "get",
    }
  );
