import { Polygon } from "geojson";
import { selector, selectorFamily } from "recoil";
import { ProjectFeature } from "../services/types";
import { pathParamsAtom } from "./pathParams";
import { getPublicBranchDataAtomFamily } from "./projectAPI";
import { canvasLayerFeatureHiddenAtom } from "./projectLayers";
import { TurbineNoiseSettings } from "./turbines";

export const PARK_PROPERTY_TYPE = "park-polygon";

export type ParkFeature = ProjectFeature<
  Polygon,
  {
    type: typeof PARK_PROPERTY_TYPE;
    noiseSettings?: TurbineNoiseSettings;
  }
>;
export const isPark = (f: ProjectFeature | undefined): f is ParkFeature =>
  f?.properties?.type === PARK_PROPERTY_TYPE;

export const getFilteredParkFeaturesSelector = selector<ParkFeature[]>({
  key: "getFilteredParkFeaturesSelector",
  get: ({ get }) => {
    const canvasLayerFeatureHidden = get(canvasLayerFeatureHiddenAtom);
    return get(getParkFeaturesSelector).filter(
      (p) => !canvasLayerFeatureHidden.includes(p.id)
    );
  },
});

export const getParkFeaturesSelector = selector<ParkFeature[]>({
  key: "getParkFeaturesSelector",
  get: ({ get }) => {
    const { projectId, customerId, branchId } = get(pathParamsAtom);
    if (!customerId || !projectId || !branchId) return [];
    const publicBranchFeatures = get(
      getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
    );
    return publicBranchFeatures.filter(isPark);
  },
});

export const getFirstParkFeaturesSelector = selector<ParkFeature | undefined>({
  key: "getFirstParkFeaturesSelector",
  get: ({ get }) => {
    const { projectId, customerId, branchId } = get(pathParamsAtom);
    if (!customerId || !projectId || !branchId) return undefined;
    const publicBranchFeatures = get(
      getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
    );
    return publicBranchFeatures.filter(isPark)?.[0];
  },
});

export const getParkFeatureSelectorFamily = selectorFamily<
  ParkFeature | undefined,
  { parkId: string }
>({
  key: "getParkFeatureSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      const parks = get(getParkFeaturesSelector);
      if (parks.length === 0) return undefined;
      const matchingParks = parks.filter((p) => p.id === parkId);
      if (1 < matchingParks.length) {
        console.log(
          "WARNING: mutliple park features with the same ID:",
          matchingParks
        );
      }
      return matchingParks[0];
    },
});
