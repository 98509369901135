import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { mapRefAtom } from "../state/map";
import { LineLayer, SymbolLayer } from "mapbox-gl";

const sourceId = "bathymetry-contours";

const contourLabels: SymbolLayer = {
  id: "bathymetry-layers",
  type: "symbol",
  source: sourceId,
  "source-layer": "bathymetry_contours-24fd4y",
  minzoom: 9,
  layout: {
    "text-field": "{depth}",
    "text-size": 8,
  },
  paint: {
    "text-color": "#277ec1",
    "text-opacity": 0.8,
  },
};
const contourLayer: LineLayer = {
  id: "bathymetry-contours",
  type: "line",
  source: sourceId,
  "source-layer": "bathymetry_contours-24fd4y",
  minzoom: 8,
  paint: {
    "line-color": "#277ec1",
    "line-width": 1,
    "line-opacity": 0.3,
  },
};

const Contours = () => {
  const map = useRecoilValue(mapRefAtom);

  useEffect(() => {
    if (!map) return;

    map.addSource(sourceId, {
      type: "vector",
      url: "mapbox://vindai.8cm0kuok",
      minzoom: 8,
    });
    map.addLayer(contourLabels);
    map.addLayer(contourLayer);

    return () => {
      map.removeLayer(contourLabels.id);
      map.removeLayer(contourLayer.id);
      map.removeSource(sourceId);
    };
  }, [map]);

  return null;
};

export default Contours;
