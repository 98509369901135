import { mapInteractionSelector } from "../../state/map";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Divider,
  IconWrapperStroke,
  MenuColumnWrapper,
  MenuWrapper,
  RelativeWrapper,
  Wrapper,
} from "./ControlPanel.layout";
import ProjectElements, {
  ProjectElementMenuType,
} from "../ProjectElements/ProjectElements";
import { leftMenuModeActiveAtom } from "../../state/filter";
import { useEffect, useMemo } from "react";
import Tooltip from "../General/Tooltip";
import { ReactComponent as Folder } from "../../icons/24/Folders.svg";
import { ReactComponent as Measure } from "../../icons/24/Expand.svg";
import { ReactComponent as Area } from "../../icons/24/Focus.svg";
import { MeasureAreaMode } from "../Area/Area";
import { MeasureDistanceMode } from "../Distance/Distance";
import { publicProjectSettingsSelectorFamily } from "../../state/projectAPI";
import { useTypedPath } from "../../state/pathParams";
import { projectElementsVisibleKey } from "../../constants/public";

const MenuColumn = () => {
  const { projectId, customerId } = useTypedPath("projectId", "customerId");
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const [leftMenuModeActive, setLeftMenuModeActive] = useRecoilState(
    leftMenuModeActiveAtom
  );

  useEffect(
    () => () => setLeftMenuModeActive(undefined),
    [setLeftMenuModeActive]
  );

  const publicProjectSettings = useRecoilValue(
    publicProjectSettingsSelectorFamily({ projectId, customerId })
  );

  const showProjectElements = useMemo(
    () =>
      projectElementsVisibleKey in publicProjectSettings
        ? publicProjectSettings[projectElementsVisibleKey]
        : true,
    [publicProjectSettings]
  );

  return (
    <MenuColumnWrapper>
      {showProjectElements && (
        <>
          <Tooltip position="right" text="Project elements">
            <IconWrapperStroke
              active={leftMenuModeActive === ProjectElementMenuType}
              onClick={(e) =>
                setLeftMenuModeActive((f) =>
                  f === ProjectElementMenuType
                    ? undefined
                    : ProjectElementMenuType
                )
              }
              id={"projectElements"}
              disabled={!mapInteraction.leftSideMenuActive}
            >
              <Folder width={"100%"} />
            </IconWrapperStroke>
          </Tooltip>
          <Divider />
        </>
      )}
      <Tooltip position="right" text="Measure area">
        <IconWrapperStroke
          active={leftMenuModeActive === MeasureAreaMode}
          onClick={() => {
            setLeftMenuModeActive((mode) =>
              mode === MeasureAreaMode ? undefined : MeasureAreaMode
            );
          }}
          disabled={!mapInteraction.leftSideMenuActive}
        >
          <Area width={"100%"} />
        </IconWrapperStroke>
      </Tooltip>
      <Divider />
      <Tooltip position="right" text="Measure distance">
        <IconWrapperStroke
          active={leftMenuModeActive === MeasureDistanceMode}
          onClick={() => {
            setLeftMenuModeActive((mode) =>
              mode === MeasureDistanceMode ? undefined : MeasureDistanceMode
            );
          }}
          disabled={!mapInteraction.leftSideMenuActive}
        >
          <Measure width={"100%"} />
        </IconWrapperStroke>
      </Tooltip>
    </MenuColumnWrapper>
  );
};

export const ControlPanelPublicMapMode = () => {
  return (
    <Wrapper>
      <MenuWrapper>
        <RelativeWrapper>
          <ProjectElements disablePark={false} />
          <MenuColumn />
        </RelativeWrapper>
      </MenuWrapper>
    </Wrapper>
  );
};
