import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as VindLogo } from "../../icons/vindlogo/vindLogo.svg";
import { StandardBox } from "../../styles/boxes/Boxes";
import { colors } from "../../styles/colors";

const LogoWrapper = styled(StandardBox)`
  display: flex;
  align-items: center;
  padding: 1rem 1.7rem;
  position: relative;
  cursor: pointer;
  height: 3rem;
  border-radius: 0.6rem;
  background-color: ${colors.grayLight};
  max-width: fit-content;
  svg {
    height: 2rem;
    width: 11rem;
  }
  &:hover {
    path {
      fill: #508196;
    }
  }
`;

const Logo = () => {
  const { customerId } = useParams();
  return (
    <Link
      to={`/projects${customerId ? `/${customerId}` : ""}`}
      style={{ textDecoration: "none" }}
    >
      <LogoWrapper>
        <VindLogo />
      </LogoWrapper>
    </Link>
  );
};

export const LogoPublic = () => {
  return (
    <a href={`https://vind.ai`} style={{ textDecoration: "none" }}>
      <LogoWrapper>
        <VindLogo />
      </LogoWrapper>
    </a>
  );
};

export default Logo;
