import React, { useEffect, useRef, useMemo } from "react";
import { selectorFamily, useRecoilValue, waitForNone, Loadable } from "recoil";
import styled from "styled-components";
import Spinner from "../../icons/spinner/Spinner";
import { fetchEnhancer } from "../../services/utils";
import { StyledModalBase } from "../InfoModal/InfoModal.style";
import ToggleableRow from "../ToggleableRow/ToggleableRow";
import { WMSSelection } from "../../state/selection";

const StyledModal = styled(StyledModalBase)`
  overflow-y: auto;
  min-width: 350px;
  flex-direction: column;
`;

export const getWmsLayerInfoSelectorFamily = selectorFamily<
  WMSSelection,
  Omit<WMSSelection, "html">
>({
  key: "getWmsLayerInfoSelectorFamily",
  get: (wmsInfo) => async () => {
    const res = await fetchEnhancer(wmsInfo.url, {
      method: "get",
    });
    const html = await res.text();
    return { ...wmsInfo, html };
  },
});

export const getWmsLayerInfosSelectorFamily = selectorFamily<
  Loadable<WMSSelection>[],
  Omit<WMSSelection, "html">[]
>({
  key: "getWmsLayerInfosSelectorFamily",
  get:
    (wmsInfos) =>
    async ({ get }) =>
      get(
        waitForNone(
          wmsInfos.map((wmsInfo) => getWmsLayerInfoSelectorFamily(wmsInfo))
        )
      ),
});

const WMSInfos = ({ selections }: { selections: WMSSelection[] }) => {
  const wmsLayerInfos = useRecoilValue(
    getWmsLayerInfosSelectorFamily(selections)
  );

  const isLoading = useMemo(
    () => !!wmsLayerInfos.find((l) => l.state === "loading"),
    [wmsLayerInfos]
  );
  const finishedData = useMemo(
    () =>
      wmsLayerInfos
        .filter((l) => l.state === "hasValue")
        .map((l) => l.contents as WMSSelection)
        .filter((l) => {
          var el = document.createElement("html");
          el.innerHTML = l.html ?? "";
          const body = el.getElementsByTagName("body")[0];
          return body.childElementCount !== 0;
        }),
    [wmsLayerInfos]
  );

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && finishedData.length === 0 && (
        <div>No data found in this area...</div>
      )}
      {finishedData.map((s) => (
        <WMSInfo
          key={s.type}
          layerName={s.type}
          html={s.html}
          legendGraphicUrl={s.legendGraphicUrl}
        />
      ))}
    </>
  );
};

const WMSInfo = ({ layerName, html, legendGraphicUrl }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!iframeRef?.current) return;
    iframeRef.current.src =
      "data:text/html;charset=utf-8," + encodeURIComponent(html);
  }, [iframeRef, html]);

  return (
    <>
      <h4>{layerName}</h4>
      <iframe ref={iframeRef} title="WMS layer info" />
      <ToggleableRow title={"Legend graphic"}>
        <img src={legendGraphicUrl} alt={"Legend graphic"} />
      </ToggleableRow>
    </>
  );
};

const WMSInfoModal = ({ selections }: { selections: WMSSelection[] }) => {
  return (
    <React.Suspense fallback={null}>
      <StyledModal>
        <h3>Info</h3>
        <WMSInfos selections={selections} />
      </StyledModal>
    </React.Suspense>
  );
};

export default WMSInfoModal;
