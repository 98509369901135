import { selectorFamily, waitForNone } from "recoil";
import { capitalize } from "../utils/utils";
import { TileJSONLayersInfoType } from "../types/gisData";
import { fetchEnhancer } from "../services/utils";
import { getExternalLayerId } from "../utils/externalLayers";

export const TILE_JSON_TYPE = "tileJSON";

export type TileJSONType = {
  url: string;
  source: string;
  layer_id_to_type: Record<string, string>;
};

export type TileJSONMetadataLayers = {
  source: string;
  layersInfo: TileJSONLayersInfoType[];
  fetchSucceeded: boolean;
};

export const tileJSONServerFullMetadataSelector = selectorFamily<
  {
    bounds: [number, number, number, number];
    vector_layers: { id: string; description: string }[];
    tiles?: string[];
  },
  string
>({
  key: "tileJSONServerFullMetadataSelector",
  get: (url) => async () => {
    try {
      const response = await fetchEnhancer(`${url}?f=json`, {
        method: "get",
      });
      return response.json();
    } catch (err) {
      console.warn(`Could not read from WFS server: ${url}, ${err}`);
    }
  },
});

export const tileJSONFullMetadataSelector = selectorFamily<
  TileJSONMetadataLayers | undefined,
  TileJSONType
>({
  key: "tileJSONFullMetadataSelector",
  get:
    (layer) =>
    async ({ get }) => {
      const metadataJSON = get(tileJSONServerFullMetadataSelector(layer.url));

      if (!metadataJSON) return;

      const layersInfo = metadataJSON.vector_layers
        .filter((l) => Object.keys(layer.layer_id_to_type).includes(l.id))
        .map((l) => ({
          type: layer.layer_id_to_type[l.id],
          internalId: l.id,
          name: l.description,
          bbox: metadataJSON.bounds,
          layer: l,
          id: getExternalLayerId(layer.url, l.id, TILE_JSON_TYPE),
          sourceType: TILE_JSON_TYPE,
          source: layer.source,
          url: layer.url,
        }));

      return {
        source: capitalize(layer.source),
        layersInfo,
        fetchSucceeded: true,
      };
    },
});

export const tileJSONLayersFullMetadataSelectorFamilyAsync = selectorFamily({
  key: "tileJSONLayersFullMetadataSelectorFamilyAsync",
  get:
    (tileJSONLayers: TileJSONType[]) =>
    async ({ get }) => {
      const wmsMetadataLayers = get(
        waitForNone(
          tileJSONLayers.map((tileJSONlayer) =>
            tileJSONFullMetadataSelector(tileJSONlayer)
          )
        )
      );

      return wmsMetadataLayers;
    },
});
