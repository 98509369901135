import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
  ProjectFeature,
} from "./../services/types";
import { atom, selector } from "recoil";
import { pathParamsAtom } from "./pathParams";
import {
  CABLE_PROPERTY_TYPE,
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
} from "../constants/wiring";
import { TURBINE_PROPERTY_TYPE } from "./layout";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  DIVISION_INCLUSION_ZONE_PROPERTY_TYPE,
} from "../constants/division";

import { PARK_PROPERTY_TYPE } from "./park";
import { isCable, isExportCable } from "./cable";
import { getPublicBranchDataAtomFamily } from "./projectAPI";

export const findFeatureChildren = (
  projectData: ProjectFeature[],
  featureId: string
): string[] => {
  const featureIds = projectData
    .filter((p) => p.properties?.parentIds?.includes(featureId))
    .map((p) => p.id);

  return [
    ...featureIds,
    ...featureIds.flatMap((id) => findFeatureChildren(projectData, id)),
  ];
};

export const canvasLayerFeatureHiddenAtom = atom<string[]>({
  key: "canvasLayerFeatureHiddenAtom",
  default: [],
});

export const canvasLayerFeaturesSelector = selector({
  key: "canvasLayerFeaturesSelector",
  get: async ({ get }) => {
    const { projectId, customerId, branchId } = get(pathParamsAtom);
    if (!customerId || !projectId || !branchId) return [];

    const publicFeatures = get(
      getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
    );

    return publicFeatures.filter(
      (c) =>
        ![
          PARK_PROPERTY_TYPE,
          TURBINE_PROPERTY_TYPE,
          SUBSTATION_PROPERTY_TYPE,
          CABLE_PROPERTY_TYPE,
          EXPORT_CABLE_PROPERTY_TYPE,
          DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
          DIVISION_INCLUSION_ZONE_PROPERTY_TYPE,
          BathymetryUserUploadedType,
          GeoTiffUserUploadedImageType,
        ].includes(c.properties.type ?? "")
    );
  },
  dangerouslyAllowMutability: true,
});

export const bathymetryLayerFeaturesSelector = selector({
  key: "bathymetryLayerFeaturesSelector",
  get: async ({ get }) => {
    const { projectId, customerId, branchId } = get(pathParamsAtom);
    if (!customerId || !projectId || !branchId) return [];

    const publicFeatures = get(
      getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
    );
    return publicFeatures.filter(
      (c) => c.properties.type === BathymetryUserUploadedType
    );
  },
  dangerouslyAllowMutability: true,
});

export const georefImagesLayerFeaturesSelector = selector({
  key: "georefImagesLayerFeaturesSelector",
  get: async ({ get }) =>
    get(publicFeaturesForCurrentSelection).filter(
      (c) => c.properties.type === GeoTiffUserUploadedImageType
    ),
  dangerouslyAllowMutability: true,
});

export const publicFeaturesForCurrentSelection = selector<ProjectFeature[]>({
  key: "publicFeaturesForCurrentSelection",
  get: ({ get }) => {
    const { projectId, customerId, branchId } = get(pathParamsAtom);
    if (!customerId || !projectId || !branchId) return [];

    const publicFeatures = get(
      getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
    );
    return publicFeatures;
  },
});

export const projectFeatureMap = selector<Map<string, ProjectFeature>>({
  key: "projectFeatureMap",
  get: ({ get }) => {
    const features = get(publicFeaturesForCurrentSelection);
    return new Map(features.map((f) => [f.id, f]));
  },
});

export const canvasLayerPolygonFeaturesSelector = selector({
  key: "canvasLayerPolygonFeaturesSelector",
  get: async ({ get }) => {
    const canvasLayerFeatureHidden = get(canvasLayerFeatureHiddenAtom);
    return get(publicFeaturesForCurrentSelection).filter(
      (c) =>
        ["Polygon", "MultiPolygon"].includes(c?.geometry?.type) &&
        !canvasLayerFeatureHidden.includes(c.id) &&
        ![
          PARK_PROPERTY_TYPE,
          DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
          DIVISION_INCLUSION_ZONE_PROPERTY_TYPE,
        ].includes(c.properties.type ?? "")
    );
  },
});

export const canvasLayerImageFeaturesSelector = selector({
  key: "canvasLayerImageFeaturesSelector",
  get: async ({ get }) => {
    const canvasLayerFeatureHidden = get(canvasLayerFeatureHiddenAtom);
    return get(publicFeaturesForCurrentSelection).filter(
      (c) =>
        c?.geometry?.type === "Polygon" &&
        !canvasLayerFeatureHidden.includes(c.id) &&
        [GeoTiffUserUploadedImageType].includes(c.properties.type ?? "")
    );
  },
});

export const canvasLayerLineFeaturesSelector = selector({
  key: "canvasLayerLineFeaturesSelector",
  get: async ({ get }) => {
    const canvasLayerFeatureHidden = get(canvasLayerFeatureHiddenAtom);
    return get(publicFeaturesForCurrentSelection).filter(
      (c) =>
        c?.geometry?.type === "LineString" &&
        !isCable(c) &&
        !isExportCable(c) &&
        !canvasLayerFeatureHidden.includes(c.id)
    );
  },
});

export const canvasLayerPointFeaturesSelector = selector({
  key: "canvasLayerPointFeaturesSelector",
  get: async ({ get }) => {
    const canvasLayerFeatureHidden = get(canvasLayerFeatureHiddenAtom);
    return get(publicFeaturesForCurrentSelection).filter(
      (c) =>
        c?.geometry?.type === "Point" &&
        !canvasLayerFeatureHidden.includes(c.id) &&
        ![TURBINE_PROPERTY_TYPE, SUBSTATION_PROPERTY_TYPE].includes(
          c.properties?.type ?? ""
        )
    );
  },
});

export const projectDataToPureGeojsonFeatures = (projectData) =>
  projectData.map((p) => p);
