import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { ModalHeader, ModalLayerName } from "../InfoModal/InfoModal.style";
import { ReactComponent as KeyInformationIcon } from "../../icons/24/DataInfo.svg";
import { ReactComponent as NoiseIcon } from "../../icons/24/Noise.svg";
import { ReactComponent as Chevron } from "../../icons/24/ArrowRight.svg";
import { ReactComponent as View } from "../../icons/24/ViewFromShore.svg";
import * as turf from "@turf/turf";
import {
  showNoiseAtom,
  noiseLevelSelectorFamily,
  allTurbinesPublicSelector,
} from "../../state/turbines";
import Spinner from "../../icons/spinner/Spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Input } from "../General/Input";
import { colors } from "../../styles/colors";
import { Ui14Bold, Ui14Regular } from "../../styles/typography";
import { useCallback } from "react";
import {
  AnalysisMenu,
  Divider,
  Header,
  Key,
  MenuOption,
  Row,
  Value,
} from "./InfoModal.style";
import { Divider as MenuDivider } from "../ControlPanels/ControlPanel.layout";
import {
  getTurbinesSelectorFamily,
  TURBINE_PROPERTY_TYPE,
} from "../../state/layout";
import { TopRightModeActiveAtom, TopRightModeType } from "./state";
import { ViewToPark } from "../ViewToPark/ViewToPark";
import { getPublicBranchDataAtomFamily } from "../../state/projectAPI";
import { viewFromShoreVisibleAtom } from "../../state/viewToPark";
import { PARK_PROPERTY_TYPE } from "../../state/park";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
  ProjectFeature,
} from "../../services/types";
import { Point } from "geojson";
import {
  AnalysisWrapper,
  Content,
  Menu,
  ParentWrapper,
  StyledModal,
} from "./InfoModal.style";
import { pathParamsAtom, useTypedPath } from "../../state/pathParams";
import { opacityPropertyName, zoomPropertyName } from "../../constants/canvas";
import { findFeatureChildren } from "../../state/projectLayers";

export const TopRightMenuOptions = {
  keyInformation: "keyInformation",
  statistics: "statistics",
  noise: "noise",
  view: "view",
  viewpublic: "viewpublic",
} as const;

const HIDDEN_PROPERTIES = [
  "id",
  "color",
  "type",
  "name",
  "noiseSettings",
  "turbineSettings",
  "Filename",
  zoomPropertyName,
  opacityPropertyName,
];

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  max-width: 40rem;
  padding: 1rem 0;
`;

const PropertyWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AreaName = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${colors.blueDark};
  margin: 1rem 0;
  font-style: italic;
  overflow-wrap: break-word;
`;

const PointCoordinateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const HiderWrapper = styled.div<{
  open: boolean;
}>`
  overflow: ${(p) => (p.open ? "auto" : "hidden")};
  max-height: ${(p) =>
    p.open ? "calc(70vh - var(--lower-right-menu-height))" : "0rem"};
  max-width: 33rem;
  padding: 0 0.2rem;
  padding-top: ${(p) => (p.open ? "1.2rem" : "0rem")};
  transition: all 0.3s linear;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const TopRightInfoContainer = ({
  icon,
  title,
  children,
}: {
  icon: JSX.Element;
  title: string;
  children: ReactNode;
}) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Header onClick={() => setOpen(!open)}>
        {icon}
        <ModalHeader style={{ margin: 0 }}>{title}</ModalHeader>
        <Chevron
          style={{
            rotate: open ? "90deg" : "0deg",
            transition: "rotate 0.2s ease-in-out",
            width: "1rem",
          }}
        />
      </Header>
      <HiderWrapper open={open}>{children}</HiderWrapper>
    </>
  );
};

const Properties = ({ feature }: { feature: ProjectFeature }) => {
  const filteredProperties = useMemo(
    () =>
      Object.keys(feature.properties).reduce(
        (acc, k) =>
          HIDDEN_PROPERTIES.includes(k)
            ? acc
            : { ...acc, [k]: feature.properties[k] },
        {}
      ),
    [feature.properties]
  );

  return (
    <PropertyContainer>
      {Object.keys(filteredProperties).map((k, i) => (
        <PropertyWrapper key={k} style={{ justifyContent: "space-between" }}>
          <Ui14Bold
            style={{ margin: 0, textTransform: "capitalize" }}
          >{`${k.toLowerCase()}:`}</Ui14Bold>
          <Ui14Regular
            style={{ margin: 0 }}
          >{`${filteredProperties[k]}`}</Ui14Regular>
        </PropertyWrapper>
      ))}
    </PropertyContainer>
  );
};

const LineStats = ({ feature }: { feature: ProjectFeature }) => {
  const distance = useMemo(() => {
    if (!feature) return;
    return Math.round(turf.length(feature, { units: "kilometers" }));
  }, [feature]);

  return (
    <>
      <Divider>{`Length: ${distance} km`}</Divider>
    </>
  );
};

const PolygonStats = ({ feature }: { feature: ProjectFeature }) => {
  const area = useMemo(() => {
    if (!feature) return;
    return Math.round(turf.area(feature) / (1000 * 1000));
  }, [feature]);

  return (
    <>
      <div>{`Area: ${area} km²`}</div>
      <Divider />
    </>
  );
};

const Stats = ({ feature }: { feature: ProjectFeature }) => {
  switch (feature.geometry.type) {
    case "Polygon":
      return <PolygonStats feature={feature} />;
    case "LineString":
      return <LineStats feature={feature} />;
    default:
      return null;
  }
};

const PointCoordinateInfo = ({
  canvasFeature,
}: {
  canvasFeature: ProjectFeature<Point>;
}) => {
  return (
    <PointCoordinateWrapper>
      latitude, longitude: {canvasFeature.geometry.coordinates[1].toFixed(4)},{" "}
      {canvasFeature.geometry.coordinates[0].toFixed(4)}
    </PointCoordinateWrapper>
  );
};

export const KeyInformation = ({
  canvasFeature,
  featureName,
}: {
  canvasFeature: ProjectFeature;
  featureName: string;
}) => {
  return (
    <TopRightInfoContainer
      title={"Key information"}
      icon={<KeyInformationIcon />}
    >
      <ModalLayerName>{featureName}</ModalLayerName>
      {canvasFeature.geometry.type === "Point" && (
        <PointCoordinateInfo
          canvasFeature={canvasFeature as ProjectFeature<Point>}
        />
      )}
      <Stats feature={canvasFeature} />
      <Properties feature={canvasFeature} />
    </TopRightInfoContainer>
  );
};

export const Noise = ({ featureName }: { featureName: string }) => {
  const setShowNoise = useSetRecoilState(showNoiseAtom);
  const { parkId } = useParams();
  const turbineNoise = useRecoilValue(
    noiseLevelSelectorFamily({ parkId: parkId! })
  );

  useEffect(() => {
    setShowNoise(true);
    return () => setShowNoise(false);
  }, [setShowNoise]);

  return (
    <TopRightInfoContainer title={"Noise analysis"} icon={<NoiseIcon />}>
      <AreaName>{featureName}</AreaName>
      <AreaName>
        The noise analysis uses the ISO-9613 standard, assuming flat terrain, no
        reflective surfaces, and homogeneous conditions
      </AreaName>
      <Divider />
      <Divider>
        <Row>
          <Key>Sound level at turbine (dB):</Key>
          <Value>
            <Input
              type="number"
              min={90}
              max={120}
              value={turbineNoise.source}
              readOnly={true}
            />
          </Value>
        </Row>
        <Row>
          <Key>Red boundary (dB):</Key>
          <Value>
            <Input
              type="number"
              min={40}
              max={80}
              readOnly={true}
              value={turbineNoise.red}
            />
          </Value>
        </Row>
        <Row>
          <Key>Yellow boundary (dB):</Key>
          <Value>
            <Input
              type="number"
              min={20}
              max={60}
              readOnly={true}
              value={turbineNoise.yellow}
            />
          </Value>
        </Row>
      </Divider>
    </TopRightInfoContainer>
  );
};

const ViewFromShoreAnalysisPublic = () => {
  const {
    projectId: _projectId,
    customerId: _customerId,
    branchId: _branchId,
    parkId: _parkId,
  } = useParams();
  const projectId = _projectId!;
  const customerId = _customerId!;
  const branchId = _branchId!;
  const parkId = _parkId!;

  const publicProjectFeatures = useRecoilValue(
    getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
  );
  const setViewFromShoreVisible = useSetRecoilState(viewFromShoreVisibleAtom);
  const turbines = useRecoilValue(getTurbinesSelectorFamily({ parkId }));

  const allTurbineTypes = useRecoilValue(
    allTurbinesPublicSelector({ customerId, projectId, branchId })
  );

  const idToHubHeight = useMemo(
    () => new Map(allTurbineTypes.map((t) => [t.id, t.hubHeight])),
    [allTurbineTypes]
  );

  const turbineIdToHeight = useMemo(
    () =>
      new Map(
        turbines.map((f) => [
          f.id,
          idToHubHeight.get(f.properties.turbineTypeId) ?? 0,
        ])
      ),
    [turbines, idToHubHeight]
  );

  useEffect(() => {
    setViewFromShoreVisible(true);
    return () => {
      setViewFromShoreVisible(false);
    };
  }, [setViewFromShoreVisible]);

  return (
    <TopRightInfoContainer title={"View from shore"} icon={<View />}>
      <ViewToPark
        publicMode={true}
        parkId={parkId}
        projectData={publicProjectFeatures}
        turbineHeights={turbineIdToHeight}
      />
    </TopRightInfoContainer>
  );
};

const ChosenContent = ({
  chosenMenu,
  canvasFeature,
  featureName,
}: {
  chosenMenu: TopRightModeType;
  canvasFeature: ProjectFeature;
  featureName: string;
}) => {
  const InnerChosenContent = useMemo(() => {
    switch (chosenMenu) {
      case TopRightMenuOptions.keyInformation:
        return (
          <KeyInformation
            canvasFeature={canvasFeature}
            featureName={featureName}
          />
        );
      case TopRightMenuOptions.noise:
        return (
          <React.Suspense fallback={<Spinner />}>
            <Noise featureName={featureName} />
          </React.Suspense>
        );
      case TopRightMenuOptions.viewpublic:
        return (
          <React.Suspense fallback={<Spinner />}>
            <ViewFromShoreAnalysisPublic />
          </React.Suspense>
        );
      default:
        return null;
    }
  }, [canvasFeature, chosenMenu, featureName]);
  if (!chosenMenu) return null;
  if (!InnerChosenContent) return null;
  return (
    <StyledModal>
      <Content>{InnerChosenContent}</Content>
    </StyledModal>
  );
};

export const CanvasLayerInfoModalPublic = ({
  featureId,
  featureName,
  featureType,
  initialMenuOption,
}: {
  featureId: string;
  featureName?: string;
  featureType?: string;
  initialMenuOption?: TopRightModeType;
}) => {
  const [topRightModeActive, setTopRightModeActive] = useRecoilState(
    TopRightModeActiveAtom
  );

  useEffect(() => {
    if (initialMenuOption) {
      setTopRightModeActive(initialMenuOption);
    }
  }, [initialMenuOption, setTopRightModeActive]);

  const navigate = useNavigate();
  const location = useLocation();
  const { parkId } = useRecoilValue(pathParamsAtom);
  const { projectId, customerId, branchId } = useTypedPath(
    "projectId",
    "customerId",
    "branchId"
  );

  const onSelectMenu = useCallback(
    (menu: TopRightModeType) => {
      if (!parkId) return;
      setTopRightModeActive(menu);
      switch (menu) {
        case TopRightMenuOptions.noise:
          navigate(
            `/analysis/noise/${customerId}/${projectId}/${branchId}/${parkId}`
          );
          return;
        case TopRightMenuOptions.viewpublic:
          navigate(
            `/analysis/view/${customerId}/${projectId}/${branchId}/${parkId}`
          );
          return;
        default:
          navigate(`/${customerId}/${projectId}/${branchId}/${parkId}`);
          return;
      }
    },
    [setTopRightModeActive, navigate, customerId, projectId, branchId, parkId]
  );

  useEffect(() => {
    if (location.pathname.startsWith("/analysis/noise/")) {
      setTopRightModeActive(TopRightMenuOptions.noise);
    }
    if (location.pathname.startsWith("/analysis/view/")) {
      setTopRightModeActive(TopRightMenuOptions.viewpublic);
    }
  }, [location, setTopRightModeActive]);

  const canvasLayers = useRecoilValue(
    getPublicBranchDataAtomFamily({ customerId, projectId, branchId })
  );
  const canvasFeature = useMemo(
    () => canvasLayers.find((cl) => cl.id === featureId),
    [canvasLayers, featureId]
  );

  const isParkWithTurbines = useMemo(
    () =>
      canvasFeature &&
      featureType === PARK_PROPERTY_TYPE &&
      findFeatureChildren(canvasLayers, canvasFeature.id).some(
        (id) =>
          canvasLayers.find((c) => c.id === id)?.properties.type ===
          TURBINE_PROPERTY_TYPE
      ),
    [featureType, canvasLayers, canvasFeature]
  );

  if (
    !canvasFeature ||
    [GeoTiffUserUploadedImageType, BathymetryUserUploadedType].includes(
      featureType ?? ""
    )
  )
    return null;

  return (
    <ParentWrapper>
      <AnalysisWrapper>
        <ChosenContent
          chosenMenu={topRightModeActive}
          canvasFeature={canvasFeature}
          featureName={featureName ?? ""}
        />
        <AnalysisMenu id={"analysisContainer"}>
          <Menu>
            <MenuOption
              Icon={KeyInformationIcon}
              iconType="stroke"
              chosenMenu={topRightModeActive}
              setChosenMenu={onSelectMenu}
              option={TopRightMenuOptions.keyInformation}
              tooltipText={"Key information"}
            />
            {isParkWithTurbines && (
              <>
                <MenuDivider />
                <MenuOption
                  Icon={NoiseIcon}
                  iconType="stroke"
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.noise}
                  tooltipText={"Noise"}
                />
              </>
            )}
            {isParkWithTurbines && (
              <>
                <MenuDivider />
                <MenuOption
                  Icon={View}
                  iconType="path"
                  chosenMenu={topRightModeActive}
                  setChosenMenu={onSelectMenu}
                  option={TopRightMenuOptions.viewpublic}
                  tooltipText={"View from shore"}
                />
              </>
            )}
          </Menu>
        </AnalysisMenu>
      </AnalysisWrapper>
    </ParentWrapper>
  );
};
