/* eslint-disable import/no-webpack-loader-syntax */
import JSZip from "jszip";
import { v4 as uuid4 } from "uuid";
import initGdalJs from "gdal3.js";
// @ts-ignore
import workerUrl from "file-loader!gdal3.js/dist/package/gdal3.js";
// @ts-ignore
import dataUrl from "gdal3.js/dist/package/gdal3WebAssembly.data";
// @ts-ignore
import wasmUrl from "gdal3.js/dist/package/gdal3WebAssembly.wasm";
import { ProjectFeature } from "../services/types";

const SHAPE_TYPE_TO_MIME: Record<string, string> = {
  shp: "x-gis/x-shapefile",
  shx: "x-gis/x-shapefile",
  dbf: "application/octet-stream",
  prj: "text/plain",
};

const paths = {
  wasm: wasmUrl,
  data: dataUrl,
  js: workerUrl,
};

export const geojsonFileToZippedShapeFiles = async (
  features: ProjectFeature[]
) => {
  const featureCollection = {
    type: "FeatureCollection",
    features,
  };

  const Gdal = await initGdalJs({ paths });
  const featureCollectionBlob = new File(
    [
      new Blob([JSON.stringify(featureCollection)], {
        type: "application/json",
      }),
    ],
    `${uuid4()}.geojson`
  );

  const result = await Gdal.open(featureCollectionBlob);
  const shapeData = result.datasets[0];

  const output = await Gdal.ogr2ogr(shapeData, [
    "-f",
    "ESRI Shapefile",
    "-t_srs",
    "EPSG:4326",
  ]);

  const allOuput = output.all as FilePath[] | undefined;

  if (!allOuput) throw new Error("No output from ogr2ogr");

  const files = await Promise.all(
    allOuput.map(async (o) => {
      const bytes = await Gdal.getFileBytes(o);
      const blob = new Blob([bytes], {
        type: SHAPE_TYPE_TO_MIME[o.real.split("/").at(-1) as string],
      });
      return new File([blob], o.real.split("/").at(-1) ?? "unknown");
    })
  );

  const zipToUpload = new JSZip();
  for (const shapeFile of files) {
    zipToUpload.file(shapeFile.name, shapeFile);
  }

  const zipBlob = await zipToUpload.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 9,
    },
  });

  return new File([zipBlob], `${uuid4()}.shp.zip`);
};
