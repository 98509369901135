import { Feature, Geometry, GeometryCollection } from "geojson";
import {
  DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE,
  DIVISION_INCLUSION_ZONE_PROPERTY_TYPE,
} from "../constants/division";
import {
  EXPORT_CABLE_PROPERTY_TYPE,
  SUBSTATION_PROPERTY_TYPE,
  CABLE_PROPERTY_TYPE,
  CABLE_CORRIDOR_PROPERTY_TYPE,
} from "../constants/wiring";

import {
  TURBINE_PROPERTY_TYPE,
  MOORING_LINE_PROPERTY_TYPE,
  ANCHOR_PROPERTY_TYPE,
} from "../state/layout";
import { PARK_PROPERTY_TYPE } from "../state/park";

export const GeoTiffUserUploadedImageType = "GeoTiffUserUploadedImageType";
export const BathymetryUserUploadedType = "BathymetryUserUploadedType";

type FeatureType =
  | typeof TURBINE_PROPERTY_TYPE
  | typeof SUBSTATION_PROPERTY_TYPE
  | typeof CABLE_PROPERTY_TYPE
  | typeof EXPORT_CABLE_PROPERTY_TYPE
  | typeof PARK_PROPERTY_TYPE
  | typeof DIVISION_EXCLUSION_ZONE_PROPERTY_TYPE
  | typeof DIVISION_INCLUSION_ZONE_PROPERTY_TYPE
  | typeof CABLE_CORRIDOR_PROPERTY_TYPE
  | typeof MOORING_LINE_PROPERTY_TYPE
  | typeof ANCHOR_PROPERTY_TYPE
  | typeof BathymetryUserUploadedType
  | typeof GeoTiffUserUploadedImageType;

export type BaseProperties = {
  id: string;
  parentIds?: string[];
  type?: FeatureType;
  name?: string;
};

export type ProjectFeature<
  GeometryType extends Geometry = Exclude<Geometry, GeometryCollection>,
  PropertyType = {}
> = Feature<GeometryType, BaseProperties & PropertyType> & { id: string };

export type GenericProjectFeature = ProjectFeature<
  Exclude<Geometry, GeometryCollection>,
  { [key: string]: any }
>;
