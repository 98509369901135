import {
  FillPaint,
  LinePaint,
  Map,
  MapboxGeoJSONFeature,
  SymbolLayer,
} from "mapbox-gl";
import { ProjectFeature } from "../../services/types";
import Polygon from "./Polygon";

export const PARK_COLOR = "#508196";

export const turbinePolygonPaint: FillPaint = {
  "fill-color": PARK_COLOR,
  "fill-opacity": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    0.2,
    0.1,
  ],
};
export const turbinePolygonLinePaint: LinePaint = {
  "line-color": "#000",
  "line-width": [
    "case",
    [
      "boolean",
      ["feature-state", "hover"],
      ["feature-state", "selected"],
      false,
    ],
    3,
    1,
  ],
  "line-opacity": 1,
};
export const polygonSymbols: Omit<SymbolLayer, "id" | "source"> = {
  type: "symbol",
  minzoom: 5,
  layout: {
    "symbol-placement": "point",
    "text-field": "{name}",
    "text-size": 12,
    "symbol-spacing": 300,
    "text-keep-upright": true,
  },
  paint: { "text-opacity": 0.6 },
};

const ParkPolygon = ({
  features,
  sourceId,
  layerId,
  map,
  beforeId,
  onClickCallback,
  onDbClickCallback,
  selectedIds,
}: {
  features: ProjectFeature[];
  sourceId: string;
  layerId: string;
  map: Map;
  beforeId?: string;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean
  ) => void;
  onDbClickCallback?: (features: MapboxGeoJSONFeature[]) => void;
  selectedIds?: (string | number)[];
}) => (
  <Polygon
    features={features}
    sourceId={sourceId}
    layerId={layerId}
    map={map}
    symbols={polygonSymbols}
    paint={turbinePolygonPaint}
    linePaint={turbinePolygonLinePaint}
    onClickCallback={onClickCallback}
    onDbClickCallback={onDbClickCallback}
    selectedIds={selectedIds}
    beforeId={beforeId}
  />
);

export default ParkPolygon;
