import { ReactNode, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Ui14Regular, Ui16Regular } from "../../styles/typography";

const Container = styled.div`
  position: relative;
`;

const TooltipContainer = styled.div<{ show: boolean; secondary: boolean }>`
  z-index: 1;
  position: absolute;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 0.4rem;
  gap: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => (p.secondary ? "rgba(2, 56, 89, 0.9)" : "#000")};

  > p {
    color: ${(p) => (p.secondary ? "white" : "white")};
    ${(p) => p.secondary && "font-size: 1.2rem;"}
  }
  opacity: ${(p) => (p.show ? 1 : 0)};
  visibility: ${(p) => (p.show ? "visible" : "hidden")};
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
`;

const Text = styled(Ui16Regular)`
  margin: 0;
  color: white;
  width: max-content;
  max-width: 20rem;
  text-transform: capitalize;
`;
const MetaText = styled(Ui14Regular)`
  margin: 0;
  color: white;
  width: max-content;
  padding: 0.2rem 0.4rem;
  background-color: ${colors.gray};
  border-radius: 0.4rem;
`;

type Position =
  | "topLeft"
  | "top"
  | "topRight"
  | "right"
  | "bottomRight"
  | "bottom"
  | "bottomLeft"
  | "left";

let timeout: NodeJS.Timeout | undefined;
export default function Tooltip({
  children,
  text,
  shortcut,
  position = "top",
  secondary = false,
}: {
  children: ReactNode;
  text: string;
  shortcut?: string;
  position?: Position;
  secondary?: boolean;
}) {
  const [show, setShow] = useState(false);

  const _onEnter = useCallback(() => {
    timeout = setTimeout(() => setShow(true), 500);
  }, []);
  const _onLeave = useCallback(() => {
    timeout && clearTimeout(timeout);
    setShow(false);
  }, []);

  const cssPosition = useMemo(() => {
    switch (position) {
      case "topLeft":
        return { top: "0", left: "50%", transform: "translate(-100%, -120%)" };
      case "top":
        return {
          top: "0",
          left: "50%",
          transform: "translate(-50%, -120%)",
        };
      case "topRight":
        return { top: "0", left: "50%", transform: "translate(0, -120%)" };
      case "right":
        return { bottom: "50%", right: "0", transform: "translate(110%, 50%)" };
      case "bottomRight":
        return { bottom: "0", left: "50%", transform: "translate(0, 110%)" };
      case "bottom":
        return {
          bottom: "0",
          left: "50%",
          transform: "translate(-50%, 110%)",
        };
      case "bottomLeft":
        return {
          bottom: "0",
          left: "50%",
          transform: "translate(-100%, 110%)",
        };
      case "left":
        return { bottom: "50%", left: "0", transform: "translate(-100%, 50%)" };
      default:
        return { top: "-4rem", left: "0", transform: "translate(-50%, 0)" };
    }
  }, [position]);

  return (
    <Container>
      <TooltipContainer show={show} style={cssPosition} secondary={secondary}>
        <Text>{text}</Text>
        {shortcut && <MetaText>{shortcut}</MetaText>}
      </TooltipContainer>
      <div
        onMouseEnter={_onEnter}
        onMouseLeave={_onLeave}
        onPointerLeave={_onLeave}
        style={{ display: "flex" }}
      >
        {children}
      </div>
    </Container>
  );
}
