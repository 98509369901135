import { useEffect, useMemo } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { mapInteractionSelector, mapRefAtom } from "../state/map";
import Anchor from "../icons/anchor/anchor.png";
import Electricity from "../icons/electricity/electricity.png";
import {
  currentSelectionArrayAtom,
  defaultMouseHandlerCallBackClickableFeature,
} from "../state/selection";
import {
  dynamicLayersSelector,
  selectedDynamicPointsLayersSelectorFamily,
  visibleDynamicLayersAtom,
} from "../state/layer";
import { canvasPolygonLayerId } from "./canvasLayer";
import { featureCollection } from "@turf/turf";
import { useTypedPath } from "../state/pathParams";

const DynamicPointLayers = () => {
  const map = useRecoilValue(mapRefAtom);
  const { projectId } = useTypedPath("projectId");
  const selectedDynamicPointsLayers = useRecoilValue(
    selectedDynamicPointsLayersSelectorFamily(projectId)
  );

  useEffect(() => {
    if (!map) return;

    map.loadImage(Anchor, (error, image) => {
      if (error) throw error;
      if (image && !map.hasImage("anchor")) map.addImage("anchor", image);
    });

    map.loadImage(Electricity, (error, image) => {
      if (error) throw error;
      if (image && !map.hasImage("electricity"))
        map.addImage("electricity", image);
    });

    return () => {
      if (map.hasImage("anchor")) map.removeImage("anchor");
      if (map.hasImage("electricity")) map.removeImage("electricity");
    };
  }, [map]);

  return (
    <>
      {selectedDynamicPointsLayers.map((layer) => (
        <DynamicPointLayer key={layer.name} layer={layer} />
      ))}
    </>
  );
};

const DynamicPointLayer = ({ layer }) => {
  const setVisibleDynamicLayers = useSetRecoilState(visibleDynamicLayersAtom);
  const data = useRecoilValue(dynamicLayersSelector({ layer }));
  const map = useRecoilValue(mapRefAtom);
  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const setLayerMouseHandling = useSetRecoilState(
    defaultMouseHandlerCallBackClickableFeature
  );

  const dynamicPointLayersId = useMemo(
    () => `dynamic-point-layer-id-${layer.name}`,
    [layer]
  );
  const dynamicPointLayersSource = useMemo(
    () => `dynamic-point-layer-source-${layer.name}`,
    [layer]
  );
  const dynamicSelectionType = useMemo(() => layer.name, [layer.name]);

  useEffect(() => {
    setVisibleDynamicLayers((vdl) => [...vdl, dynamicPointLayersId]);
    return () =>
      setVisibleDynamicLayers((vdl) =>
        vdl.filter((l) => l !== dynamicPointLayersId)
      );
  }, [setVisibleDynamicLayers, dynamicPointLayersId]);

  const dynamicPointLayer: mapboxgl.SymbolLayer = useMemo(
    () => ({
      id: dynamicPointLayersId,
      type: "symbol",
      source: dynamicPointLayersSource, // reference the data source
      layout: {
        "icon-image": layer.icon, // reference the image
        "icon-size": 0.05,
      },
      minzoom: 2,
    }),
    [dynamicPointLayersId, dynamicPointLayersSource, layer.icon]
  );

  useEffect(() => {
    if (!map) return;

    map.addSource(dynamicPointLayersSource, {
      type: "geojson",
      promoteId: "id",
      data: featureCollection(data.features),
    });
    map.addLayer(
      dynamicPointLayer,
      map.getLayer(canvasPolygonLayerId) ? canvasPolygonLayerId : undefined
    );
    const onMouseClick = (e) => {
      e.preventDefault();
      if (!mapInteraction.hover) return;
      setCurrentSelectionArray([
        {
          type: dynamicSelectionType,
          selection: e.features[0],
        },
      ]);
    };
    setLayerMouseHandling((l) => ({
      ...l,
      [dynamicPointLayersId]: {
        onClick: onMouseClick,
      },
    }));

    return () => {
      map.removeLayer(dynamicPointLayer.id);
      map.removeSource(dynamicPointLayersSource);

      setLayerMouseHandling((l) => {
        const cleanedL = { ...l };
        delete cleanedL[dynamicPointLayersId];
        return cleanedL;
      });
    };
  }, [
    mapInteraction.hover,
    map,
    setCurrentSelectionArray,
    data,
    dynamicPointLayersId,
    dynamicPointLayer,
    dynamicPointLayersSource,
    dynamicSelectionType,
    setLayerMouseHandling,
  ]);

  return null;
};

export default DynamicPointLayers;
