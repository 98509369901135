import { atom, selector } from "recoil";
import { Feature, Point } from "geojson";
import { featureWGS84ToProjected } from "../utils/proj4";

export enum VIEW_MODE {
  NATURAL_MODE = "natural",
  WIRE_FRAME_MODE = "wireframe",
}

export const viewFromShoreVisibleAtom = atom<boolean>({
  key: "viewFromShoreVisibleAtom",
  default: false,
});

export type LngLat = { lng: number; lat: number };

export const viewPositionAtom = atom<LngLat | undefined>({
  key: "viewPositionAtom",
  default: undefined,
});

export const viewDateAtom = atom<Date>({
  key: "viewDateAtom",
  default: new Date("2022-08-01 18:00"),
});

export const viewFovAtom = atom<number>({
  key: "viewFovAtom",
  default: 30,
});

export const viewParkRotationAtom = atom<number>({
  key: "viewParkRotationAtom",
  default: 0,
});

export const viewTurbineHeightsAtom = atom<number[]>({
  key: "viewTurbineHeightsAtom",
  default: undefined,
});

export const viewHeightAtom = atom<number>({
  key: "viewHeightAtom",
  default: 10,
});

export const viewOrigoWGS84Atom = atom<Feature<Point> | null>({
  key: "viewOrigoWGS84Atom",
  default: null,
});

export const viewCameraAspectAtom = atom<any>({
  key: "viewCameraAspectAtom",
  default: undefined,
});

export const viewViewModeAtom = atom<VIEW_MODE>({
  key: "viewViewModeAtom",
  default: VIEW_MODE.NATURAL_MODE,
});

export const viewTurbineLightsAtom = atom<boolean>({
  key: "viewTurbineLightsAtom",
  default: false,
});

export const viewProj4StringAtom = atom<undefined | string>({
  key: "viewProj4StringAtom",
  default: undefined,
});

export const viewOrigoSelector = selector({
  key: "viewOrigoSelector",
  get: async ({ get }) => {
    const origoWGS84 = get(viewOrigoWGS84Atom);
    const proj4String = get(viewProj4StringAtom);
    if (!origoWGS84 || !proj4String) return;
    return featureWGS84ToProjected(origoWGS84, proj4String).geometry
      .coordinates;
  },
});

export const viewTurbineCoordsAtom = atom<undefined | [number, number][]>({
  key: "viewTurbineCoordsAtom",
  default: undefined,
});
