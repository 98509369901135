import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { analysisVisibleKey } from "../../constants/public";
import {
  canvasGeotiffLayerSource,
  canvasLineLayerSource,
  canvasPointLayerSource,
  canvasPolygonLayerSource,
} from "../../layers/canvasLayer";
import { onMobileState } from "../../state/onMobile";
import { useTypedPath } from "../../state/pathParams";
import { publicProjectSettingsSelectorFamily } from "../../state/projectAPI";
import {
  currentSelectionWMSAtom,
  firstCurrentSelectionSelector,
} from "../../state/selection";
import {
  CanvasLayerInfoModalPublic,
  TopRightMenuOptions,
} from "../CanvasLayerInfoModal/CanvasLayerInfoModal";
import GenericInfoModal from "../GenericInfoModal/GenericInfoModal";
import { turbinePolygonsSourceId } from "../Layout";
import WMSInfoModal from "../WMSInfoModal/WMSInfoModal";

export const InfoModalPublic = () => {
  const currentSelection = useRecoilValue(firstCurrentSelectionSelector);
  const currentSelectionWMS = useRecoilValue(currentSelectionWMSAtom);

  const onMobile = useRecoilValue(onMobileState);
  const { customerId, projectId } = useTypedPath("customerId", "projectId");
  const publicProjectSettings = useRecoilValue(
    publicProjectSettingsSelectorFamily({ projectId, customerId })
  );

  const showAnalysis = useMemo(
    () => (publicProjectSettings[analysisVisibleKey] ?? true) && !onMobile,
    [publicProjectSettings, onMobile]
  );

  if (currentSelectionWMS.length !== 0 && !onMobile) {
    return <WMSInfoModal selections={currentSelectionWMS} />;
  }

  if (currentSelection && showAnalysis) {
    switch (currentSelection.source) {
      case turbinePolygonsSourceId:
      case canvasGeotiffLayerSource:
      case canvasPolygonLayerSource:
      case canvasPointLayerSource:
      case canvasLineLayerSource:
        return (
          <CanvasLayerInfoModalPublic
            featureId={currentSelection.properties.id}
            featureName={
              currentSelection.properties.name ||
              currentSelection.properties.NAVN ||
              "undefined"
            }
            featureType={currentSelection.properties?.type}
            initialMenuOption={TopRightMenuOptions.keyInformation}
          />
        );
      default:
        return (
          <GenericInfoModal
            feature={currentSelection}
            source={currentSelection.source}
          />
        );
    }
  }

  return null;
};
