import { useRecoilValue, useSetRecoilState } from "recoil";
import { useMouseHandlingWithSmallestFeaturePrioritized } from "../hooks/mouseHandling";
import {
  currentSelectionArrayAtom,
  currentSelectionWMSAtom,
  currentWiringSelectionArrayAtom,
  defaultMouseHandlerCallBackClickableFeature,
} from "../state/selection";
import { useCallback } from "react";

const MouseHandling = () => {
  const layerMouseHandling = useRecoilValue(
    defaultMouseHandlerCallBackClickableFeature
  );
  const setCurrentSelection = useSetRecoilState(currentSelectionArrayAtom);
  const setWiringCurrentSelection = useSetRecoilState(
    currentWiringSelectionArrayAtom
  );
  const setCurrentSelectionWMS = useSetRecoilState(currentSelectionWMSAtom);
  const clickOutside = useCallback(() => {
    setCurrentSelection([]);
    setWiringCurrentSelection([]);
    setCurrentSelectionWMS([]);
  }, [setCurrentSelection, setWiringCurrentSelection, setCurrentSelectionWMS]);
  useMouseHandlingWithSmallestFeaturePrioritized(
    layerMouseHandling,
    clickOutside
  );

  return null;
};

export default MouseHandling;
