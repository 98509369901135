import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { modalTypeOpenAtom } from "../../state/modal";
import { useTypedPath } from "../../state/pathParams";
import { publicProjectSettingsSelectorFamily } from "../../state/projectAPI";
import { StandardBox } from "../../styles/boxes/Boxes";
import { Ui14Regular, Ui18Bold } from "../../styles/typography";
import FullScreenModal from "../FullScreenModal/FullScreenModal";
import Button from "../General/Button";

export const PublicModeGreetingModalType = "PublicModeGreetingModalType";

const Wrapper = styled(StandardBox)`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 2.4rem;
  max-width: 60%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
  justify-content: flex-end;
`;

const Header = styled(Ui18Bold)`
  color: #264e5d;
  margin: 0;
`;

const PublicModeGreetingsModal = () => {
  const { projectId, customerId } = useTypedPath("projectId", "customerId");
  const [modalTypeOpen, setModalTypeOpen] = useRecoilState(modalTypeOpenAtom);
  const [hasShown, setHasShown] = useState(false);

  const { greeting_title, greeting_text } = useRecoilValue(
    publicProjectSettingsSelectorFamily({ projectId, customerId })
  );

  useEffect(() => {
    if (
      !greeting_title ||
      greeting_title.trim() === "" ||
      !greeting_text ||
      greeting_text.trim() === "" ||
      hasShown
    )
      return;
    setModalTypeOpen({ modalType: PublicModeGreetingModalType });
    setHasShown(true);
  }, [greeting_title, greeting_text, setModalTypeOpen, setHasShown, hasShown]);

  if (modalTypeOpen?.modalType !== PublicModeGreetingModalType) return null;

  return (
    <FullScreenModal>
      <Wrapper>
        <>
          <Header>{greeting_title}</Header>
          <TextContainer>
            {greeting_text &&
              greeting_text.split("\n").map((line, i) => (
                <Ui14Regular style={{ margin: 0 }} key={i}>
                  {line}
                </Ui14Regular>
              ))}
          </TextContainer>
          <ButtonContainer>
            <Button text="Close" onClick={() => setModalTypeOpen(undefined)} />
          </ButtonContainer>
        </>
      </Wrapper>
    </FullScreenModal>
  );
};

export default PublicModeGreetingsModal;
