import { fetchEnhancer, fetchJson } from "./utils";
import { ProjectFeature } from "./types";
import { isMetadataShapeFileExport } from "../utils/debug";
import { PublicProjectMeta } from "../state/projectAPI";
import { z } from "zod";

const PROJECT_DATA_API_VERSION = "3.0.0";
export const PROJECT_DATA_API_PATH_V3 = "/api/project-data-v3";

const PROJECT_SERVICE_API_VERSION = "1.0.0";
export const PROJECT_SERVICE_API_PATH = "/api/project-service";
export const NODE_SERVICE_API_PATH = "/api/customer";

// NOTE: These must be kept in sync with the types in the api-v2 repo.

export enum NodeType {
  FOLDER = "folder",
  PERSONAL_FOLDER = "personal_folder",
  PROJECT = "project",
}

export const _SharedNodeInformation = z.object({
  id: z.string(),
  name: z.string(),
  parent_id: z.string().nullish(),
  organisation_id: z.string().nullish(),
  legacy_id: z.string(),
  created_at: z.number(),
  archived_at: z.number().nullish(),
  author: z.string(),
});
export const _PersonalFolderNodeInformation = _SharedNodeInformation.extend({
  type: z.literal(NodeType.PERSONAL_FOLDER),
});
export const _FolderNodeInformation = _SharedNodeInformation.extend({
  type: z.literal(NodeType.FOLDER),
});
export const _ProjectNodeInformation = _SharedNodeInformation.extend({
  type: z.literal(NodeType.PROJECT),
  location: z.string().nullish(),
  status: z.string().nullish(),
  tutorial_id: z.string().nullish(),
  main_branch_id: z.string().nullish(),
});

export const _Node = _ProjectNodeInformation
  .or(_PersonalFolderNodeInformation)
  .or(_FolderNodeInformation);
export type Node = z.infer<typeof _Node>;

export type CommonMeta = {
  id: string;
  title: string;
  createdAt: number;
  updatedAt: number;
  isArchived: boolean;
  customerId: string;
};

export type ProjectMeta = z.infer<typeof _ProjectNodeInformation>;

export type BranchMeta = CommonMeta & {
  projectId: string;
  author?: string;
};

export type PublicBranchMeta = {
  is_public: boolean;
  partitionKey: string;
  data_layers: string[];
  sortKey: string;
  branchId: string;
};

// -------- Projects --------

export const getGeoTiffPresignedUrlForPublicProject = async (
  nodeId: string,
  branchId: string,
  fileName: string
) =>
  fetchJson<string>(
    `${PROJECT_DATA_API_PATH_V3}/public/georefimage/${nodeId}/${branchId}/${fileName}`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }
  );

export const getPublicBranchData = async (
  nodeId: string,
  branchId: string,
  isCheckly?: boolean
) => {
  const res = await fetchJson<
    { features: ProjectFeature[] } & { dataUrl?: string }
  >(
    `${PROJECT_SERVICE_API_PATH}/public/node/${nodeId}/branch/${branchId}/features${
      isCheckly ? "?isCheckly=true" : ""
    }`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    }
  );
  if (res.dataUrl) {
    const data = await fetchJson(res.dataUrl, {
      method: "get",
    });
    return data;
  } else {
    return res;
  }
};

export const getProjectPublicSettings = async (nodeId: string) =>
  await fetchJson<PublicProjectMeta>(
    `${PROJECT_DATA_API_PATH_V3}/public/settings/${nodeId}`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }
  );

export const getPublicBranchMetadata = async (
  nodeId: string,
  branchId: string
) =>
  await fetchJson<{ branch: BranchMeta }>(
    `${PROJECT_SERVICE_API_PATH}/public/node/${nodeId}/branch/${branchId}`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_SERVICE_API_VERSION,
      },
    }
  );

export const getPublicProjectBranches = async (
  nodeId: string,
) =>
  fetchJson<PublicBranchMeta[]>(
    `${PROJECT_DATA_API_PATH_V3}/public/metadata/${nodeId}`,
    {
      method: "get",
      headers: {
        "x-project-data-client-version": PROJECT_DATA_API_VERSION,
      },
    }
  ).then((branches) => branches.filter((b) => b.is_public));

export const getGeotiffImage = async (
  nodeId: string,
  branchId: string,
  filename: string
) => {
  const presignedUrl = await getGeoTiffPresignedUrlForPublicProject(
    nodeId,
    branchId,
    filename
  );
  const res = await fetchEnhancer(presignedUrl, { method: "get" });
  const blob = await res.blob();
  return blob;
};

export const getGeojsonFeaturesAsShapeZip = async (
  features: ProjectFeature[]
) => {
  const metadata = isMetadataShapeFileExport();
  const res = await fetchEnhancer(
    `/api/featureexporter/geojson/shpzip` + (metadata ? "?metadata=true" : ""),
    {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(features),
    }
  );
  return await res.blob();
};
