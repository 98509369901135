import React from "react";
import styled from "styled-components";
import { Ui14Regular } from "../../styles/typography";
import {
  Divider,
  ModalHeader,
  StyledModalBase,
  ModalLayerName,
} from "../InfoModal/InfoModal.style";
import { ProjectFeature } from "../../services/types";

const StyledModal = styled(StyledModalBase)`
  overflow-y: auto;
  max-height: 80vh;
  overflow-wrap: break-word;
  position: absolute;
  top: 64px;
  right: 8px;
  display: inline-block;
`;

const FilterWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  align-items: center;
  cursor: pointer;

  svg {
    height: 100%;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &:hover ${FilterWrapper} {
    opacity: 1;
  }
`;

const ValueWithFilter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const BrokenWord = styled(Ui14Regular)`
  word-break: break-all;
`;

const GenericInfoModal = ({
  feature,
  source,
}: {
  feature: ProjectFeature;
  source: string;
}) => {
  if (!feature?.properties) return null;

  return (
    <StyledModal>
      <ModalHeader>Information</ModalHeader>
      <Row>
        <ModalLayerName style={{ margin: 0 }}>{source}</ModalLayerName>
      </Row>
      <Divider />
      {Object.keys(feature.properties).map((k) => {
        return (
          <Row key={k}>
            <Ui14Regular style={{ textTransform: "capitalize" }}>
              {k}:
            </Ui14Regular>
            <ValueWithFilter>
              <BrokenWord>
                {feature.properties[k]?.toString() ?? "--"}
              </BrokenWord>
            </ValueWithFilter>
          </Row>
        );
      })}
    </StyledModal>
  );
};

export default GenericInfoModal;
