import { useEffect, useRef } from "react";
import { getCoordinates } from "../../utils/geojson/validate";
import styled from "styled-components";
import { DEFAULT_CANVAS_LAYER_COLOR } from "../../layers/canvasLayer";
import { wgs84ToPsuedoMercator } from "../../utils/proj4";
import { projectDataToPureGeojsonFeatures } from "../../state/projectLayers";
import { ProjectFeature } from "../../services/types";
import { TurbinesFeatureCollection } from "../../state/layout";
import { Feature, Geometry } from "geojson";

const FeatureCanvas = styled.canvas`
  width: 2.4rem;
  height: 2.4rem;
`;

const useFeatureToCanvas = (
  features: (ProjectFeature | TurbinesFeatureCollection)[],
  canvasRef
) => {
  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const singleFeatures = features.flatMap((f) => {
      const isMultiFeature =
        f.geometry.type.includes("Multi") && f.type === "Feature";
      if (isMultiFeature) {
        return f.geometry.coordinates.map((c) => ({
          ...f,
          geometry: {
            ...f.geometry,
            type: f.geometry.type.replace("Multi", ""),
            coordinates: c,
          } as Geometry,
        }));
      } else {
        return [f] as Feature[];
      }
    });

    const featureCoordsPsuedoMercator = projectDataToPureGeojsonFeatures(
      singleFeatures
    )
      .filter(
        (f) => f.geometry.coordinates && f.geometry.coordinates.length !== 0
      )
      .map((f) => ({
        feature: f,
        coords: getCoordinates(f.geometry.coordinates),
      }))
      .filter((c) => c.coords != null)
      .map((c) => ({
        ...c,
        coords: c.coords.map((c) => wgs84ToPsuedoMercator(c.slice(0, 2))),
      }));
    const Xs = featureCoordsPsuedoMercator
      .map((c) => c.coords.map((c) => c[0]))
      .flat();
    const Ys = featureCoordsPsuedoMercator
      .map((c) => c.coords.map((c) => c[1]))
      .flat();

    const minX = Math.min(...Xs);
    const maxX = Math.max(...Xs) - minX;

    const minY = Math.min(...Ys);
    const maxY = Math.max(...Ys) - minY;

    const widthHeightRatio = maxX / maxY;

    featureCoordsPsuedoMercator.forEach(({ coords, feature }) => {
      const xOffset =
        (canvas.width / 2) *
        (widthHeightRatio < 1 ? widthHeightRatio - 1.0 : 0.0);
      const yOffset =
        (canvas.height / 2) *
        (1 / widthHeightRatio < 1 ? 1 / widthHeightRatio - 1.0 : 0.0);

      const xStretch = widthHeightRatio < 1 ? widthHeightRatio : 1.0;
      const yStretch = 1 / widthHeightRatio < 1 ? 1 / widthHeightRatio : 1.0;

      const normalizedCoords = coords.map((c) => [
        ((c[0] - minX) / maxX) * canvas.width * xStretch - xOffset,
        canvas.height -
          ((c[1] - minY) / maxY) * canvas.height * yStretch +
          yOffset,
      ]);

      if (feature.geometry.type.includes("Point")) {
        ctx.strokeStyle = "#000000";
        ctx.fillStyle = "#000000";
        normalizedCoords.forEach((c) => {
          ctx.beginPath();
          ctx.arc(c[0], c[1], 0.75, 0, 2 * Math.PI);
          ctx.stroke();
          ctx.fill();
        });
      } else {
        ctx.strokeStyle =
          feature.properties.color || DEFAULT_CANVAS_LAYER_COLOR;
        ctx.fillStyle = feature.properties.color || DEFAULT_CANVAS_LAYER_COLOR;
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(normalizedCoords[0][0], normalizedCoords[0][1]);
        normalizedCoords.slice(1).forEach((c) => ctx.lineTo(c[0], c[1]));
        ctx.stroke();
        if (feature.geometry.type === "Polygon") ctx.fill();
      }
    });
  }, [features, canvasRef]);
};

const FeatureToCanvas = ({ features }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useFeatureToCanvas(features, canvasRef);
  return <FeatureCanvas width="100" height="100" ref={canvasRef} />;
};

export default FeatureToCanvas;
