import React, { ReactNode } from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/browser";
import { colors } from "../../styles/colors";

const WEBGL_ERROR_MESSAGES = [
  "Failed to initialize WebGL.",
  "Error creating WebGL context.",
];

const ErrorTextWrapper = styled.div`
  font-size: 2rem;
  text-align: center;
  width: 80%;
`;

const BulletPointWrapper = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.grayLight};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export class NotAuthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = "NotAuthorizedError";
  }
}

type Props = {
  children: ReactNode;
};
class ErrorBoundaryGlobal extends React.Component<
  Props,
  { hasError: boolean; error?: Error }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(errorInfo);
    console.error(error);
    Sentry.captureException(error);
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      if (this.state.error instanceof NotAuthorizedError) {
        return (
          <Wrapper>
            <ErrorTextWrapper>
              Unauthorized, please go back to{" "}
              <a href={`/projects`} style={{ textDecoration: "none" }}>
                projects page
              </a>
            </ErrorTextWrapper>
          </Wrapper>
        );
      }
      if (WEBGL_ERROR_MESSAGES.includes(this.state?.error?.message || "")) {
        return (
          <Wrapper>
            <ErrorTextWrapper>
              Something went wrong when trying to initialize a 3d scene using
              your graphics card. Annoyingly this can be because of many reasons
              but we recommend to do the following.
              <BulletPointWrapper>
                <ul>
                  <li>Update your browser</li>
                  <li>
                    Update your graphic drivers{" "}
                    {"(select the one appropriate for you)"}
                  </li>
                  <ul>
                    <li>
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        href="https://www.nvidia.com/download/index.aspx"
                      >
                        Nvidia
                      </a>
                    </li>
                    <li>
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        href="https://www.amd.com/en/support"
                      >
                        Amd
                      </a>
                    </li>
                    <li>
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        href="https://www.intel.com/content/www/us/en/download-center/home.html"
                      >
                        Intel
                      </a>
                    </li>
                  </ul>
                </ul>
              </BulletPointWrapper>
            </ErrorTextWrapper>
          </Wrapper>
        );
      }
      return (
        <Wrapper>
          <ErrorTextWrapper>
            An error has occured, please refresh the page in order to continue
            or go back to the{" "}
            <a href={`/projects`} style={{ textDecoration: "none" }}>
              projects page
            </a>
            , we apologize for the inconvenience
          </ErrorTextWrapper>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryGlobal;
