import React from "react";
import styled from "styled-components";
import { ReactComponent as Checkmark } from "../../icons/checkmark/checkmark.svg";
import { colors } from "../../styles/colors";
import { InputLabel } from "./Input";

const CheckBoxLabel = styled.label<{ labelPlacement: "above" | "after" }>`
  display: flex;
  gap: ${(p) => (p.labelPlacement === "after" ? "1.2rem" : "0.4rem")};
  flex-direction: ${(p) =>
    p.labelPlacement === "after" ? "row-reverse" : "column"};
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input`
  visibility: hidden;
  position: absolute;
`;

type StyledCheckboxProps = {
  checked: boolean;
  disabled: boolean;
};

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 1.8rem;
  height: 1.8rem;
  background: ${(p) => (p.checked ? colors.blueDark : "white")};
  opacity: ${(p) => (p.disabled ? 0.6 : 1)};
  border: 1px solid ${(p) => (p.checked ? colors.blueDark : colors.blueMedium)};
  transition: all 200ms ease;
  ${HiddenCheckbox}:hover + & {
    ${(p) => !p.disabled && `border: 1px solid ${colors.blueDark}`};
  }
  ${(p) => p.disabled && `cursor: auto`};
  border-radius: 0.4rem;
  ${(p) => p.disabled && "cursor: not-allowed;"}
  path {
    stroke: white;
  }
`;

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelPlacement?: "above" | "after";
}

export default function Checkbox({
  label,
  labelPlacement = "above",
  checked,
  disabled,
  ...p
}: CheckboxProps) {
  return (
    <CheckBoxLabel
      style={disabled ? { cursor: "not-allowed" } : {}}
      labelPlacement={labelPlacement}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <CheckboxContainer>
        <HiddenCheckbox
          type="checkbox"
          checked={checked}
          disabled={disabled}
          {...p}
        />
        <StyledCheckbox checked={checked ?? false} disabled={disabled ?? false}>
          {checked && <Checkmark />}
        </StyledCheckbox>
      </CheckboxContainer>
    </CheckBoxLabel>
  );
}
