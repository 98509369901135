import { TopRightMenuOptions } from "./CanvasLayerInfoModal";
import { atom } from "recoil";

export type TopRightModeType =
  | undefined
  | typeof TopRightMenuOptions[keyof typeof TopRightMenuOptions];

export const TopRightModeActiveAtom = atom<TopRightModeType>({
  key: "InfoModalOpenState",
  default: undefined,
});
