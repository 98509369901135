import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { mapRefAtom } from "../../state/map";
import * as turf from "@turf/turf";
import { colors } from "../../styles/colors";
import { GeoJSONSource, MapMouseEvent } from "mapbox-gl";
import { coordinatesToPolygon } from "../../utils/geojson/utils";
import { featureCollection, point } from "@turf/turf";
import { Feature, Geometry } from "geojson";
import { leftMenuModeActiveAtom } from "../../state/filter";

const DistanceMeterWrapper = styled.div`
  position: fixed;
  padding: 1rem;
  top: 60px;
  background-color: ${colors.grayLight};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 12rem;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  border-radius: 6px;
`;

export const MeasureAreaMode = "measure-area-mode-type";

const coordsToArea = (distanceCoords: number[][]) => {
  const polygon = coordinatesToPolygon(distanceCoords);
  return Math.round(turf.area(polygon) / (1000 * 1000));
};

const Area = () => {
  const leftMenuModeActive = useRecoilValue(leftMenuModeActiveAtom);

  if (leftMenuModeActive !== MeasureAreaMode) return <></>;

  return <AreaInner />;
};

const AreaInner = () => {
  const [hoverPoint, setHoverPoint] = useState([0, 0]);
  const map = useRecoilValue(mapRefAtom);
  const setLeftMenuModeActive = useSetRecoilState(leftMenuModeActiveAtom);
  const [areaCoords, setAreaCoords] = useState<number[][]>([]);

  const sourceId = useMemo(() => Math.random().toString(), []);

  useEffect(() => {
    if (!map) return;

    map.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer({
      id: "measure-polygon-points",
      type: "circle",
      source: sourceId,
      paint: {
        "circle-radius": 5,
        "circle-color": "#000",
      },
      filter: ["in", "$type", "Point"],
    });
    map.addLayer({
      id: "measure-polygons",
      type: "line",
      source: sourceId,
      paint: {
        "line-color": "#000",
      },
      filter: ["in", "$type", "Polygon"],
    });

    map.moveLayer("measure-polygon-points");
    map.moveLayer("measure-polygons");
    return () => {
      map.removeLayer("measure-polygon-points");
      map.removeLayer("measure-polygons");
      map.removeSource(sourceId);
    };
  }, [map, sourceId]);

  useEffect(() => {
    if (!map) return;

    const polygon = coordinatesToPolygon([
      ...areaCoords,
      hoverPoint,
      ...areaCoords.slice(0, 1),
    ]);

    const geojson = featureCollection([
      ...areaCoords.map((c, i) => point(c, { id: i })),
      polygon,
    ] as Feature<Geometry>[]);

    const source = map.getSource(sourceId) as GeoJSONSource;
    source.setData(geojson);

    return () => {
      source.setData({
        type: "FeatureCollection",
        features: [],
      });
    };
  }, [areaCoords, hoverPoint, map, sourceId]);

  useEffect(() => {
    if (!map) return;

    const click = (e: MapMouseEvent) => {
      e.preventDefault();
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["measure-polygon-points"],
      });

      if (0 < features.length) {
        const f = features[0];
        const id = f.id ?? f.properties?.["id"] ?? "";
        const filteredDistanceCoords = areaCoords.filter((_, i) => i !== id);
        setAreaCoords(filteredDistanceCoords);
      } else {
        setAreaCoords((coords) => [...coords, [e.lngLat.lng, e.lngLat.lat]]);
      }
    };

    const mousemove = (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["measure-polygon-points"],
      });

      setHoverPoint(Object.values(e.lngLat));

      map.getCanvas().style.cursor =
        features.length !== 0 && features[0].layer.type === "circle"
          ? "no-drop"
          : "crosshair";
    };

    map.on("click", click);
    map.on("mousemove", mousemove);

    const abort = (e) => {
      if (e.code === "Escape") {
        setLeftMenuModeActive(undefined);
      }
    };

    document.addEventListener("keydown", abort);

    return () => {
      map.off("click", click);
      map.off("mousemove", mousemove);
      document.removeEventListener("keydown", abort);
    };
  }, [
    map,
    areaCoords,
    setAreaCoords,
    setHoverPoint,
    hoverPoint,
    setLeftMenuModeActive,
    sourceId,
  ]);

  return (
    <DistanceMeterWrapper>
      {coordsToArea([...areaCoords, hoverPoint, ...areaCoords.slice(0, 1)])}
      km²
    </DistanceMeterWrapper>
  );
};

export default Area;
