import mapboxgl, { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import MapFeature from "./Feature";
import { Feature } from "geojson";
import { fillOpacityWithCallback } from "./utils";
import { getZoomLevels } from "../../layers/utils";

const DEFAULT_COLOR = "#27AE60";

const Point = ({
  features,
  sourceId,
  layerId,
  map,
  symbols,
  paint,
  color,
  opacity,
  beforeId,
  onClickCallback,
  selectedIds,
  zoomLevels,
}: {
  features: Feature[];
  sourceId: string;
  layerId: string;
  map: Map;
  symbols?: Omit<mapboxgl.SymbolLayer, "id" | "source">;
  paint?: mapboxgl.CirclePaint;
  color?: string;
  opacity?: number;
  beforeId?: string;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean
  ) => void;
  selectedIds?: (string | number)[];
  zoomLevels?: [number, number];
}) => {
  const layers = useMemo(() => {
    return [
      {
        type: "circle",
        paint: {
          "circle-color": "rgba(0,0,0,0)",
          "circle-radius": 3,
          "circle-opacity": onClickCallback
            ? [
                "case",
                [
                  "boolean",
                  ["feature-state", "hover"],
                  ["feature-state", "selected"],
                  false,
                ],
                1,
                0.6,
              ]
            : 1,
          ...(paint ?? {}),
        },
        id: layerId,
        source: sourceId, // reference the data source
        ...getZoomLevels(zoomLevels),
      },
      ...(symbols
        ? [
            {
              ...symbols,
              id: `${layerId}-symbolid`,
              source: sourceId,
              ...getZoomLevels(zoomLevels),
            },
          ]
        : []),
    ];
  }, [
    layerId,
    sourceId,
    paint,
    symbols,
    onClickCallback,
    zoomLevels,
  ]) as mapboxgl.AnyLayer[];

  useEffect(() => {
    if (!map || (paint && "circle-color" in paint)) return;
    map.setPaintProperty(layerId, "circle-color", color ?? DEFAULT_COLOR);
  }, [layerId, map, color, layers, paint]);

  useEffect(() => {
    if (!map) return;
    if (opacity !== undefined) {
      map.setPaintProperty(
        layerId,
        "circle-opacity",
        fillOpacityWithCallback(opacity, opacity + 0.2)
      );
    }
  }, [layerId, map, opacity, layers]);

  return (
    <MapFeature
      layers={layers}
      features={features}
      sourceId={sourceId}
      layerId={layerId}
      map={map}
      beforeId={beforeId}
      onClickCallback={onClickCallback}
      selectedIds={selectedIds}
    />
  );
};

export default Point;
