import { selectorFamily } from "recoil";
import * as api from "../services/projectDataAPIService";
import { publicNodeInfoSelectorFamily } from "./projectAPI";

// ======== Branch ========

export const getPublicBranchesForProject = selectorFamily<
  api.BranchMeta[],
  {
    customerId: string;
    projectId: string;
  }
>({
  key: "getPublicBranchesForProject",
  get:
    ({ customerId, projectId }) =>
    async ({get}) => {
      const node = get(publicNodeInfoSelectorFamily({ customerId, projectId }));
      const publicBranches = await api.getPublicProjectBranches(
        node.id
      );
      return (
        await Promise.all(
          publicBranches.map(async (b) =>
            api
              .getPublicBranchMetadata(node.id, b.branchId)
              .then((o) => o.branch)
          )
        )
      ).filter((b) => b != null);
    },
});
