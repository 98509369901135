import React, { useEffect } from "react";
import styled from "styled-components";
import AnimatedLogo from "../../icons/animatedlogo/AnimatedLogo";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  transition: backdrop-filter 0.2s ease-in-out;
`;

const FullScreenLoaderWithBlur = () => {
  useEffect(() => {
    const wheelHandler = (event) => {
      event.preventDefault();
    };
    document.addEventListener("wheel", wheelHandler, { passive: false });
    return () => {
      document.removeEventListener("wheel", wheelHandler);
    };
  }, []);
  return (
    <Wrapper id={"FullScreenLoaderWithFade"}>
      <AnimatedLogo />
    </Wrapper>
  );
};

export default FullScreenLoaderWithBlur;
