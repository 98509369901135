import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Chevron } from "../../icons/24/ArrowRight.svg";
import { Ui14Medium } from "../../styles/typography";

const Content = styled.div`
  color: #264e5d;
  margin: 0.4rem 0;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleableTitle = styled(Title)`
  cursor: pointer;
  overflow: hidden;
`;

const TitleWithImage = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ChevronWrapper = styled.div<{ open?: boolean }>`
  height: 60%;
  transform: rotate(${(p) => (p.open ? "90deg" : "0deg")});
  transition: 0.1s;
  svg {
    height: 2.2rem;
    width: 2.2rem;
  }
`;

const ToggleableList = ({
  title,
  icon,
  children,
  defaultOpen = false,
}: {
  title: string;
  icon?: string;
  children: JSX.Element;
  defaultOpen?: boolean;
}) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);
  return (
    <Content>
      <ToggleableTitle onClick={() => setOpen(!open)}>
        <TitleWithImage>
          {icon && <img width="35px" height="35px" alt="icon" src={icon} />}
          <Ui14Medium>{title}</Ui14Medium>
        </TitleWithImage>{" "}
        <ChevronWrapper open={open}>
          <Chevron width={"1rem"} />
        </ChevronWrapper>
      </ToggleableTitle>
      {open && children}
    </Content>
  );
};

export default ToggleableList;
