import React, { Suspense, useEffect, useMemo, useState } from "react";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { KeepSelectionInSyncWithPathParameters } from "../components/Design/ProjectMapView/ProjectMapView";
import Distance from "../components/Distance/Distance";
import RightSidePublic from "../components/RightSide/RightSidePublic";
import {
  getPublicBranchDataAtomFamily,
  publicNodeInfoSelectorFamily,
  publicProjectSettingsSelectorFamily,
} from "../state/projectAPI";
import PublicModeGreetingsModal from "../components/PublicModeGreetingsModal/PublicModeGreetingsModal";
import PublicOffshoreMap from "../layers/PublicOffshoreMap";
import { LogoPublic } from "../components/Logo/Logo";
import { ControlPanelPublicMapMode } from "../components/ControlPanels/ControlPanelPublicMapMode";
import HasLoaded from "../components/HasLoaded/HasLoaded";
import PublicBranchSelector from "../components/PublicBranchSelector/PublicBranchSelector";
import Area from "../components/Area/Area";
import { pathParamsAtom, useTypedPath } from "../state/pathParams";
import styled from "styled-components";
import { Ui16Medium } from "../styles/typography";
import { colors } from "../styles/colors";
import { StandardBox } from "../styles/boxes/Boxes";
import { useParams } from "react-router-dom";
import { mapRefAtom } from "../state/map";
import { useGoToFeatures } from "../hooks/map";
import { onMobileState } from "../state/onMobile";
import { startZoomAreaKey } from "../constants/public";
import { PARK_PROPERTY_TYPE } from "../state/park";
import SyncLayersWithSelectedLayers from "../components/LayerList/SyncLayersWithSelectedLayers";
import SearchExernalLayerModal from "../components/SearchExernalLayerModal/SearchExernalLayerModal";

const Name = styled(Ui16Medium)<{ highlight: boolean; name: string }>`
  margin: 0;
  font-weight: ${(p) => (p.highlight ? 600 : 400)};
  cursor: pointer;
  border-radius: 0.6rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: inherit;

  :after {
    display: block;
    content: attr(name);
    font-weight: 600;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`;

const NavWrapper = styled.div<{ highlight?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) => (p.highlight ? colors.blueDark : "inherit")};
  color: ${(p) => (p.highlight ? colors.grayLight : "inherit")};
  cursor: pointer;
  border-radius: 0.4rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: ${(p) => (p.highlight ? colors.grayLight : colors.black)};
  }
  padding: 0 1.5rem 0 1.5rem;
  gap: 1rem;

  svg {
    height: 2.4rem;
    width: 2.4rem;
    path {
      stroke: ${(p) => (p.highlight ? colors.grayLight : colors.blueDark)};
    }
    rect {
      stroke: ${(p) => (p.highlight ? colors.grayLight : colors.blueDark)};
    }
    circle {
      stroke: ${(p) => (p.highlight ? colors.grayLight : colors.blueDark)};
    }
  }
`;

const Container = styled(StandardBox)`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 5rem;
`;

const NavigationContainer = styled.div`
  position: absolute;
  top: 8px;
  transition: top 0.2s linear;
  left: 8px;

  display: flex;
  align-items: center;
  gap: 0.8rem;
  z-index: 3;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 180px;
  gap: 0.8rem;
  justify-content: center;
  position: relative;
  margin-left: 0.4rem;
`;

const ProjectName = () => {
  const { projectId, customerId } = useTypedPath("projectId", "customerId");

  const getPublicProjectMetadata = useRecoilValueLoadable(
    publicNodeInfoSelectorFamily({ projectId, customerId })
  );

  const title =
    getPublicProjectMetadata.state !== "hasValue"
      ? ""
      : getPublicProjectMetadata.contents.name;

  return (
    <NavigationContainer>
      <LogoPublic />
      <Container>
        <NavWrapper>
          <NameContainer>
            <Name style={{ cursor: "inherit" }} name={title} highlight={true}>
              {title}
            </Name>
          </NameContainer>
        </NavWrapper>
      </Container>
    </NavigationContainer>
  );
};

const useSetPathParams = () => {
  const params = useParams();
  const setPathParams = useSetRecoilState(pathParamsAtom);
  useEffect(() => {
    setPathParams(params);
  }, [params, setPathParams]);
};

const ZoomToFeatures = () => {
  const { projectId, customerId, branchId } = useTypedPath(
    "projectId",
    "customerId",
    "branchId"
  );
  const publicProjectSettings = useRecoilValue(
    publicProjectSettingsSelectorFamily({ customerId, projectId })
  );
  const features = useRecoilValue(
    getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
  );
  const map = useRecoilValue(mapRefAtom);
  const goToFeatures = useGoToFeatures(map);
  const [complete, setComplete] = useState(false);
  const startZoomArea = useMemo(
    () => publicProjectSettings[startZoomAreaKey] ?? [],
    [publicProjectSettings]
  );

  useEffect(() => {
    if (!map || complete) return;

    if (startZoomArea.length === 0 && features.length === 0) {
      setComplete(true);
      return;
    }

    setTimeout(() => {
      if (startZoomArea.length === 2) {
        map.fitBounds(startZoomArea);
        setComplete(true);
        return;
      }
      const parkOrOtherFeatures = features.filter(
        (f) => !f.properties.type || f.properties.type === PARK_PROPERTY_TYPE
      );
      goToFeatures(parkOrOtherFeatures);
      setComplete(true);
    }, 50); // avoid race condition on initialization
  }, [goToFeatures, map, features, complete, startZoomArea]);

  return <></>;
};

const PublicMap = () => {
  useSetPathParams();
  const onMobile = useRecoilValue(onMobileState);

  return (
    <>
      <SyncLayersWithSelectedLayers />
      <PublicOffshoreMap />
      <Suspense fallback={null}>
        <ZoomToFeatures />
        <ProjectName />
        <RightSidePublic />
        {!onMobile && <Distance />}
        {!onMobile && <Area />}
        {!onMobile && <ControlPanelPublicMapMode />}
        <React.Suspense fallback={null}>
          <KeepSelectionInSyncWithPathParameters />
          <PublicModeGreetingsModal />
          <SearchExernalLayerModal />
        </React.Suspense>
        <PublicBranchSelector />
        <HasLoaded />
      </Suspense>
    </>
  );
};

export default PublicMap;
