import { ReactNode, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { colors } from "../../../styles/colors";
import { DropDownItems } from "./DropdownItems";
import { ReactComponent as Chevron } from "../../../icons/24/ArrowRight.svg";
import { Ui14Medium, Ui16Bold } from "../../../styles/typography";

const DropDownContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const DropDownButton = styled.button<{ open: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.6rem;
  padding: 0.7rem 1.1remxp;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${colors.grayLight};
  border-radius: 6px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export const ButtonText = styled(Ui16Bold)`
  color: ${colors.blueDark};
  white-space: nowrap;
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
  text-align: left;
`;

export const ButtonTextSmall = styled(Ui14Medium)`
  color: ${colors.blueDark};
  white-space: nowrap;
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
  text-align: left;
`;

const ChevronWrapper = styled.div<{
  open: boolean;
  position?: "top" | "bottom";
}>`
  transform: rotate(
    ${(p) => (p.open ? (p.position === "bottom" ? "90deg" : "-90deg") : "0deg")}
  );
  transition: 0.1s;
  display: flex;
  align-items: center;
  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export type DropDownItem = {
  value: string;
  name: string;
  info?: string;
  disabled?: boolean;
  icon?: ReactNode;
  renderItem?: () => ReactNode;
};

export default function DropdownButton({
  items,
  onSelectItem,
  selectedItemValue,
  position = "bottom",
  size = "large",
  disabled,
  buttonText,
  renderText,
  actionItems = [],
}: {
  items: DropDownItem[];
  onSelectItem: (val: string) => void;
  buttonText: string;
  renderText?: (text: string) => ReactNode;
  position?: "top" | "bottom";
  size?: "large" | "small";
  selectedItemValue?: string;
  disabled?: boolean;
  actionItems?: DropDownItem[];
}) {
  const [_isOpen, setIsOpen] = useState(false);

  const onOptionClicked = useCallback(
    (item: DropDownItem) => {
      onSelectItem(item.value);
      setIsOpen(false);
    },
    [onSelectItem]
  );

  const menuWrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(menuWrapperRef.current, () => {
    setIsOpen(false);
  });
  return (
    <div
      ref={menuWrapperRef}
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    >
      <DropDownContainer>
        <DropDownButton
          open={_isOpen}
          disabled={disabled}
          onClick={() => setIsOpen(!_isOpen)}
        >
          {renderText ? (
            renderText(buttonText)
          ) : size === "large" ? (
            <ButtonText>{buttonText}</ButtonText>
          ) : (
            <ButtonTextSmall>{buttonText}</ButtonTextSmall>
          )}
          <ChevronWrapper open={_isOpen} position={position}>
            <Chevron />
          </ChevronWrapper>
        </DropDownButton>
        <DropDownItems
          isOpen={_isOpen}
          items={items}
          size={size}
          selectedOption={selectedItemValue}
          onOptionClicked={onOptionClicked}
          position={position}
          actionItems={actionItems}
        />
      </DropDownContainer>
    </div>
  );
}
