import { pathParamsAtom } from "./pathParams";
import { atom, selector, selectorFamily } from "recoil";
import { canvasLayerFeatureHiddenAtom } from "./projectLayers";
import { ProjectFeature } from "../services/types";
import { Point, Polygon } from "@turf/turf";
import { pointInPolygon } from "../utils/geometry";
import { getParkFeatureSelectorFamily, getParkFeaturesSelector } from "./park";
import * as turf from "@turf/turf";
import { getPublicBranchDataAtomFamily } from "./projectAPI";

export const TURBINE_PROPERTY_TYPE = "park-layout-turbine";
export const ANCHOR_PROPERTY_TYPE = "anchor";
export const MOORING_LINE_PROPERTY_TYPE = "mooring-line";
export const MAX_DISTANCE_NEIGHBOUR_TURBINE = 20;

export type TurbineFeature = ProjectFeature<
  Point,
  {
    type: typeof TURBINE_PROPERTY_TYPE;
    turbineTypeId: string;
  }
>;
export const isTurbine = (f: ProjectFeature | undefined): f is TurbineFeature =>
  f?.properties?.type === TURBINE_PROPERTY_TYPE;

export type TurbinesFeatureCollection = {
  type: "FeatureCollection";
  id: string;
  geometry: {
    type: "FeatureCollection";
  };
  properties: {
    name?: string;
    color?: string;
  };
  features: TurbineFeature[];
};

export const selectedInclusionZoneIdsAtom = atom<string[]>({
  key: "selectedInclusionZoneIdsAtom",
  default: [],
});

export const getAllTurbinesSelector = selector<TurbineFeature[]>({
  key: "getAllTurbinesSelector",
  get: async ({ get }) => {
    const { projectId, customerId, branchId } = get(pathParamsAtom);
    if (!customerId || !projectId || !branchId) return [];
    const publicBranchFeatures = get(
      getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
    );
    const canvasLayerFeatureHidden = get(canvasLayerFeatureHiddenAtom);
    return (
      publicBranchFeatures.filter(
        (f) => f.properties.type === TURBINE_PROPERTY_TYPE
      ) as TurbineFeature[]
    ).filter(
      (t) =>
        !t.properties.parentIds?.some((id) =>
          canvasLayerFeatureHidden.includes(id)
        )
    );
  },
});

export const getTurbinesSelectorFamily = selectorFamily<
  TurbineFeature[],
  { parkId: string }
>({
  key: "getTurbinesSelectorFamily",
  get:
    ({ parkId }) =>
    async ({ get }) =>
      get(getAllTurbinesSelector).filter((f) =>
        f.properties?.parentIds?.includes(parkId)
      ),
});

export const getTurbinesFeatureCollectionSelectorFamily = selectorFamily<
  TurbinesFeatureCollection,
  { parkId: string }
>({
  key: "getTurbinesFeatureCollectionSelectorFamily",
  get:
    ({ parkId }) =>
    async ({ get }) => {
      const turbines = get(getTurbinesSelectorFamily({ parkId }));
      return {
        type: "FeatureCollection" as const,
        id: parkId,
        properties: {},
        features: turbines,
        geometry: { type: "FeatureCollection" },
      };
    },
});

export const getSurroundingTurbineFeaturesSelector = selectorFamily<
  TurbineFeature[],
  { parkId?: string }
>({
  key: "getSurroundingLayoutFeaturesSelector",
  get:
    ({ parkId }) =>
    async ({ get }) => {
      if (!parkId) return [];

      const { projectId, customerId, branchId } = get(pathParamsAtom);
      if (!customerId || !projectId || !branchId) return [];
      const publicBranchFeatures = get(
        getPublicBranchDataAtomFamily({ projectId, customerId, branchId })
      );
      const park = get(getParkFeatureSelectorFamily({ parkId }));
      const turbines = get(
        getTurbinesFeatureCollectionSelectorFamily({ parkId })
      );
      if (!park || !turbines) return [];
      const paddedPark = turf.buffer(park, MAX_DISTANCE_NEIGHBOUR_TURBINE);

      const allParks = get(getParkFeaturesSelector);
      const otherParks = allParks.filter((p) => p.id !== park.id);

      const closeParks = otherParks.filter((p) =>
        turf.intersect(paddedPark, p)
      );

      const closeLayouts = closeParks
        .map((p) =>
          get(getTurbinesFeatureCollectionSelectorFamily({ parkId: p.id }))
        )
        .filter((p) => p != null);

      const closeTurbines = closeLayouts
        .flatMap((layout) => layout.features)
        .filter((t) => turf.inside(t, paddedPark));

      const selectedInclusionZoneIds = get(selectedInclusionZoneIdsAtom);
      if (selectedInclusionZoneIds.length !== 0) {
        const chosenInclusionZones = publicBranchFeatures.filter((p) =>
          selectedInclusionZoneIds.includes(p.id)
        );
        const layout = get(
          getTurbinesFeatureCollectionSelectorFamily({ parkId: park.id })
        );
        const turbineFeaturesOutsideInclusionZones = layout.features.filter(
          (feature) =>
            !chosenInclusionZones.some((e) =>
              pointInPolygon(feature.geometry, e.geometry as Polygon)
            )
        );
        return [...closeTurbines, ...turbineFeaturesOutsideInclusionZones];
      }

      return closeTurbines;
    },
});
