import React, { useCallback, useMemo } from "react";
import Tiles from "../../icons/tiles/tiles.png";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  SourceName,
  libraryLayersOpenAtom,
  publicProjectLayersPerSourceSelectorFamily,
  selectedDynamicLayersAtom,
  selectedSourceNameAtom,
} from "../../state/layer";
import Spinner from "../../icons/spinner/Spinner";
import { ReactComponent as Linestring } from "../../icons/24/Line.svg";
import { ReactComponent as Polygon } from "../../icons/24/Square.svg";
import { ReactComponent as Point } from "../../icons/24/Pin.svg";
import { ReactComponent as Globe } from "../../icons/24/Globe.svg";
import ToggleableList from "../ToggleableList/ToggleableList";
import {
  ActionIcons,
  ContentWrapper,
  IconWithName,
  RowEyeIcon,
  RowName,
  RowWrapper,
  SubHeader,
  Wrapper,
  Center,
  LanguageSwitchWrapper,
  RowSearchIcon,
} from "./LayerList.style";

import { toastMessagesAtom } from "../../state/toast";
import { useTypedPath } from "../../state/pathParams";
import { SkeletonBlock } from "../../styles/skeleton";
import { PublicGisLayerCommon, SourceTypesLayer } from "../../types/gisData";
import Button from "../General/Button";
import { modalTypeOpenAtom } from "../../state/modal";
import { SearchExernalLayerModalType } from "../SearchExernalLayerModal/SearchExernalLayerModal";
import { publicProjectSettingsSelectorFamily } from "../../state/projectAPI";

const layerIcon = (layer, selected) => {
  switch (layer.type) {
    case "point":
    case "circle":
      return (
        <div>
          <Point
            width={"1.6rem"}
            style={{ fillOpacity: selected ? "1.0" : undefined }}
          />
        </div>
      );
    case "line":
      return (
        <div>
          <Linestring
            width={"1.6rem"}
            style={{ fillOpacity: selected ? "1.0" : undefined }}
          />
        </div>
      );
    case "polygon":
    case "featureCollection":
      return (
        <div>
          <Polygon
            width={"1.6rem"}
            style={{ fillOpacity: selected ? "1.0" : undefined }}
          />
        </div>
      );
    case "wms":
      return (
        <img
          width="16px"
          height="16px"
          src={Tiles}
          alt="layer type icon"
          style={{ opacity: selected ? "1.0" : undefined }}
        />
      );
    default:
      return;
  }
};

const LayerName = ({ layer }: { layer: PublicGisLayerCommon }) => {
  const { customerId, projectId } = useTypedPath("customerId", "projectId");
  const publicProjectSettings = useRecoilValue(
    publicProjectSettingsSelectorFamily({ customerId, projectId })
  );
  const renamedLayers =
    publicProjectSettings.public_collections_renamed_layers ?? {};

  return <RowName>{renamedLayers[layer.id] ?? layer.name}</RowName>;
};

const DynamicLayerRow = ({
  layer,
  search,
}: {
  layer: PublicGisLayerCommon;
  search?: boolean;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const { projectId } = useTypedPath("projectId");
  const [selectedDynamicLayers, setSelectedDynamicLayersAtom] = useRecoilState(
    selectedDynamicLayersAtom(projectId)
  );
  const selected = useMemo(
    () => !!selectedDynamicLayers.find((sl) => sl.name === layer.name),
    [selectedDynamicLayers, layer]
  );

  const setToastMessages = useSetRecoilState(toastMessagesAtom);

  const onClick = useCallback(() => {
    if (layer.type === "wms" && !selected) {
      setToastMessages((tm) => [
        ...tm,
        {
          text: "Right click on the layer in the map to see more information",
          timeout: 4000,
          id: layer.name,
        },
      ]);
    }

    setSelectedDynamicLayersAtom(
      selected
        ? selectedDynamicLayers.filter((sl) => sl.name !== layer.name)
        : [...selectedDynamicLayers, layer]
    );
  }, [
    layer,
    selectedDynamicLayers,
    selected,
    setSelectedDynamicLayersAtom,
    setToastMessages,
  ]);

  return (
    <RowWrapper>
      <IconWithName onClick={onClick}>
        {layerIcon(layer, selected)}
        <LayerName layer={layer} />
      </IconWithName>
      <ActionIcons>
        <RowEyeIcon
          title={"Show layer"}
          selected={selected}
          onClick={onClick}
        />
        {search &&
          [
            SourceTypesLayer.hosted,
            SourceTypesLayer.wfs,
            SourceTypesLayer.arcgis,
          ].includes(layer.sourceType) && (
            <RowSearchIcon
              onClick={() =>
                setModalTypeOpen({
                  modalType: SearchExernalLayerModalType,
                  metadata: layer,
                })
              }
              title={"Search layer"}
            />
          )}
      </ActionIcons>
    </RowWrapper>
  );
};

const AllLayers = ({ layers }: { layers: PublicGisLayerCommon[] }) => {
  const { projectId } = useTypedPath("projectId");
  const [selectedDynamicLayers, setSelectedDynamicLayersAtom] = useRecoilState(
    selectedDynamicLayersAtom(projectId)
  );

  const allLayersInSourceAreSelected = useMemo(() => {
    return layers.every((l) =>
      selectedDynamicLayers.find((sl) => sl.id === l.id)
    );
  }, [selectedDynamicLayers, layers]);

  const setAllLayersToSelected = useCallback(() => {
    const layersToMakeSelected = layers.filter((l) => {
      return !selectedDynamicLayers.find((sl) => sl.name === l.name);
    });

    setSelectedDynamicLayersAtom([
      ...selectedDynamicLayers,
      ...layersToMakeSelected,
    ]);
  }, [layers, selectedDynamicLayers, setSelectedDynamicLayersAtom]);

  const setAllLayersToUnselected = useCallback(() => {
    const layersToUnselect = layers.filter((l) => {
      return selectedDynamicLayers.find((sl) => sl.name === l.name);
    });

    const layerIsNotInList = (layer, listOfLayers) => {
      const layerIsInList = listOfLayers.find((l) => l.name === layer.name);
      return !layerIsInList;
    };

    setSelectedDynamicLayersAtom(
      selectedDynamicLayers.filter((sl) =>
        layerIsNotInList(sl, layersToUnselect)
      )
    );
  }, [layers, selectedDynamicLayers, setSelectedDynamicLayersAtom]);

  return (
    <>
      <RowWrapper>
        <IconWithName onClick={setAllLayersToSelected}>
          <RowName>
            <i>All layers</i>
          </RowName>
        </IconWithName>
        <ActionIcons>
          <RowEyeIcon
            selected={allLayersInSourceAreSelected}
            onClick={() =>
              allLayersInSourceAreSelected
                ? setAllLayersToUnselected()
                : setAllLayersToSelected()
            }
          />
        </ActionIcons>
      </RowWrapper>
    </>
  );
};

const SourceLayers = ({
  layersInfo,
}: {
  layersInfo: PublicGisLayerCommon[];
}) => (
  <>
    <AllLayers layers={layersInfo} />
    {layersInfo.map((vl, i) => (
      <div key={"sourcelist" + i}>
        <DynamicLayerRow layer={vl} />
      </div>
    ))}
  </>
);

export const SourceLayerList = ({
  title,
  layersInfo,
}: {
  title: string;
  layersInfo: PublicGisLayerCommon[];
}) => {
  return (
    <ToggleableList title={title}>
      <React.Suspense fallback={<Spinner />}>
        <SourceLayers layersInfo={layersInfo} />
      </React.Suspense>
    </ToggleableList>
  );
};

const LibraryLayers = () => {
  const { customerId, projectId, branchId } = useTypedPath(
    "customerId",
    "projectId",
    "branchId"
  );

  const layers = useRecoilValue(
    publicProjectLayersPerSourceSelectorFamily({
      customerId,
      projectId,
      branchId,
    })
  );

  const [selectedSourceName, setSelectedSourceName] = useRecoilState(
    selectedSourceNameAtom
  );

  return (
    <>
      <SubHeader>Available layers</SubHeader>
      {Object.keys(layers).map((k) => (
        <SourceLayerList
          key={k}
          title={
            selectedSourceName === SourceName.Original
              ? layers[k][0].source.originalName ?? layers[k][0].source.name
              : layers[k][0].source.name
          }
          layersInfo={layers[k]}
        />
      ))}
      <LanguageSwitchWrapper>
        <Button
          style={{ width: "fit-content" }}
          text={selectedSourceName}
          icon={<Globe />}
          buttonType="secondary"
          onClick={() =>
            setSelectedSourceName((sn) =>
              sn === SourceName.Original
                ? SourceName.English
                : SourceName.Original
            )
          }
        />
      </LanguageSwitchWrapper>
    </>
  );
};

const SelectedLayers = () => {
  const { projectId } = useTypedPath("customerId", "projectId");
  const selectedDynamicLayers = useRecoilValue(
    selectedDynamicLayersAtom(projectId)
  );

  if (selectedDynamicLayers.length === 0) return null;

  return (
    <>
      <SubHeader>Selected layers</SubHeader>
      {selectedDynamicLayers.map((sl) => (
        <DynamicLayerRow key={sl.name} layer={sl} search={true} />
      ))}
    </>
  );
};

const LayerListPublicInner = ({ open }: { open: boolean }) => {
  return (
    <Wrapper open={open}>
      <ContentWrapper>
        <React.Suspense
          fallback={
            <Center>
              <SkeletonBlock style={{ height: "10px" }} />
            </Center>
          }
        >
          <SelectedLayers />
          <LibraryLayers />
        </React.Suspense>
      </ContentWrapper>
    </Wrapper>
  );
};

export const LayerListPublic = () => {
  const open = useRecoilValue(libraryLayersOpenAtom);

  return (
    <Wrapper open={open}>
      <React.Suspense
        fallback={
          <Center style={{ alignItems: "center" }}>
            <Spinner />
          </Center>
        }
      >
        <LayerListPublicInner open={open} />
      </React.Suspense>
    </Wrapper>
  );
};
