import { ReactNode, useRef } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { modalTypeOpenAtom } from "../../state/modal";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
`;

const FullScreenModal = ({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: ReactNode;
}) => {
  const setModalTypeOpen = useSetRecoilState(modalTypeOpenAtom);
  const wrapperRef = useRef<HTMLDivElement>(null);
  return (
    <Wrapper
      ref={wrapperRef}
      onMouseDown={(e) => {
        if (e.target !== wrapperRef.current) return;
        if (onClick) {
          onClick();
          return;
        }
        setModalTypeOpen(undefined);
      }}
    >
      {children}
    </Wrapper>
  );
};

export default FullScreenModal;
