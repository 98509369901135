import { ProjectFeature } from "../../services/types";

export const getCoordinates = (
  coordinates: number[] | number[][] | number[][][] | number[][][][]
) => {
  if (typeof coordinates[0] === "number") return [coordinates];
  if (typeof coordinates[0][0] === "undefined") return null;
  if (typeof coordinates[0][0] === "number") return coordinates;
  return getCoordinates(coordinates[0]);
};

export type BBOX = [number, number, number, number];

const minMaxFast = (array: number[]) => {
  let min = array[0],
    max = array[0];

  for (let i = 1; i < array.length; i++) {
    let value = array[i];
    min = value < min ? value : min;
    max = value > max ? value : max;
  }

  return [min, max];
};

export const getBBOXArray = (features: ProjectFeature[]): BBOX => {
  const coords = features.reduce(
    (acc, f) => [...acc, ...getCoordinates(f.geometry.coordinates)],
    [] as number[]
  );
  const xCoords = coords.map((c) => c[0]);
  const yCoords = coords.map((c) => c[1]);

  const [minX, maxX] = minMaxFast(xCoords);
  const [minY, maxY] = minMaxFast(yCoords);

  return [minX, minY, maxX, maxY];
};
