import { CirclePaint, Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { TurbineFeature } from "../../state/layout";
import Point from "./Point";

const turbinePaint: CirclePaint = {
  "circle-radius": [
    "interpolate",
    ["linear"],
    ["zoom"],
    // zoom is 5 (or less) -> circle radius will be 1px
    5,
    1,
    // zoom is 10 (or greater) -> circle radius will be 5px
    10,
    6,
  ],
  "circle-color": [
    "case",
    ["boolean", ["feature-state", "overlap"], false],
    "#ff0000",
    "#000000",
  ],
  "circle-opacity": [
    "case",
    ["boolean", ["feature-state", "selected"], false],
    0.0,
    1.0,
  ],
};

const TurbinePoint = ({
  features,
  sourceId,
  layerId,
  map,
  beforeId,
  onClickCallback,
  selectedIds,
}: {
  features: TurbineFeature[];
  sourceId: string;
  layerId: string;
  map: Map;
  beforeId?: string;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean
  ) => void;
  selectedIds?: (string | number)[];
}) => (
  <>
    <Point
      features={features}
      sourceId={sourceId}
      layerId={layerId}
      map={map}
      paint={turbinePaint}
      onClickCallback={onClickCallback}
      selectedIds={selectedIds}
      beforeId={beforeId}
    />
  </>
);

export default TurbinePoint;
