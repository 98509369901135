import { atom, selectorFamily } from "recoil";
import { CensoredTurbineType, DEFAULT_TURBINES } from "../types/turbines";
import { getParkFeatureSelectorFamily } from "./park";
import { fetchJson } from "../services/utils";
import { publicNodeInfoSelectorFamily } from "./projectAPI";

// export type Unit = "meter" | "diameter";
// export type Value = {
//   v: number;
//   unit: "meter" | "diameter";
// };

// export const meter = (m: number): Value => ({ v: m, unit: "meter" });
// export const diameter = (m: number): Value => ({ v: m, unit: "diameter" });
// export const value = (v: number, unit: Unit): Value => ({ v, unit });

export const DEFAULT_NOISE_PARAMS = { source: 110, red: 50, yellow: 40 };

export const showNoiseAtom = atom({
  key: "show-noise",
  default: false,
});

export type TurbineNoiseSettings = {
  source: number | string;
  red: number | string;
  yellow: number | string;
};
export const noiseLevelSelectorFamily = selectorFamily<
  TurbineNoiseSettings,
  { parkId: string }
>({
  key: "noiseLevelSelectorFamily",
  get:
    ({ parkId }) =>
    ({ get }) => {
      if (!parkId) return DEFAULT_NOISE_PARAMS;
      const park = get(getParkFeatureSelectorFamily({ parkId }));
      if (!park) return DEFAULT_NOISE_PARAMS;

      return park.properties?.noiseSettings ?? DEFAULT_NOISE_PARAMS;
    },
});

export const defaultTurbinesAtom = atom({
  key: "available-turbines",
  default: DEFAULT_TURBINES,
});

export const allTurbinesPublicSelector = selectorFamily<
  CensoredTurbineType[],
  { customerId: string; projectId: string; branchId: string }
>({
  key: "all-turbines-public-default-selector",
  get:
    ({ customerId, projectId, branchId }) =>
    async ({ get }) => {
      const defaultTurbines = get(defaultTurbinesAtom);
      const headers = {
        method: "get",
      };
      const node = get(publicNodeInfoSelectorFamily({ customerId, projectId }));
      const customTurbines = await fetchJson<CensoredTurbineType[]>(
        `/api/turbines/public/node/${node.id}/${branchId}`,
        headers
      );
      return [...defaultTurbines, ...customTurbines];
    },
});
