import md5 from "md5";
import { CUSTOM_LAYER_SOURCE } from "../constants/customLayers";
import { PublicGisLayerCommon } from "../types/gisData";

export const getExternalLayerId = (
  sourceUrl: string,
  layerName: string | undefined | null,
  sourceType: string
) => {
  if (!layerName || layerName === "") {
    layerName = "undefined";
  }
  const sourceForId = [sourceUrl, sourceType, layerName].join("::");
  return md5(sourceForId.replaceAll(" ", "").trim());
};

export const isCustomLayer = (layer): boolean => {
  return layer.source === CUSTOM_LAYER_SOURCE;
};

export const layerToSourceId = (
  layer: PublicGisLayerCommon,
  suffix: string
): string => {
  if (isCustomLayer(layer)) {
    return `${layer.id}${suffix}`;
  }
  return `${layer.source.name}-${layer.name}${suffix}`;
};
