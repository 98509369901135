import styled from "styled-components";
import { colors } from "./colors";

export const Ui20Regular = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: 0rem;

  display: flex;
  align-items: center;
  font-feature-settings: "cv01" on, "cv02" on, "cv03" on, "cv04" on, "cv05" on,
    "cv06" on, "cv10" on, "calt" on;

  color: ${colors.blueDark};
`;
export const Ui20Medium = styled(Ui20Regular)`
  font-weight: 500;
`;
export const Ui20Bold = styled(Ui20Regular)`
  font-weight: 600;
`;

export const Ui18Regular = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.6rem;
  letter-spacing: 0rem;

  display: flex;
  align-items: center;
  font-feature-settings: "cv01" on, "cv02" on, "cv03" on, "cv04" on, "cv05" on,
    "cv06" on, "cv10" on;

  color: ${colors.blueDark};
`;
export const Ui18Medium = styled(Ui18Regular)`
  font-weight: 500;
`;
export const Ui18Bold = styled(Ui18Regular)`
  font-weight: 600;
`;

export const Ui16Regular = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0rem;

  display: flex;
  align-items: center;
  font-feature-settings: "cv01" on, "cv02" on, "cv03" on, "cv04" on, "cv05" on,
    "cv06" on, "cv10" on;

  color: ${colors.blueDark};
`;
export const Ui16Medium = styled(Ui16Regular)`
  font-weight: 500;
`;
export const Ui16Bold = styled(Ui16Regular)`
  font-weight: 600;
`;

export const Ui14Regular = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0rem;

  display: flex;
  align-items: center;
  font-feature-settings: "cv01" on, "cv02" on, "cv03" on, "cv04" on, "cv05" on,
    "cv06" on, "cv10" on;

  color: ${colors.blueDark};
`;
export const Ui14Medium = styled(Ui14Regular)`
  font-weight: 500;
`;
export const Ui14Bold = styled(Ui14Regular)`
  font-weight: 600;
`;

export const Ui12Regular = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0rem;

  display: flex;
  align-items: center;
  font-feature-settings: "cv01" on, "cv02" on, "cv03" on, "cv04" on, "cv05" on,
    "cv06" on, "cv10" on;

  color: ${colors.blueDark};
`;
export const Ui12Medium = styled(Ui12Regular)`
  font-weight: 500;
`;
export const Ui12Bold = styled(Ui12Regular)`
  font-weight: 600;
`;

export const Ui10Regular = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.6rem;
  letter-spacing: 0rem;

  display: flex;
  align-items: center;
  font-feature-settings: "cv01" on, "cv02" on, "cv03" on, "cv04" on, "cv05" on,
    "cv06" on, "cv10" on;

  color: ${colors.blueDark};
`;
export const Ui10Medium = styled(Ui10Regular)`
  font-weight: 500;
`;

export const UiErrorText = styled(Ui10Medium)`
  margin: 0 0;
  color: red;
`;

export const Ui10Bold = styled(Ui10Regular)`
  font-weight: 600;
`;

export const typography = {
  buttonSmall: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
  },
  buttonMedium: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1.4rem",
    lineHeight: "1.7rem",
  },
  buttonLarge: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1.6rem",
    lineHeight: "2rem",
  },
};
