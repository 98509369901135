import { useCallback, useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mapRefAtom } from "../state/map";
import {
  dynamicLayersSelector,
  selectedDynamicCircleLayersSelectorFamily,
  visibleDynamicLayersAtom,
} from "../state/layer";
import { currentSelectionArrayAtom } from "../state/selection";
import { selectMethod } from "./canvasLayer";
import { addIdOnFeaturesIfNotUUID4 } from "../utils/geojson/utils";
import Point from "../components/MapFeatures/Point";
import { ProjectFeature } from "../services/types";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { useTypedPath } from "../state/pathParams";
import { filterFeatureCollectionAccordingToType } from "./utils";
import { layerToSourceId } from "../utils/externalLayers";
import { PublicVectorGisLayer, isHostedLayer } from "../types/gisData";

const POINT_TYPES = ["MultiPoint", "Point"];
export const CIRCLE_LAYERS_SUFFIX = "-circle";

const DynamicCircleLayers = () => {
  const { projectId } = useTypedPath("projectId");
  const selectedDynamicCircleLayers = useRecoilValue(
    selectedDynamicCircleLayersSelectorFamily(projectId)
  );

  return (
    <>
      {selectedDynamicCircleLayers.map((layer) => (
        <DynamicCircleLayer key={layer.name} layer={layer} />
      ))}
    </>
  );
};

const DynamicCircleLayer = ({ layer }: { layer: PublicVectorGisLayer }) => {
  const setVisibleDynamicLayers = useSetRecoilState(visibleDynamicLayersAtom);
  const rawData = useRecoilValue(dynamicLayersSelector({ layer }));
  const filteredGeojson = useMemo(
    () =>
      isHostedLayer(layer)
        ? filterFeatureCollectionAccordingToType(rawData, "Point")
        : rawData,
    [rawData, layer]
  );

  const features = useMemo(() => {
    if (layer.type === "circle")
      return addIdOnFeaturesIfNotUUID4(filteredGeojson.features);
    return addIdOnFeaturesIfNotUUID4(
      filteredGeojson.features.filter((f) =>
        POINT_TYPES.includes(f.geometry.type)
      )
    );
  }, [filteredGeojson, layer]) as ProjectFeature[];
  const map = useRecoilValue(mapRefAtom);

  const layerId = useMemo(
    () => `${layer.name}-${CIRCLE_LAYERS_SUFFIX}`,
    [layer]
  );
  const sourceId = useMemo(
    () => layerToSourceId(layer, CIRCLE_LAYERS_SUFFIX),
    [layer]
  );

  useEffect(() => {
    setVisibleDynamicLayers((vdl) => [...vdl, layerId]);
    return () =>
      setVisibleDynamicLayers((vdl) => vdl.filter((l) => l !== layerId));
  }, [setVisibleDynamicLayers, layerId]);

  const setCurrentSelectionArray = useSetRecoilState(currentSelectionArrayAtom);

  const onClickCallBack = useCallback(
    (features: MapboxGeoJSONFeature[], shiftClicked: boolean) => {
      selectMethod(shiftClicked, features, setCurrentSelectionArray);
    },
    [setCurrentSelectionArray]
  );

  const layerStyling = layer.layerSetting?.layerStyle;

  const color = useMemo(() => {
    if (layerStyling?.color) return layerStyling.color;
  }, [layerStyling]);

  const opacity = useMemo(() => {
    if (layerStyling?.opacity !== undefined) return layerStyling?.opacity;
    return 0.4;
  }, [layerStyling]);

  if (!map || features.length === 0) return null;
  return (
    <Point
      map={map}
      opacity={opacity}
      color={color}
      features={features}
      layerId={layerId}
      sourceId={sourceId}
      onClickCallback={onClickCallBack}
      zoomLevels={layerStyling?.zoomLevels}
    />
  );
};

export default DynamicCircleLayers;
