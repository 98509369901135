import { MapLayerMouseEvent } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  selectedDynamicTileJSONCircleLayersSelectorFamily,
  selectedDynamicTileJSONLineLayersSelectorFamily,
  selectedDynamicTileJSONPolygonLayersSelectorFamily,
} from "../state/layer";
import { mapInteractionSelector, mapRefAtom } from "../state/map";
import {
  currentSelectionArrayAtom,
  defaultMouseHandlerCallBackClickableFeature,
} from "../state/selection";
import { canvasPolygonLayerId, selectMethod } from "./canvasLayer";
import { useTypedPath } from "../state/pathParams";
import { PublicGisLayerTilejson } from "../types/gisData";

const PROMOTE_ID_OVERRIDES = "osm_id";

const dynamicTilePolygonToSourceId = (layer: PublicGisLayerTilejson) => {
  return `dynamic-tile-json-polygon-layer-source-${layer.sourceLayerId}`;
};

const dynamicTilePolygonToLayerId = (name: PublicGisLayerTilejson) => {
  return `dynamic-tile-json-polygon-layer-id-${name}`;
};

const DynamicTileJSONLayers = () => {
  const { projectId } = useTypedPath("projectId");
  const selectedDynamicTileJSONPolygonLayers = useRecoilValue(
    selectedDynamicTileJSONPolygonLayersSelectorFamily(projectId)
  );
  const selectedDynamicTileJSONCircleLayers = useRecoilValue(
    selectedDynamicTileJSONCircleLayersSelectorFamily(projectId)
  );
  const selectedDynamicTileJSONLineLayers = useRecoilValue(
    selectedDynamicTileJSONLineLayersSelectorFamily(projectId)
  );

  return (
    <>
      {selectedDynamicTileJSONLineLayers.map((layer) => (
        <DynamicTileJSONLineLayer key={layer.name} layer={layer} />
      ))}
      {selectedDynamicTileJSONCircleLayers.map((layer) => (
        <DynamicTileJSONCircleLayer key={layer.name} layer={layer} />
      ))}
      {selectedDynamicTileJSONPolygonLayers.map((layer) => (
        <DynamicTileJSONPolygonLayer key={layer.name} layer={layer} />
      ))}
    </>
  );
};

const DynamicTileJSONCircleLayer = ({
  layer,
}: {
  layer: PublicGisLayerTilejson;
}) => {
  const dynamicTileJSONLayersId = useMemo(
    () => `dynamic-tile-json-layer-id-${layer.sourceLayerId}`,
    [layer]
  );
  const dynamicTileJSONLayersSource = useMemo(
    () => `dynamic-tile-json-layer-source-${layer.sourceLayerId}`,
    [layer]
  );
  const dynamicLayer = useMemo(
    () => ({
      maxzoom: 20,
      minzoom: 2,
      type: "circle",
      "source-layer": layer.sourceLayerId,
      paint: {
        "circle-color": "#27AE60",
        "circle-radius": 4,
        "circle-opacity": [
          "case",
          [
            "boolean",
            ["feature-state", "hover"],
            ["feature-state", "selected"],
            false,
          ],
          1,
          0.6,
        ],
      },
      id: dynamicTileJSONLayersId,
      source: dynamicTileJSONLayersSource,
    }),
    [dynamicTileJSONLayersId, layer.sourceLayerId, dynamicTileJSONLayersSource]
  );

  return <DynamicTileJSONLayer layer={layer} layerStyle={dynamicLayer} />;
};

const DynamicTileJSONPolygonLayer = ({ layer }) => {
  const dynamicTileJSONPolygonLayersId = useMemo(
    () => dynamicTilePolygonToLayerId(layer.id),
    [layer]
  );
  const dynamicTileJSONLayersSourceId = useMemo(
    () => dynamicTilePolygonToSourceId(layer),
    [layer]
  );
  const dynamicLayer = useMemo(
    () => ({
      maxzoom: 20,
      minzoom: 2,
      id: dynamicTileJSONPolygonLayersId,
      type: "fill",
      source: dynamicTileJSONLayersSourceId,
      layout: {},
      "source-layer": layer.sourceLayerId,
      paint: {
        "fill-color": layer.layerSetting.layerStyle?.color ?? "#77bb77",
        "fill-opacity": [
          "case",
          [
            "boolean",
            ["feature-state", "hover"],
            ["feature-state", "selected"],
            false,
          ],
          layer.layerSetting.layerStyle?.opacity
            ? layer.layerSetting.layerStyle?.opacity + 0.2
            : 1,
          layer.layerSetting.layerStyle?.opacity ?? 0.4,
        ],
      },
    }),
    [layer, dynamicTileJSONPolygonLayersId, dynamicTileJSONLayersSourceId]
  );

  return <DynamicTileJSONLayer layer={layer} layerStyle={dynamicLayer} />;
};

const DynamicTileJSONLineLayer = ({
  layer,
}: {
  layer: PublicGisLayerTilejson;
}) => {
  const dynamicTileJSONLineLayersId = useMemo(
    () => `dynamic-tile-json-line-layer-id-${layer.sourceLayerId}`,
    [layer]
  );
  const dynamicTileJSONLayersSource = useMemo(
    () => `dynamic-tile-json-line-layer-source-${layer.sourceLayerId}`,
    [layer]
  );
  const dynamicLayer = useMemo(
    () => ({
      maxzoom: 20,
      minzoom: 2,
      id: dynamicTileJSONLineLayersId,
      type: "line",
      source: dynamicTileJSONLayersSource,
      "source-layer": layer.sourceLayerId,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": layer.layerSetting?.layerStyle?.color ?? "#000000",
        "line-width": [
          "case",
          [
            "boolean",
            ["feature-state", "hover"],
            ["feature-state", "selected"],
            false,
          ],
          5,
          5,
        ],
        "line-opacity": [
          "case",
          [
            "boolean",
            ["feature-state", "hover"],
            ["feature-state", "selected"],
            false,
          ],
          layer.layerSetting?.layerStyle?.opacity
            ? layer.layerSetting?.layerStyle?.opacity + 0.2
            : 1,
          layer.layerSetting?.layerStyle?.opacity ?? 0.4,
        ],
      },
    }),
    [dynamicTileJSONLineLayersId, dynamicTileJSONLayersSource, layer]
  );

  return <DynamicTileJSONLayer layer={layer} layerStyle={dynamicLayer} />;
};

const DynamicTileJSONLayer = ({
  layer,
  layerStyle,
}: {
  layer: PublicGisLayerTilejson;
  layerStyle: any;
}) => {
  const map = useRecoilValue(mapRefAtom);
  const mapInteraction = useRecoilValue(mapInteractionSelector);
  const setLayerMouseHandling = useSetRecoilState(
    defaultMouseHandlerCallBackClickableFeature
  );
  const [currentSelectionArray, setCurrentSelectionArray] = useRecoilState(
    currentSelectionArrayAtom
  );

  useEffect(() => {
    if (!map) return;
    map.addSource(layerStyle.source, {
      type: "vector",
      url: layer.endpoint.url,
      promoteId: PROMOTE_ID_OVERRIDES,
    });
    map.addLayer(
      layerStyle,
      map.getLayer(canvasPolygonLayerId) ? canvasPolygonLayerId : undefined
    );
    return () => {
      map.removeLayer(layerStyle.id);
      map.removeSource(layerStyle.source);
    };
  }, [layer, map, layerStyle]);

  useEffect(() => {
    if (!map) return;

    const selection = currentSelectionArray.filter(
      (cs) => cs.layer?.id === layerStyle.id
    );

    selection.forEach((s) =>
      map.setFeatureState(
        { source: s.source, id: s.id, sourceLayer: s.sourceLayer },
        { selected: true }
      )
    );
    return () => {
      selection.forEach((s) =>
        map.removeFeatureState(
          {
            source: s.source,
            id: s.id,
            sourceLayer: s.sourceLayer,
          },
          "selected"
        )
      );
    };
  }, [currentSelectionArray, map, layerStyle, layer.name, layer.type]);

  useEffect(() => {
    if (!map || !map.getSource(layerStyle.source)) return;

    const onClick = (e: MapLayerMouseEvent) => {
      e.preventDefault();
      if (!mapInteraction.hover) return;
      const { ctrlKey, metaKey, shiftKey, altKey } = e.originalEvent;
      const append = ctrlKey || metaKey || shiftKey || altKey;
      selectMethod(append, e.features, setCurrentSelectionArray);
    };

    setLayerMouseHandling((l) => ({
      ...l,
      [layerStyle.id]: {
        onClick: onClick,
      },
    }));

    return () => {
      setLayerMouseHandling((l) => {
        const cleanedL = { ...l };
        delete cleanedL[layerStyle.id];
        return cleanedL;
      });
    };
  }, [
    map,
    mapInteraction,
    layerStyle,
    setCurrentSelectionArray,
    setLayerMouseHandling,
  ]);

  return null;
};

export default DynamicTileJSONLayers;
