import styled from "styled-components";

export const Container = styled.div`
  && {
    pointer-events: none;
  }
  & > * {
    pointer-events: auto;
  }
`;
