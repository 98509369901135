import mapboxgl from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { getTurbinesSelectorFamily } from "../state/layout";
import { mapRefAtom } from "../state/map";
import {
  showNoiseAtom,
  noiseLevelSelectorFamily,
  TurbineNoiseSettings,
} from "../state/turbines";
import { useTypedPath } from "../state/pathParams";

class NoiseLayer {
  id: string;
  type: string;
  source: string;
  points: number[];
  scale: number;
  db: number | string;
  red: number | string;
  yellow: number | string;
  turbineLocations: number[];
  program: WebGLProgram;
  aPos: GLint;
  buffer: WebGLBuffer;
  scaleLocation: WebGLUniformLocation;
  dbLocation: WebGLUniformLocation;
  redLocation: WebGLUniformLocation;
  yellowLocation: WebGLUniformLocation;
  numberLocation: WebGLUniformLocation;
  turbinesLocation: WebGLUniformLocation;
  constructor(
    points: number[],
    scale: number,
    turbineLocations: number[],
    noiseLevel: TurbineNoiseSettings
  ) {
    this.id = "noise";
    this.type = "custom";
    this.source = "turbines";
    this.points = points;
    this.scale = scale;
    this.db = noiseLevel.source;
    this.red = noiseLevel.red;
    this.yellow = noiseLevel.yellow;
    this.turbineLocations = turbineLocations;
  }
  onAdd(map, gl) {
    // create GLSL source for vertex shader
    const vertexSource = `
              precision highp float; 
              uniform mat4 u_matrix;
              attribute vec2 a_pos;
              varying vec2 cc;
              void main() {
                  cc = a_pos;
                  gl_Position = u_matrix * vec4(a_pos, 0.0, 1.0);
              }`;

    // create GLSL source for fragment shader
    const fragmentSource = `
              precision highp float;
              const int max_turbines = 1000;

              varying vec2 cc;
              uniform int u_number_of_turbines;
              uniform vec2 u_turbines[max_turbines];
              uniform float u_scale;
              uniform float u_db;
              uniform float u_red;
              uniform float u_yellow;

              float a = 1. / log(10.);

              float log10(float x) {
                return a * log(x);
              }

              float Noise(vec2 turbine, vec2 point) {
                vec2 vector = (point - turbine) / u_scale;
                float distance = length(vector);
                float geometrical_divergence = 10. * log10(4. * 3.1415 * distance * distance);
                float atmospheric_absorption = 0.4 * distance / 1000.;
                float value = u_db - geometrical_divergence - atmospheric_absorption;
                return pow(10., value / 10.);
              }

              void main() {
                float noise = 0.;
                for(int i=0;i<max_turbines;i++) {
                  noise += Noise(u_turbines[i], cc);
                  if (i >= u_number_of_turbines) break;
                }
                float total = 10.0 * log10(noise);
                bool red_zone = u_red < total;
                bool yellow_zone = u_yellow < total;

                vec3 color = vec3(0., 0., 0.);
                float alpha = 0.;
                if ( red_zone ) {
                  color = vec3(1., 0., 0.);
                  alpha = .6;
                } else if ( yellow_zone ) {
                  color = vec3(1.,1.,0.);
                  alpha = .6;
                }
                gl_FragColor = vec4(color, alpha);
              }`;

    // create a vertex shader
    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);

    // create a fragment shader
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);

    // link the two shaders into a WebGL program
    this.program = gl.createProgram();
    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);

    this.aPos = gl.getAttribLocation(this.program, "a_pos");
    // create and initialize a WebGLBuffer to store vertex and color data
    this.buffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array(this.points),
      gl.STATIC_DRAW
    );

    this.scaleLocation = gl.getUniformLocation(this.program, "u_scale");
    this.dbLocation = gl.getUniformLocation(this.program, "u_db");
    this.redLocation = gl.getUniformLocation(this.program, "u_red");
    this.yellowLocation = gl.getUniformLocation(this.program, "u_yellow");
    this.numberLocation = gl.getUniformLocation(
      this.program,
      "u_number_of_turbines"
    );
    this.turbinesLocation = gl.getUniformLocation(this.program, "u_turbines");
  }
  render(gl, matrix) {
    gl.useProgram(this.program);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    gl.uniformMatrix4fv(
      gl.getUniformLocation(this.program, "u_matrix"),
      false,
      matrix
    );

    gl.uniform1f(this.scaleLocation, this.scale);
    gl.uniform1f(this.dbLocation, this.db);
    gl.uniform1f(this.redLocation, this.red);
    gl.uniform1f(this.yellowLocation, this.yellow);
    gl.uniform1i(this.numberLocation, this.turbineLocations.length);
    gl.uniform2fv(this.turbinesLocation, this.turbineLocations);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
    gl.enableVertexAttribArray(this.aPos);
    gl.vertexAttribPointer(this.aPos, 2, gl.FLOAT, false, 0, 0);

    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
  }
}

const Noise = () => {
  const showNoise = useRecoilValue(showNoiseAtom);
  if (!showNoise) return null;
  return <NoiseActive />;
};

const NoiseActive = () => {
  const { parkId } = useTypedPath("parkId");
  const features = useRecoilValue(getTurbinesSelectorFamily({ parkId }));

  const noiseLevel = useRecoilValue(noiseLevelSelectorFamily({ parkId }));
  const map = useRecoilValue(mapRefAtom);

  const noiseLayer = useMemo(() => {
    const turbineLonlats = features.map((f) => f.geometry.coordinates);
    if (turbineLonlats.length === 0) return null;
    const turbinePoints = turbineLonlats.map((v) =>
      mapboxgl.MercatorCoordinate.fromLngLat({
        lng: v[0],
        lat: v[1],
      })
    );
    const longitudes = turbineLonlats.map((c) => c[0]);
    const latitudes = turbineLonlats.map((c) => c[1]);

    const buffer = 0.5;
    const lonMin = Math.min(...longitudes);
    const latMin = Math.min(...latitudes);
    const lonMax = Math.max(...longitudes);
    const latMax = Math.max(...latitudes);
    const bbox = [
      lonMin - buffer,
      latMin - buffer,
      lonMax + buffer,
      latMax + buffer,
    ];
    const centerCoord = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: (lonMin + lonMax) / 2,
      lat: (latMin + latMax) / 2,
    });
    const lowerLeft = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[0],
      lat: bbox[1],
    });
    const lowerRight = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[2],
      lat: bbox[1],
    });
    const upperRight = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[2],
      lat: bbox[3],
    });
    const upperLeft = mapboxgl.MercatorCoordinate.fromLngLat({
      lng: bbox[0],
      lat: bbox[3],
    });

    const scale = centerCoord.meterInMercatorCoordinateUnits();

    const turbineLocations = turbinePoints.flatMap((v) => [v.x, v.y]);

    const points = [
      lowerLeft.x,
      lowerLeft.y,
      lowerRight.x,
      lowerRight.y,
      upperRight.x,
      upperRight.y,
      upperLeft.x,
      upperLeft.y,
    ];
    return new NoiseLayer(points, scale, turbineLocations, noiseLevel);
  }, [features, noiseLevel]);

  useEffect(() => {
    if (!map || !noiseLayer) return;
    noiseLayer.db = noiseLevel.source;
    noiseLayer.red = noiseLevel.red;
    noiseLayer.yellow = noiseLevel.yellow;
  }, [noiseLevel, map, noiseLayer]);

  useEffect(() => {
    if (!map || !noiseLayer) return;
    map.addLayer(noiseLayer as any);

    return () => {
      map.removeLayer(noiseLayer.id);
    };
  }, [map, noiseLayer]);

  return null;
};

export default Noise;
