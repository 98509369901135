import { Map } from "mapbox-gl";
import { atom, selector } from "recoil";
import { leftMenuModeActiveAtom } from "./filter";
import { modalTypeOpenAtom } from "./modal";
import { ProjectElementMenuType } from "../components/ProjectElements/ProjectElements";
import { newCustomDataSourceAtom } from "./layer";

export const mapRefAtom = atom<undefined | Map>({
  key: "mapRefAtom",
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const mapControlsAtom = atom<undefined | MapboxDraw>({
  key: "mapControlsAtom",
  default: undefined,
  dangerouslyAllowMutability: true,
});

export const editFeatureAtom = atom<string | number | undefined>({
  key: "editFeatureAtom",
  default: undefined,
});

export const addFeatureAtom = atom<
  | undefined
  | {
      mode: MapboxDraw.DrawMode;
      options?: { type?: string; color?: string; source?: string };
    }
>({
  key: "addFeatureAtom",
  default: undefined,
});

const leftMenuAllowedInteraction = [ProjectElementMenuType];

export const mapInteractionSelector = selector({
  key: "mapInteractionSelector",
  get: ({ get }) => {
    const leftMenuActive = get(leftMenuModeActiveAtom);
    return {
      select:
        !get(newCustomDataSourceAtom) &&
        (leftMenuActive == null ||
          leftMenuAllowedInteraction.includes(leftMenuActive)) &&
        !get(editFeatureAtom),
      hover:
        !get(newCustomDataSourceAtom) &&
        (leftMenuActive == null ||
          leftMenuAllowedInteraction.includes(leftMenuActive)) &&
        !get(editFeatureAtom),
      createGeneratePolygon:
        !get(newCustomDataSourceAtom) &&
        !get(editFeatureAtom) &&
        !get(addFeatureAtom),
      canvasLayerPopupOptions:
        !get(newCustomDataSourceAtom) &&
        !get(editFeatureAtom) &&
        !get(addFeatureAtom),
      deleteFeatureKeyboardShortcut:
        !get(modalTypeOpenAtom) && !get(newCustomDataSourceAtom),
      leftSideMenuActive: !get(newCustomDataSourceAtom),
      projectControl: !get(newCustomDataSourceAtom),
      undoredo: !get(newCustomDataSourceAtom),
      copyPaste: !get(modalTypeOpenAtom) && !get(newCustomDataSourceAtom),
    };
  },
});

export const selectedChildIndexForParentAtom = atom({
  key: "selectedChildIndexForParentAtom",
  default: {},
});
