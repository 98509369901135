import styled from "styled-components";
import { colors } from "../colors";

const thumbHeight = 10;
const thumbColor = "#508196";
const indeterminateColor = colors.blueMediumLight;

export const Range = styled.input<{ indeterminate?: boolean }>`
  appearance: none;
  width: 100%;
  margin: 0;
  height: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  position: relative;

  -moz-appearance: initial;

  &:after {
    position: absolute;
    right: 0rem;
    content: "";
    width: 4px;
    height: 4px;
    background-color: #023859;
    transform: rotate(45deg);
    top: 3px;
    bottom: 0;
    z-index: -1;
  }

  &:before {
    position: absolute;
    left: 0rem;
    content: "";
    width: 4px;
    height: 4px;
    background-color: #023859;
    transform: rotate(45deg);
    top: 3px;
    bottom: 0;
    z-index: -1;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    border: 1px ${(p) => (p.indeterminate ? "dotted" : "solid")} #023859;
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${thumbHeight}px;
    width: ${thumbHeight}px;
    background: ${(p) => (p.indeterminate ? indeterminateColor : thumbColor)};
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &::-moz-range-track {
    height: 0px;
    border: 1px ${(p) => (p.indeterminate ? "dotted" : "solid")} #023859;
    width: 100%;
  }
  &::-moz-range-progress {
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${(p) => (p.indeterminate ? indeterminateColor : thumbColor)};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: 1px;
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${(p) => (p.indeterminate ? indeterminateColor : thumbColor)};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }
`;

export const TextInput = styled.input`
  border: 1px solid ${colors.blueMedium};
  color: #264e5d;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: auto;
    border-color: ${colors.blueDark};
    &::placeholder {
      color: transparent;
    }
  }
`;
