import React, { Suspense, useEffect } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WebFont from "webfontloader";
import Toast from "./components/Toast/Toast";
import Notification from "./components/Notification/Notification";
import PublicMap from "./PublicMap/PublicMap";
import { ViewToParkThreeScene } from "./components/ViewToPark/ViewToPark";
import { useOnMobile } from "./state/onMobile";

export const CommonComponents = () => {
  return (
    <>
      <Suspense fallback={null}>
        <Toast />
        <Notification />
        <ViewToParkThreeScene />
      </Suspense>
    </>
  );
};

export default function App() {
  useOnMobile();
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Montserrat:100,200,300,400,500,600,700,800,900"],
      },
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/:customerId/:projectId/:branchId"
            element={<PublicMap />}
          />
          <Route
            path="/:customerId/:projectId/:branchId/:parkId"
            element={<PublicMap />}
          />
          <Route
            path="/analysis/:analysisType/:customerId/:branchId/:projectId"
            element={<PublicMap />}
          />
          <Route
            path="/analysis/:analysisType/:customerId/:projectId/:branchId/:parkId"
            element={<PublicMap />}
          />
        </Routes>
      </BrowserRouter>

      <CommonComponents />
    </>
  );
}
