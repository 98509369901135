import { PublicGisLayerWFS } from "../types/gisData";
import { appendQueryParamsSign } from "../utils/utils";
import { addCorsAndCacheProxyURL } from "./gisSourceCorsProxy";

export type WfsLayer = {
  source: string;
  wfs_url: string;
  layers: WfsSubLayer[];
  hide?: boolean;
  filteredLayers?: string[];
  path?: string;
};

export type WfsSubLayer = {
  featureTypeLayerName?: string;
  featureTypeName?: string;
  responseType?: string;
  theme?: string;
  type?: string;
  icon?: string;
  alias?: string;
};

export const WFS_TYPE = "wfs";

export const getWfsPath = (layer: PublicGisLayerWFS) => {
  const isPrivate = "private" in layer.source ? layer.source["private"] : false;

  if (isPrivate)
    throw new Error("PRivate layer not yet supported in public mode");

  return `${addCorsAndCacheProxyURL(layer.endpoint.url)}${appendQueryParamsSign(
    layer.endpoint.url
  )}SERVICE=WFS&VERSION=2.0.0&request=GetFeature&OUTPUTFORMAT=${
    layer.outputValue
  }&srsName=EPSG:4326&typeName=${layer.sourceLayerId}`;
};
