import { v4 as uuidv4 } from "uuid";
import { Feature, FeatureCollection, Polygon } from "geojson";
import * as Sentry from "@sentry/browser";
import {
  BathymetryUserUploadedType,
  GeoTiffUserUploadedImageType,
  ProjectFeature,
} from "../../services/types";

export enum GeotiffType {
  georefimage = "georefimage",
  bathymetry = "bathymetry",
}

export type GeotiffFeature = ProjectFeature<
  Polygon,
  {
    type:
      | typeof BathymetryUserUploadedType
      | typeof GeoTiffUserUploadedImageType;
    filename: string;
  }
>;

const uuid4RegEx =
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const addIdOnFeaturesIfNotUUID4 = <F extends Feature>(
  features: F[]
): (F & { properties?: { id: string } })[] => {
  if (!features) return [];
  return features
    .filter((f) => f != null)
    .map((f) => {
      if (!("properties" in f)) {
        Sentry.captureException(
          new Error("Illegal geojson feature without a properties entry"),
          { extra: { feature: f } }
        );
      }
      const id =
        String(f?.id ?? "").match(uuid4RegEx) != null ? String(f.id) : uuidv4();
      return {
        ...f,
        id,
        properties: { ...(f?.properties ?? {}), id },
      };
    });
};

export const addIdOnFeatureCollectionIfNotUUID4 = (
  featureCollection: FeatureCollection
): FeatureCollection => {
  return {
    ...featureCollection,
    features: addIdOnFeaturesIfNotUUID4(featureCollection.features),
  };
};

// type NestedArray<T> = T[] | NestedArray<T>[];
type NestedArray<T> = Array<T> | NestedArray<T>[];

export const funcOnCoords = <T>(
  coords: number[] | NestedArray<number>,
  func: (n: number[]) => T
): any => {
  const isBottom = <T>(a: NestedArray<T>): a is Array<T> =>
    typeof a[0] === "number";

  if (isBottom(coords)) return func(coords);
  return coords.map((c) => funcOnCoords(c, func));
};

export function ToEastLatLng(
  positon: { lng: number; lat: number },
  eastDistance: number
) {
  const r_earth = 6378;
  const pi = Math.PI;
  const new_longitude =
    positon.lng +
    ((eastDistance / r_earth) * (180 / pi)) /
      Math.cos((positon.lat * pi) / 180);
  return { lat: positon.lat, lng: new_longitude };
}

export const coordinatesToPolygon = (coords): Feature<Polygon> => ({
  type: "Feature",
  geometry: {
    type: "Polygon",
    coordinates: [coords],
  },
  properties: {},
});
