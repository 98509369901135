import styled from "styled-components";
import { StandardBox } from "../../styles/boxes/Boxes";
import { colors } from "../../styles/colors";
import { Ui16Bold, Ui16Regular } from "../../styles/typography";

export const StyledModalBase = styled(StandardBox)`
  padding: 1.2rem;
  min-width: 27rem;
  font-size: 1.3rem;
  line-height: 1;
  color: #6b6b76;
  outline: none;
  border-radius: 6px;
  box-sizing: content-box;
  display: flex;
  position: relative;
  height: fit-content;
`;

export const ModalHeader = styled(Ui16Bold)`
  margin: 0 0 12px;
`;

export const ModalLayerName = styled(Ui16Regular)`
  margin: 0;
`;

export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${colors.blueMedium};
  margin: 1.6rem 0;
  padding: 0;
  opacity: 0.5;
  height: 1px;
  width: 100%;
`;
