import mapboxgl, { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import MapFeature from "./Feature";
import { Feature } from "geojson";
import { fillOpacityWithCallback } from "./utils";
import { getZoomLevels } from "../../layers/utils";

const DEFAULT_COLOR = "#BF93E4";

const LineString = ({
  features,
  sourceId,
  layerId,
  map,
  symbols,
  paint,
  color,
  opacity,
  beforeId,
  onClickCallback,
  selectedIds,
  zoomLevels,
}: {
  features: Feature[];
  sourceId: string;
  layerId: string;
  map: Map;
  symbols?: Omit<mapboxgl.SymbolLayer, "id" | "source">;
  paint?: mapboxgl.LinePaint;
  color?: string;
  opacity?: number;
  beforeId?: string;
  onClickCallback?: (
    features: MapboxGeoJSONFeature[],
    shiftClicked: boolean
  ) => void;
  selectedIds?: (string | number)[];
  zoomLevels?: [number, number];
}) => {
  const layers = useMemo(() => {
    return [
      {
        id: layerId,
        type: "line",
        source: sourceId, // reference the data source
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: paint ?? {
          "line-color": "rgba(0,0,0,0)",
          "line-width": 5,
        },
        ...getZoomLevels(zoomLevels),
      },
      ...(symbols
        ? [
            {
              ...symbols,
              id: `${layerId}-symbolid`,
              source: sourceId,
              ...getZoomLevels(zoomLevels),
            },
          ]
        : []),
    ];
  }, [layerId, sourceId, symbols, paint, zoomLevels]) as mapboxgl.AnyLayer[];

  useEffect(() => {
    if (!map || (paint && "line-color" in paint)) return;
    map.setPaintProperty(layerId, "line-color", color ?? DEFAULT_COLOR);
  }, [layerId, map, color, layers, paint]);

  useEffect(() => {
    if (!map) return;
    if (opacity !== undefined) {
      map.setPaintProperty(
        layerId,
        "line-opacity",
        fillOpacityWithCallback(opacity, opacity + 0.2)
      );
    }
  }, [layerId, map, opacity, layers]);

  return (
    <MapFeature
      layers={layers}
      features={features}
      sourceId={sourceId}
      layerId={layerId}
      map={map}
      beforeId={beforeId}
      onClickCallback={onClickCallback}
      selectedIds={selectedIds}
    />
  );
};

export default LineString;
