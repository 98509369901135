import { useCallback } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { TextInput } from "../../styles/input/input";
import { Ui12Medium } from "../../styles/typography";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  height: 100%;
`;

export const InputLabel = styled(Ui12Medium)`
  margin: 0;
  color: ${colors.gray};
  text-transform: uppercase;
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onEnter?: () => void;
  onCancel?: () => void;
}

export const Input = ({ onEnter, onCancel, ...props }: InputProps) => {
  const _onKeyDown = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (e.key === "Enter") {
        onEnter && onEnter();
      } else if (e.key === "Escape") {
        onCancel && onCancel();
      }
    },
    [onCancel, onEnter]
  );

  return <TextInput onKeyDown={_onKeyDown} {...props} />;
};
