import { Position } from "geojson";
import proj4 from "proj4";
import { fetchEnhancer } from "../services/utils";
import { funcOnCoords } from "./geojson/utils";

const PSUEDO_MERCATOR_PROJ4_STRING =
  "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs";

export const ESRI_54008 =
  "+proj=sinu +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs +type=crs";

export const EPSG_3857 =
  "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs";

export const wgs84ToProjected = (coord, proj4String) =>
  proj4(proj4String, coord.slice(0, 2));

export const wgs84ToPsuedoMercator = (coord) =>
  proj4(PSUEDO_MERCATOR_PROJ4_STRING, coord);

export const projectedToWGS84 = (coord, proj4String) =>
  proj4(proj4String, "WGS84", coord);

export const pseudoMercatorToWGS84 = (coord) =>
  proj4(PSUEDO_MERCATOR_PROJ4_STRING, "WGS84", coord);

export const toWGS84 = (coord, proj4String) =>
  proj4(proj4String, "WGS84", coord);

export const featureCollectionToWGS84 = async (featureCollection, epsg) => {
  if (epsg === 4326) return featureCollection;
  const res = await fetchEnhancer(`https://epsg.io/${epsg}.proj4`, {
    method: "get",
  });
  const proj4String = await res.text();
  return {
    ...featureCollection,
    features: featureCollection.features.map((f) => ({
      ...f,
      geometry: {
        ...f.geometry,
        coordinates: funcOnCoords(f.geometry.coordinates, (c) =>
          toWGS84(c, proj4String)
        ),
      },
    })),
  };
};

export const featureWGS84ToProjected = (f, proj4String) => ({
  ...f,
  geometry: {
    ...f.geometry,
    coordinates: funcOnCoords(f.geometry.coordinates, (c) =>
      wgs84ToProjected(c, proj4String)
    ),
  },
});

export const transformBBOXToWGS84Square = (bbox, proj4String) => {
  return [
    proj4(proj4String, "EPSG:4326", [bbox[0], bbox[1]]),
    proj4(proj4String, "EPSG:4326", [bbox[2], bbox[1]]),
    proj4(proj4String, "EPSG:4326", [bbox[2], bbox[3]]),
    proj4(proj4String, "EPSG:4326", [bbox[0], bbox[3]]),
  ].map((c) => ({ lng: c[0], lat: c[1] }));
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export const getDistanceFromLatLonInM = (
  [lng1, lat1]: Position,
  [lng2, lat2]: Position
) => {
  const R = 6371000; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};
