import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Chevron } from "../../icons/24/ArrowRight.svg";

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  align-items: center;
`;

const ToggleableTitle = styled(Title)`
  cursor: pointer;
`;

const ChevronWrapper = styled.div<{ open: boolean }>`
  height: 60%;
  transform: rotate(${(p) => (p.open ? "90deg" : "0deg")});
  transition: 0.1s;
  svg {
    height: 2.2rem;
    width: 1.3rem;
  }
`;

const ToggleableRow = ({ title, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <ToggleableTitle onClick={() => setOpen(!open)}>
        <Title>{title}</Title>
        <ChevronWrapper open={open}>
          <Chevron width={"1rem"} />
        </ChevronWrapper>
      </ToggleableTitle>
      {open && children}
    </div>
  );
};

export default ToggleableRow;
