import { Feature, FeatureCollection } from "geojson";
import { SymbolLayer } from "mapbox-gl";
import { LayerStrokeStyle } from "../types/gisData";

export const externalWFSAnchorId = "external-wfs-anchor-id";

export const filterFeatureCollectionAccordingToType = (
  featureCollection:
    | {
        [key: string]: any;
        features: Feature[];
      }
    | FeatureCollection,
  type: "Polygon" | "LineString" | "Point"
) => ({
  ...featureCollection,
  features: featureCollection.features.filter((f) =>
    f.geometry.type.includes(type)
  ),
});

export const getLayerSymbols = (pinnedProperty?: string) => {
  if (!pinnedProperty) return;
  return {
    type: "symbol",
    minzoom: 5,
    layout: {
      "symbol-placement": "point",
      "text-field": `{${pinnedProperty}}`,
      "text-size": 12,
      "symbol-spacing": 300,
      "text-keep-upright": true,
    },
    paint: {
      "text-opacity": 1,
    },
  } as Omit<SymbolLayer, "id" | "source">;
};

export const StrokeStyleToDashArray = {
  [LayerStrokeStyle.Solid]: [1, 0],
  [LayerStrokeStyle.Dashed]: [5, 5],
  [LayerStrokeStyle.Dotted]: [1, 2],
};

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const getZoomLevels = (zoomLevels: undefined | [number, number]) => ({
  maxzoom: zoomLevels != null ? zoomLevels[1] : 22,
  minzoom: zoomLevels != null ? zoomLevels[0] : 0,
});
