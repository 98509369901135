import { useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mapRefAtom } from "../state/map";
import { selectedDynamicWMSLayersSelectorFamily } from "../state/layer";
import {
  getDistanceFromLatLonInM,
  wgs84ToPsuedoMercator,
} from "../utils/proj4";
import { currentSelectionWMSAtom } from "../state/selection";
import { canvasPolygonLayerId } from "./canvasLayer";
import { useTypedPath } from "../state/pathParams";
import { PublicGisLayerWMS } from "../types/gisData";
import { addCorsAndCacheProxyURL } from "../state/gisSourceCorsProxy";
import { appendQueryParamsSign } from "../utils/utils";

const getWmsPath = (layer: PublicGisLayerWMS) => {
  return `${addCorsAndCacheProxyURL(layer.endpoint.url)}${appendQueryParamsSign(
    layer.endpoint.url
  )}format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&styles=&transparent=true&width=256&height=256&layers=${
    layer.sourceLayerId
  }`;
};

const getQueryURL = (layer: PublicGisLayerWMS, bboxQueryPsuedoMercator) =>
  encodeURI(
    `${addCorsAndCacheProxyURL(
      layer.endpoint.url
    )}?request=GetFeatureInfo&service=WMS&version=1.1.1&styles=&layers=${
      layer.sourceLayerId
    }&srs=EPSG:3857&bbox=${bboxQueryPsuedoMercator.join(
      ","
    )}&width=256&height=256&format=image/png&query_layers=${
      layer.sourceLayerId
    }&info_format=text/html&x=128&y=128`
  );

const DynamicWMSLayers = () => {
  const { projectId } = useTypedPath("projectId");
  const selectedDynamicWMSLayers = useRecoilValue(
    selectedDynamicWMSLayersSelectorFamily(projectId)
  );
  return (
    <>
      {selectedDynamicWMSLayers.map((layer) => (
        <DynamicWMSLayer key={layer.name} layer={layer} />
      ))}
    </>
  );
};

const DynamicWMSLayer = ({ layer }: { layer: PublicGisLayerWMS }) => {
  const map = useRecoilValue(mapRefAtom);
  const setCurrentSelectionWMS = useSetRecoilState(currentSelectionWMSAtom);

  const dynamicWMSLayersId = useMemo(
    () => `dynamic-wms-layer-id-${layer.name}`,
    [layer]
  );
  const dynamicWMSLayersSource = useMemo(
    () => `dynamic-wms-layer-source-${layer.name}`,
    [layer]
  );

  const dynamicWMSLayer: mapboxgl.RasterLayer = useMemo(
    () => ({
      id: dynamicWMSLayersId,
      type: "raster",
      source: dynamicWMSLayersSource, // reference the data source
      paint: {},
    }),
    [dynamicWMSLayersId, dynamicWMSLayersSource]
  );

  useEffect(() => {
    if (!map) return;
    map.addSource(dynamicWMSLayersSource, {
      type: "raster",
      tiles: [`${getWmsPath(layer)}&bbox={bbox-epsg-3857}`],
      tileSize: 256,
    });
    map.addLayer(
      dynamicWMSLayer,
      map.getLayer(canvasPolygonLayerId) ? canvasPolygonLayerId : undefined
    );
    return () => {
      map.removeLayer(dynamicWMSLayer.id);
      map.removeSource(dynamicWMSLayersSource);
    };
  }, [map, dynamicWMSLayersId, layer, dynamicWMSLayer, dynamicWMSLayersSource]);

  useEffect(() => {
    if (!map) return;
    const checkWMSInfo = async (e) => {
      if (e.originalEvent.button !== 2) return;
      const { lngLat } = e;
      const coord = [lngLat.lng, lngLat.lat];

      const mapBounds = map.getBounds();
      const diagonalBounds = getDistanceFromLatLonInM(
        [mapBounds.getSouthWest().lng, mapBounds.getSouthWest().lat],
        [mapBounds.getNorthEast().lng, mapBounds.getNorthEast().lat]
      );
      const bboxBuffer = Math.round(diagonalBounds * 0.05);
      const pseudoMercator = wgs84ToPsuedoMercator(coord);
      const bboxQueryPsuedoMercator = [
        [pseudoMercator[0] - bboxBuffer, pseudoMercator[1] - bboxBuffer],
        [pseudoMercator[0] + bboxBuffer, pseudoMercator[1] + bboxBuffer],
      ];

      const queryUrl = getQueryURL(layer, bboxQueryPsuedoMercator);

      const legendGraphicUrl = `${layer.endpoint.url}?service=WMS&version=1.1.1&request=GetLegendGraphic&format=image/png&layer=${layer.id}`;

      const newSelection = {
        type: layer.name,
        url: queryUrl,
        legendGraphicUrl,
      };

      setCurrentSelectionWMS((selections) => [
        ...selections.filter((s) => s.type !== newSelection.type),
        newSelection,
      ]);
    };
    map.on("mousedown", checkWMSInfo);
    return () => {
      map.off("mousedown", checkWMSInfo);
    };
  }, [map, layer, setCurrentSelectionWMS]);

  return null;
};

export default DynamicWMSLayers;
